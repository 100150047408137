import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
    silentTranslationWarn: true,
    locale: 'zh',
    fallbackLocale: 'dev',
    messages: {

        'zh': require('@/assets/lang/zh.js'),

        'en': require('@/assets/lang/en.js'),

        'jp': require('@/assets/lang/jp.js')

    }
})