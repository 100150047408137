<template>
	<div>
		<el-tabs
			v-model="activeTab"
			style="height: 100%; color: black; min-height: 60vh"
		>
			<el-tab-pane
				label="Process Number"
				name="first"
				style="text-align: center"
			>
				<el-divider content-position="left"
					>Process Number(MPROC)</el-divider
				>

				<el-form label-width="auto">
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="NO.">
								<el-input
									@input="ProcessPropertiesChange"
									v-model="
										$store.state.ProcessProperties
											.ProcessNumber
									"
									placeholder="请在此输入热处理过程数"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-tab-pane>
			<el-tab-pane
				label="Heat(SHEAT)"
				name="second"
				style="text-align: center"
			>
				<el-divider content-position="left">Heat(SHEAT)</el-divider>
				<processTable
					:parentTableData="$store.state.ProcessProperties.SHEAT"
				/>
			</el-tab-pane>
			<el-tab-pane
				label="Diffuse(SDIFP)"
				name="third"
				style="text-align: center"
			>
				<el-divider content-position="left">Diffuse(SDIFP)</el-divider>
				<processTable
					:parentTableData="$store.state.ProcessProperties.SDIFP"
				/>
			</el-tab-pane>
			<el-tab-pane
				label="Quenching(SQUEN)"
				name="aaa"
				style="text-align: center"
			>
				<el-divider content-position="left"
					>Quenching(SQUEN)</el-divider
				>
				<processTable
					:parentTableData="$store.state.ProcessProperties.SQUEN"
				/>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
import processTable from '../components/processTable';
export default {
	components: {
		processTable,
	},
	computed: {
		// ProcessProperties: {
		// 	get() {
		// 		return this.$store.state.ProcessProperties;
		// 	},
		// 	set(value) {
		// 		// this.$store.commit('updateProcessProperties', value);
		// 	},
		// },
	},
	data() {
		return {
			activeTab: 'first',
			ProcessProperties: this.$store.state.ProcessProperties,
		};
	},
	mounted() {},
	methods: {
		ProcessPropertiesChange(a) {
			// this.$store.commit(
			// 	'updateProcessProperties',
			// 	this.$store.state.ProcessProperties
			// );
			this.$store.commit('aaa', '');
		},
	},
};
</script>
