<template>
	<div class="container">
		<el-tabs
			v-model="activeName"
			@tab-click="handleClick"
			style="height: 100%; color: black; min-height: 60vh"
		>
			<el-tab-pane
				label="上传材料文件"
				name="upload"
				style="text-align: center"
			>
				<div style="margin-top: 20vh"></div>
				<el-upload
					class="upload-demo"
					drag
					action="https://jsonplaceholder.typicode.com/posts/"
					multiple
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">
						可将材料物性文件拖拽到此处，或<em>点击上传</em>
					</div>
					<div class="el-upload__tip" slot="tip">
						只能上传.dat文件
					</div>
				</el-upload>
			</el-tab-pane>
			<el-tab-pane label="Material" name="first">
				<el-form :model="formInline" label-width="auto">
					<el-divider content-position="left"
						>Material Name(MCHAR)</el-divider
					>
					<el-row>
						<el-col :span="8">
							<el-form-item label="Material Name">
								<el-input
									v-model="$store.state.MaterialProperties.Material.MaterialName"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Materials Characte ristic</el-divider
					>
					<el-row>
						<el-col :span="4">
							<el-form-item label="Diffusion">
								<el-input
									v-model="$store.state.MaterialProperties.Material.MaterialCharacteRistic.Diffusion"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item
								label="Transformation"
								label-width="180px"
							>
								<el-input
									v-model="$store.state.MaterialProperties.Material.MaterialCharacteRistic.Transformation"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="Heat">
								<el-input
									v-model="$store.state.MaterialProperties.Material.MaterialCharacteRistic.Heat"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="Inelasticity">
								<el-input
									v-model="$store.state.MaterialProperties.Material.MaterialCharacteRistic.Inelasticity"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="Crystal">
								<el-input
									v-model="$store.state.MaterialProperties.Material.MaterialCharacteRistic.Crystal"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Materials Element (MELTB)</el-divider
					>
					<el-row>
						<el-col :span="3">
							<el-form-item label="Fe">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Fe"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="C">
								<el-input
									v-model="$store.state.MaterialProperties.Material.C"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Si">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Si"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Mn">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Mn"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Cr">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Cr"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Mg">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Mg"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Zn">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Zn"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Al">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Al"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="3">
							<el-form-item label="Ni">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Ni"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Mo">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Mo"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="V">
								<el-input
									v-model="$store.state.MaterialProperties.Material.V"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Cu">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Cu"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="N">
								<el-input
									v-model="$store.state.MaterialProperties.Material.N"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="P">
								<el-input
									v-model="$store.state.MaterialProperties.Material.P"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="S">
								<el-input
									v-model="$store.state.MaterialProperties.Material.S"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="Ti">
								<el-input
									v-model="$store.state.MaterialProperties.Material.Ti"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-form-item label="W">
								<el-input
									v-model="$store.state.MaterialProperties.Material.W"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Diffusion coefficient" name="second">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MDIFF</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.PhaseNumber"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="$store.state.MaterialProperties.DiffsionCoefficient.DataFormat">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.DiffusionElementNumber"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="$store.state.MaterialProperties.DiffsionCoefficient.Phase">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="$store.state.MaterialProperties.DiffsionCoefficient.DiffusionElement">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.FuctionalType.Concentration"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left: 40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>D = D0 + D1*T + D2*T^2 + D3*T^3 +
								D4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="D0=">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.FuctionalType.D0"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="D1=">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.FuctionalType.D1"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="D2=">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.FuctionalType.D2"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="D3=">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.FuctionalType.D3"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="D4=">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.FuctionalType.D4"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="$store.state.MaterialProperties.DiffsionCoefficient.DiscretionType.Concentration"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left: 90px; margin-top: 10px">
						<el-col :span="2">
							Temperature
							<el-input
								v-model="$store.state.MaterialProperties.DiffsionCoefficient.DiscretionType.Temperature"
							></el-input>
						</el-col>
						<el-col style="margin-left: 10px" :span="2">
							Diffusion coefficient
							<el-input
								v-model="$store.state.MaterialProperties.DiffsionCoefficient.DiscretionType.DiffusionCoeffcient"
							></el-input>
						</el-col>
						<el-col
							style="margin-left: 60px; margin-top: 16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left: 90px; margin-top: 10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="To ausenite-PT" name="third">
				<el-row>
					<el-button>MHTRN</el-button>
					<el-radio-group style="margin-left: 30px" v-model="$store.state.MaterialProperties.ToAusenite_PT.MHTRN">
						<el-radio :label="3">AVRAMT</el-radio>
						<el-radio :label="6">AVMOD</el-radio>
						<el-radio :label="9">JMMOD</el-radio>
					</el-radio-group>
					<el-button style="margin-left: 30px" @click="onSubmit"
						>Insert</el-button
					>
				</el-row>
				<el-divider content-position="left">FUNCT</el-divider>
				<el-radio v-model="$store.state.MaterialProperties.ToAusenite_PT.FUNCT" :label="1">f11</el-radio>
				<div>
					<img src="../../assets/img/material3.png" alt />
				</div>
				<el-row style="margin-top: 20px">
					<el-col :span="6">
						Start temperature(a1) :
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.StartTemperaturea1"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a3):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.Coefficienta3"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a0):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.Coefficienta0"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a2):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.Coefficienta2"></el-input>
					</el-col>
				</el-row>
				<el-row style="margin-top: 10px">
					<el-col :span="6">
						End temperature (a4) :
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.EndTemperaturea4"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a5):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.Coefficienta5"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a6):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToAusenite_PT.f11.Coefficienta6"></el-input>
					</el-col>
					<el-col :span="6">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-radio style="margin-top: 20px" v-model="$store.state.MaterialProperties.ToAusenite_PT.FUNCT" :label="2"
					>TTTF</el-radio
				>
				<el-row style="margin-top: 10px">
					<el-col :span="2">
						Temperature
						<el-input
							v-model="$store.state.MaterialProperties.ToAusenite_PT.TTTF.Temperature"
						></el-input>
					</el-col>
					<el-col style="margin-left: 10px" :span="2">
						Time
						<el-input
							v-model="$store.state.MaterialProperties.ToAusenite_PT.TTTF.Time"
						></el-input>
					</el-col>
				</el-row>
				<el-button style="margin-top: 10px">insert</el-button>
			</el-tab-pane>

			<el-tab-pane label="To diffuse-PT" name="fourth">
				<el-row>
					<el-button>MQTRD</el-button>
					<el-radio-group style="margin-left: 30px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.MQTRD">
						<el-radio :label="3">AVRAMT</el-radio>
						<el-radio :label="6">AVMOD</el-radio>
						<el-radio :label="9">JMMOD</el-radio>
					</el-radio-group>
					<el-button style="margin-left: 30px" @click="onSubmit"
						>Insert</el-button
					>
				</el-row>
				<el-divider content-position="left">FUNCT</el-divider>
				<el-radio v-model="$store.state.MaterialProperties.ToDiffuse_PT.FUNCT" :label="1">f11</el-radio>
				<div>
					<img src="../../assets/img/material3.png" alt />
				</div>
				<el-row style="margin-top: 20px">
					<el-col :span="6">
						Start temperature(a1) :
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.StartTemperaturea1"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a3):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.Coefficienta3"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a0):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.Coefficienta0"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a2):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.Coefficienta2"></el-input>
					</el-col>
				</el-row>
				<el-row style="margin-top: 10px">
					<el-col :span="6">
						End temperature (a4) :
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.EndTemperaturea4"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a5):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.Coefficienta5"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a6):
						<el-input style="width: 150px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.f11.Coefficienta6"></el-input>
					</el-col>
					<el-col :span="6">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-radio style="margin-top: 20px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.FUNCT" :label="2"
					>CCTF</el-radio
				>
				<el-row style="margin-top: 10px">
					<el-col :span="2">
						Temperature
						<el-input
							v-model="$store.state.MaterialProperties.ToDiffuse_PT.CCTF.Temperature"
						></el-input>
					</el-col>
					<el-col style="margin-left: 10px" :span="2">
						Time
						<el-input
							v-model="$store.state.MaterialProperties.ToDiffuse_PT.CCTF.Time"
						></el-input>
					</el-col>
					<el-col style="margin-left: 10px" :span="2">
						Cooling speed
						<el-input
							v-model="$store.state.MaterialProperties.ToDiffuse_PT.CCTF.CollingSpeed"
						></el-input>
					</el-col>
				</el-row>
				<el-button style="margin-top: 10px">insert</el-button>
				<br />
				<el-radio style="margin-top: 10px" v-model="$store.state.MaterialProperties.ToDiffuse_PT.FUNCT" :label="3"
					>TTTF</el-radio
				>
				<el-row style="margin-top: 10px">
					<el-col :span="2">
						Temperature
						<el-input
							v-model="$store.state.MaterialProperties.ToDiffuse_PT.TTTF.Temperature"
						></el-input>
					</el-col>
					<el-col style="margin-left: 10px" :span="2">
						Time
						<el-input
							v-model="$store.state.MaterialProperties.ToDiffuse_PT.TTTF.Time"
						></el-input>
					</el-col>
				</el-row>
				<el-button style="margin-top: 10px">insert</el-button>
			</el-tab-pane>

			<el-tab-pane label="To martensite-PT" name="fifth">
				<el-button>MQTRN</el-button>
				<br />
				<el-radio style="margin-top: 10px" v-model="$store.state.MaterialProperties.ToMartensite_PT.MQTRN" :label="1"
					>KMMOD</el-radio
				>
				<el-row style="margin-top: 10px">
					<el-col :span="2">
						Initial value
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.KMMOD.InitialValue"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						A
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.KMMOD.A"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						B
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.KMMOD.B"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						K
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.KMMOD.K"
						></el-input>
					</el-col>
					<el-col
						style="margin-left: 20px; margin-top: 16px"
						:span="2"
					>
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-row>
					<img src="../../assets/img/material5-1.png" alt />
				</el-row>
				<el-radio style="margin-top: 10px" v-model="$store.state.MaterialProperties.ToMartensite_PT.MQTRN" :label="2"
					>MGMOD</el-radio
				>
				<el-row style="margin-top: 10px">
					<el-col :span="2">
						K
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.MGMOD.K"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						MS
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.MGMOD.MS"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						A
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.MGMOD.A"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						B
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.MGMOD.B"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						F
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.MGMOD.F"
						></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="2">
						G
						<el-input
							v-model="$store.state.MaterialProperties.ToMartensite_PT.MGMOD.G"
						></el-input>
					</el-col>
					<el-col
						style="margin-left: 20px; margin-top: 16px"
						:span="2"
					>
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-row>
					<img src="../../assets/img/material5-2.png" alt />
				</el-row>
			</el-tab-pane>

			<el-tab-pane label="Hardness" name="sixth">
				<el-button>MHRC</el-button>
				<el-divider content-position="left">MAYNIER</el-divider>
				<h2 style="color: rgb(48, 49, 51)">
					A cooling rate to use for a calculation of the hardness
				</h2>
				<el-row style="margin-top: 10px">
					<el-col :span="3">
						Temperature
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left: 20px" :span="3">
						Temperature range
						<el-input></el-input>
					</el-col>
					<el-col
						style="margin-left: 20px; margin-top: 16px"
						:span="2"
					>
						<el-button>insert</el-button>
					</el-col>
				</el-row>
			</el-tab-pane>

			<el-tab-pane label="Thermal conductivitv" name="seventh">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MCOND</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left: 40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>K = D0 + K1*T + K2*T^2 + K3*T^3 +
								K4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="K0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left: 90px; margin-top: 10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left: 10px" :span="3">
							Thermal conductivitv
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left: 60px; margin-top: 16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left: 90px; margin-top: 10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Specific heat" name="eighth"> </el-tab-pane>

			<el-tab-pane label="Density" name="ninth">
				<div style="width: 100%; text-align: center">
					<el-button
						type="success"
						icon="el-icon-download"
						@click="onSubmit"
						>保存到本地</el-button
					>
					<el-button
						type="primary"
						@click="onSubmit"
						icon="el-icon-upload"
						>保存到云端</el-button
					>
					<el-button @click="onSubmit">重置</el-button>
				</div>
				<el-row style="margin-top:20px">
					<el-col :span="6">
						Start temperature(a1) :
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a3):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a0):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a2):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
				</el-row>
				<el-row style="margin-top:10px">
					<el-col :span="6">
						End temperature (a4) :
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a5):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a6):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-radio style="margin-top:20px" v-model="radio2" :label="2"
					>TTTF</el-radio
				>
				<el-row style="margin-top:10px">
					<el-col :span="2">
						Temperature
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:10px" :span="2">
						Time
						<el-input></el-input>
					</el-col>
				</el-row>
				<el-button style="margin-top:10px">insert</el-button>
			</el-tab-pane>

			<el-tab-pane label="To diffuse-PT" name="fourth">
				<el-row>
					<el-button>MQTRD</el-button>
					<el-radio-group style="margin-left:30px" v-model="radio1">
						<el-radio :label="3">AVRAMT</el-radio>
						<el-radio :label="6">AVMOD</el-radio>
						<el-radio :label="9">JMMOD</el-radio>
					</el-radio-group>
					<el-button style="margin-left:30px" @click="onSubmit"
						>Insert</el-button
					>
				</el-row>
				<el-divider content-position="left">FUNCT</el-divider>
				<el-radio v-model="radio2" :label="1">f11</el-radio>
				<div>
					<img src="../../assets/img/material3.png" alt />
				</div>
				<el-row style="margin-top:20px">
					<el-col :span="6">
						Start temperature(a1) :
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a3):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a0):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a2):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
				</el-row>
				<el-row style="margin-top:10px">
					<el-col :span="6">
						End temperature (a4) :
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a5):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						Coefficient (a6):
						<el-input style="width:150px" v-model="inp"></el-input>
					</el-col>
					<el-col :span="6">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-radio style="margin-top:20px" v-model="radio2" :label="2"
					>CCTF</el-radio
				>
				<el-row style="margin-top:10px">
					<el-col :span="2">
						Temperature
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:10px" :span="2">
						Time
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:10px" :span="2">
						Cooling speed
						<el-input></el-input>
					</el-col>
				</el-row>
				<el-button style="margin-top:10px">insert</el-button>
				<br />
				<el-radio style="margin-top:10px" v-model="radio2" :label="3"
					>TTTF</el-radio
				>
				<el-row style="margin-top:10px">
					<el-col :span="2">
						Temperature
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:10px" :span="2">
						Time
						<el-input></el-input>
					</el-col>
				</el-row>
				<el-button style="margin-top:10px">insert</el-button>
			</el-tab-pane>

			<el-tab-pane label="To martensite-PT" name="fifth">
				<el-button>MQTRN</el-button>
				<br />
				<el-radio style="margin-top:10px" v-model="radio3" :label="1"
					>KMMOD</el-radio
				>
				<el-row style="margin-top:10px">
					<el-col :span="2">
						Initial value
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						A
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						B
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						K
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px;margin-top:16px" :span="2">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-row>
					<img src="../../assets/img/material5-1.png" alt />
				</el-row>
				<el-radio style="margin-top:10px" v-model="radio3" :label="2"
					>MGMOD</el-radio
				>
				<el-row style="margin-top:10px">
					<el-col :span="2">
						K
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						MS
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						A
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						B
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						F
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="2">
						G
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px;margin-top:16px" :span="2">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
				<el-row>
					<img src="../../assets/img/material5-2.png" alt />
				</el-row>
			</el-tab-pane>

			<el-tab-pane label="Hardness" name="sixth">
				<el-button>MHRC</el-button>
				<el-divider content-position="left">MAYNIER</el-divider>
				<h2 style="color:rgb(48,49,51)">
					A cooling rate to use for a calculation of the hardness
				</h2>
				<el-row style="margin-top:10px">
					<el-col :span="3">
						Temperature
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px" :span="3">
						Temperature range
						<el-input></el-input>
					</el-col>
					<el-col style="margin-left:20px;margin-top:16px" :span="2">
						<el-button>insert</el-button>
					</el-col>
				</el-row>
			</el-tab-pane>

			<el-tab-pane label="Thermal conductivitv" name="seventh">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MCOND</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>K = D0 + K1*T + K2*T^2 + K3*T^3 +
								K4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="K0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="K4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Thermal conductivitv
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Specific heat" name="eighth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MSPEA</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>S = S0 + S1*T + S2*T^2 + S3*T^3 +
								S4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="S0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="S1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="S2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="S3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="S4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Specific heat
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Density" name="ninth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MDENS</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>DE = DE0 + DE1*T + DE2*T^2 + DE3*T^3 +
								DE4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="DE0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="DE1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="DE2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="DE3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="DE4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Density
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Transformation latent heat" name="Tenth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MLATENT</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:100px">
							<el-form-item label="Data Format:Functional type">
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-row>
						<el-button @click="onSubmit" style="margin-left:200px"
							>Austenite ‹=› Diffuse</el-button
						>
						<el-button @click="onSubmit" style="margin-left:400px"
							>Austenite ‹=› Martensite</el-button
						>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element( GDELX)</el-divider
					>
					<el-row>
						<el-radio-group v-model="materialForm.Name">
							<el-radio label="Carbon"></el-radio>
							<el-radio label="Nitrogen"></el-radio>
							<el-radio label="Other"></el-radio>
						</el-radio-group>
						<el-button @click="onSubmit" style="margin-left:100px"
							>Insert</el-button
						>
					</el-row>
					<el-row style="margin-top:20px">
						<el-form-item label="Concentration">
							<el-col :span="7">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="Start temperature">
							<el-col :span="7">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="End temperature">
							<el-col :span="7">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="Transformation latent heat">
							<el-col :span="7">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-col>
						</el-form-item>
						<el-col :span="3" style="margin-left:300px">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px;margin-bottom:100px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-tab-pane>

			<el-tab-pane
				label="Modulus of longitudinal elasticity"
				name="Eleventh"
			>
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MYOUNG</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>EL = EL0 + EL1*T + EL2*T^2 + EL3*T^3 +
								EL4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="EL0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EL1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EL2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EL3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EL4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							ELastic modulus
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Poisson ratio" name="Twelfth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MPOISSON</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>P = P0 + P1*T + P2*T^2 + P3*T^3 +
								P4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="P0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="P1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="P2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="P3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="P4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Poisson ratio
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Yield stress" name="Thirteenth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MYIELD</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>Y = Y0 + Y1*T +Y2*T^2 + Y3*T^3 +
								Y4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="Y0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="Y1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="Y2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="Y3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="Y4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Yield Stress
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Hardening coefficient" name="Fourteen">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MHARD</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="Division">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>H = H0 + H1*T +H2*T^2 + H3*T^3 +
								H4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="H0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="H1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="H2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="H3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="H4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="Division">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Yield Stress
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane
				label="Thermal expansion coefficients"
				name="Fifteenth"
			>
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MTHEM</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
								<el-radio label="Gamma(Fe-N)"></el-radio>
								<el-radio label="Epsilon(Fe-N)"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>TH = TH0 + TH1*T +TH2*T^2 + TH3*T^3 +
								TH4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="TH0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="TH1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="TH2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="TH3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="TH4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Thermal expansion coefficients
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane
				label="Transformation expansion coefficients"
				name="Sixteenth"
			>
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MEXPAN</el-button>
					<el-row>
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>EX = EX0 + EX1*T +EX2*T^2 + EX3*T^3 +
								EX4*T^4</el-form-item
							>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="EX0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EX1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EX2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EX3=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="EX4=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="3">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="3">
							Transformation expansion coefficients
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane
				label="Transformation plasticity & Diffusive strain coefficient"
				name="seventeenth"
			>
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MPHSPL</el-button>

					<el-row>
						<el-col :span="3">
							<el-button @click="onSubmit" style="margin-top:20px"
								>Austenite ＜=＞ Diffuse</el-button
							>
						</el-col>
						<el-col :span="1" style="margin-left:100px">
							<el-form-item label="KC=K0"> </el-form-item>
						</el-col>
						<el-col
							:span="1"
							style="margin-top:40px;margin-left:-60px"
						>
							<el-form-item label="KN=K0"> </el-form-item>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="7">
							<el-form-item label="Carbon or Nitrogen">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7" style="margin-left:10px">
							<el-form-item label="K0">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button style="margin-left:90px;margin-top:10px"
								>insert</el-button
							>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="3">
							<el-button @click="onSubmit" style="margin-top:20px"
								>Austenite ＜=＞ Martensite</el-button
							>
						</el-col>
						<el-col :span="7" style="margin-left:100px">
							<el-form-item
								label="KC=K0，K1 is the stress factor"
							>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item
						label="KN=K0，K1 is the stress factor"
						style="margin-left:280px"
					>
					</el-form-item>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Carbon or Nitrogen">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8" style="margin-left:10px">
							<el-form-item label="K0">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="K1">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8" style="margin-left:10px">
							<el-form-item label="KUSER">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button style="margin-left:90px;margin-top:10px"
								>insert</el-button
							>
						</el-col>
					</el-row>
					<el-divider content-position="left"></el-divider>
					<el-row>
						<el-button @click="onSubmit">MCRYSTAL</el-button>
					</el-row>
					<el-row>
						<img
							src="../../assets/img/material17-1.png"
							style="margin-top:30px"
							alt
						/>
					</el-row>
					<el-row>
						<el-col :span="5">
							<img
								src="../../assets/img/material17-2.png"
								style="margin-top:20px"
								alt
							/>
						</el-col>
						<el-col
							:span="3"
							style="margin-top:30px;margin-left:-200px"
						>
							<el-input v-model="materialForm.Name"></el-input>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="6" style="margin-left:-200px">
							<el-form-item label="A1">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:-150px">
							<el-form-item label="A2">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:-100px">
							<el-form-item label="A3">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:-50px">
							<el-form-item label="A4">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6" style="margin-left:-160px">
							<el-form-item label="AUSER1">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:-100px">
							<el-form-item label="AUSER2">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button style="margin-left:90px;"
								>insert</el-button
							>
						</el-col>
					</el-row>
					<el-row>
						<img
							src="../../assets/img/material17-3.png"
							style="margin-top:20px"
							alt
						/>
					</el-row>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Creep coefficient" name="Eighteenth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MCREEPO</el-button>
					<el-button @click="onSubmit">MCREEPV</el-button>
					<el-row style="margin-top:10px">
						<el-col :span="7">
							<el-form-item label="Phase Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Data Format">
								<el-radio-group v-model="materialForm.Name">
									<el-radio label="Fuctional type"></el-radio>
									<el-radio
										label="Discretion type"
									></el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="Diffusion Element Number">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button @click="onSubmit">Insert</el-button>
						</el-col>
					</el-row>
					<el-divider content-position="left"
						>Phase (SPHSX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Austenite"></el-radio>
								<el-radio label="Bainite"></el-radio>
								<el-radio label="Pearlite"></el-radio>
								<el-radio label="Ferrite"></el-radio>
								<el-radio label="Martensite"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>
					<el-divider content-position="left"
						>Diffusion Element(GDELX)</el-divider
					>
					<el-row>
						<el-form-item>
							<el-radio-group v-model="materialForm.Name">
								<el-radio label="Carbon"></el-radio>
								<el-radio label="Nitrogen"></el-radio>
								<el-radio label="Other"></el-radio>
							</el-radio-group>
							<el-button @click="onSubmit">Insert</el-button>
						</el-form-item>
					</el-row>

					<el-divider content-position="left">
						Fuctional type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col style="margin-left:40px" :span="12">
							<el-form-item label="Tenperature Dependence"
								>B=B0 + B1*T + B2*T^2</el-form-item
							>
							<el-form-item>n = n0 +n1*T</el-form-item>
							<img
								src="../../assets/img/material18-1.png"
								style="margin-top:20px;margin-left:195px;margin-bottom:20px"
								alt
							/>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="5">
							<el-form-item label="B0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="B1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="B2=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="n0=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="n1=">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-form-item label="">
								<el-button>insert</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<el-divider content-position="left">
						Discretion type
					</el-divider>

					<el-row>
						<el-col :span="8">
							<el-form-item label="Concentration">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<img
								src="../../assets/img/material18-2.png"
								style="margin-top:20px;margin-left:195px;margin-bottom:20px"
								alt
							/>
						</el-col>
					</el-row>

					<el-row style="margin-left:90px;margin-top:10px">
						<el-col :span="4">
							Temperature
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="4">
							coefficient B
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col style="margin-left:10px" :span="4">
							index n
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
							<el-input></el-input>
						</el-col>
						<el-col
							style="margin-left:60px;margin-top:16px"
							:span="6"
						>
							<el-button>GEND</el-button>
							<el-button>SEND</el-button>
						</el-col>
					</el-row>
					<el-button style="margin-left:90px;margin-top:10px"
						>insert</el-button
					>
				</el-form>
			</el-tab-pane>

			<el-tab-pane label="Nitriding coefficient" name="Nineteenth">
				<el-form :model="formInline" label-width="auto">
					<el-button @click="onSubmit">MNITRID</el-button>
					<el-divider content-position="left"
						>LIMIT（limit concentration of nitrogen in different
						Fe-N phases）</el-divider
					>

					<el-row style="margin-top:40px">
						<el-col :span="6">
							<el-form-item label="a1" style="margin-left:10px">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:10px">
							<el-form-item label="a2">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:10px">
							<el-form-item label="a3">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:10px">
							<el-form-item label="a4">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-left:10px">
							<el-form-item label="a5">
								<el-input
									v-model="materialForm.Name"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button style="margin-left:90px;margin-top:10px"
								>insert</el-button
							>
						</el-col>
					</el-row>

					<el-col :span="5">
						<img
							src="../../assets/img/material19.png"
							style="margin-top:20px;margin-left:195px;margin-bottom:20px"
							alt
						/>
					</el-col>
				</el-form>
			</el-tab-pane>
		</el-tabs>

		<div style="width: 100%; text-align: center">
			<el-button type="success" icon="el-icon-download" @click="onSubmit"
				>保存到本地</el-button
			>
			<el-button type="primary" @click="onSubmit" icon="el-icon-upload"
				>保存到云端</el-button
			>
			<el-button @click="onSubmit">重置</el-button>
		</div>
	</div>
</template>
<script>
// import {} from "@vue/composition-api";

export default {
	data() {
		return {
			materialForm: {},
			activeName: 'seventh',
			radio1: 3,
			radio2: 1,
			radio3: 1,
			radio4: 1,
			radio5: 1,
			radio6: 1,
			textarea: '',
			inp: '',
		};
	},
	mounted() {},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		},
	},
};
</script>
<style scoped></style>
