<template>
    <div>
        <el-col :span="24" class="toolbar" style="padding-bottom: 20px">
            <el-input
                style="width: 200px"
                placeholder="搜索名称"
                v-model="queryStr"
            ></el-input
            >&nbsp;&nbsp;
            <el-button @click="refreshTable()">查询</el-button>
            <el-button type="danger" @click="refreshTable()"
                >刷新列表</el-button
            >
        </el-col>
        <el-table
            :data="tableData"
            style="min-height: 55vh; bacground-color: #ffffff60"
            height="250"
        >
            <!-- <el-table-column
                            prop="Id"
                            label="Id"
                            width="150"
                            :show-overflow-tooltip="true"
                        >
                        </el-table-column> -->
            <el-table-column
                prop="UserId"
                label="用户Id"
                width="220"
                :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="User.Name" label="用户名" width="150">
            </el-table-column>
            <el-table-column prop="IP" label="IP" width="150">
            </el-table-column>
            <el-table-column
                prop="Things"
                label="事件"
                width="300"
                :show-overflow-tooltip="true"
            >
            </el-table-column>

            <el-table-column prop="CreateTime" label="时间" width="180">
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumber"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { SelectLog } from "../api/api";
export default {
    components: {},
    data() {
        return {
            tableData: [], //表数据
            queryStr: "",
            currentPage: 1, //当前是第几页
            pageSize: 7,
            pageSizes: [7, 10, 20, 30],
            totalNumber: 0, //共计多少条数据
            orderBy: "",
            addDialogFormVisible: false,
            editDialogFormVisible: false,
            addAccess: [],
            editAccess: [],
            addForm: {
                username: "",
                password: "",
                name: "",
                type: "",
                access: "",
                tel: "",
                address: "",
                companyId: "",
            },
            editForm: {
                id: "",
                username: "",
                password: "",
                name: "",
                type: "",
                access: "",
                tel: "",
                address: "",
                companyId: "",
            },
            companyList: [],
            formLabelWidth: "120px",
        };
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        //返回首页
        toHome() {
            this.$router.push({ path: "/home" });
        },
        toRegisterManage() {
            this.$router.push({ path: "/home/registerManage" });
        },
        toUserManage() {
            this.$router.push({ path: "/home/userManage" });
        },
        toCompanyManage() {
            this.$router.push({ path: "/home/CompanyManage" });
        },
        toLog() {
            this.$router.push({ path: "/home/Log" });
        },

        //获取表格数据
        getTableData() {
            var pageData = {
                name: this.queryStr,
                desc: false,
                currentPage: this.currentPage - 1,
                pageNumber: this.pageSize,
            };
            SelectLog(pageData).then((res) => {
                this.tableData = res.data;
                this.totalNumber = res.count;
            });
        },
        //刷新表格
        refreshTable() {
            this.getTableData();
        },

        //点击添加按钮（改变添加dialog状态）
        changeDialogFormVisible() {
            this.addDialogFormVisible = true;
        },
        //切换表格每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
            console.log(`每页 ${val} 条`);
        },
        //切换表格当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
            console.log(`当前页: ${val}`);
        },
    },
};
</script>

<style>
.mainBox2 > .border-box-content {
    padding: 76px 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-size: 25px;
    font-weight: 700;
    grid-gap: 20px;
}
</style>

<style scoped>
.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.menu-item {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edebeb69;
    border-radius: 25px;
    width: 80%;
    height: 50px;
    font-size: 20px;
    transition: all 0.2s linear;
}
.menu-item:hover {
    transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -o-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    background-color: #edebeb90;
}

.main-table {
    width: 65vw;
    padding: 20px 0px;
    margin: 0 auto;
}
</style>
