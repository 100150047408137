<template>
    <dv-full-screen-container class="mainContainer">
        <div id="top-header">
            <dv-decoration-8 class="dv-decoration-8 header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration" :dur="5" />
            <dv-decoration-8
                :reverse="true"
                class="dv-decoration-8 header-right-decoration"
            />
            <div class="center-title">{{ $t("lang.mainTitle") }}</div>
            <div class="right-menu">
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="账户管理"
                    placement="bottom-end"
                >
                    <i class="el-icon-menu" @click="toUserManage"></i>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('lang.personalCenter')"
                    placement="bottom-end"
                >
                    <i class="el-icon-user-solid"></i>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('lang.userGuide')"
                    placement="bottom-end"
                >
                    <i class="el-icon-question" @click="toHelper"></i>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('lang.firstpage')"
                    placement="bottom-end"
                >
                    <i class="el-icon-s-home" @click="toHome"></i>
                </el-tooltip>
                <!-- 全屏按钮 -->
                <el-tooltip
                    effect="dark"
                    :content="$t('lang.fullScreenExitFullScreen')"
                    placement="bottom"
                    style="font-size: 23px"
                >
                    <FullScreen class="item" />
                </el-tooltip>
                <!-- 国际化按钮 -->
                <el-tooltip
                    effect="dark"
                    :content="$t('lang.changeLanguage')"
                    placement="bottom"
                    style="font-size: 23px"
                >
                    <Internationalization class="item" />
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('lang.logout')"
                    placement="bottom-end"
                >
                    <i class="el-icon-switch-button" @click="toLogin"></i>
                </el-tooltip>
            </div>
        </div>
        <router-view />
    </dv-full-screen-container>
</template>

<script>
import FullScreen from "../components/Yi-FullScreen.vue";
import Internationalization from "../components/Yi-Internationalize.vue";

export default {
    name: "Home",
    components: {
        FullScreen,
        Internationalization,
    },
    mounted() {
        this.accessInit();
    },
    data() {
        return {
            accessList: [],
            userType: "",
        };
    },
    methods: {
        //路由跳转
        toLogin() {
            localStorage.removeItem("token");
            this.$router.push({ path: "/" });
        },
        toHome() {
            this.$router.push({ path: "/master" });
        },
        //路由跳转
        toCosmap() {
            this.$router.push({ path: "/home/Calculate" });
        },
        toUserManage() {
            this.$router.push({ path: "/home/Usermanage" });
        },
        accessInit() {
            let accessStr = localStorage.getItem("access");
            this.accessList = accessStr.split(",");

            this.userType = localStorage.getItem("type");
        },
        toHelper() {
            this.$router.push({ path: "/Helper" });
        },
    },
};
</script>
<style >
.dv-border-box-8 .border-box-content {
    padding: 76px 40px 10px 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 0.2fr;
    font-size: 25px;
    font-weight: 700;
}
#top-header {
    position: relative;
    width: 100%;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
#top-header .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: 700;
    left: 50%;
    top: 15px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 500px;
    text-align: center;
}
#top-header .right-menu {
    position: absolute;
    font-size: 30px;
    font-weight: 700;
    left: 86%;
    top: 15px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 230px;
}
#top-header .right-menu .right-menu-item {
    width: 35px;
    height: 35px;
    background-color: red;
}

#top-header .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
}
#top-header .header-left-decoration,
#top-header .header-right-decoration {
    margin-top: 15px;
    width: 25%;
    height: 70px;
}
</style>
<style scoped>
.mainContainer {
    font-size: 20px;
    background-image: url("../assets/mainBackground.png");
    background-size: 100% 100%;
    -webkit-box-shadow: 0 0 3px #00f;
    box-shadow: 0 0 3px #00f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.mainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 341px;
    flex-direction: column;
    height: 205px;
    /* background-color: #edebeb69; */
    /* border-radius: 25px; */
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-image: url("../assets/homeItem.png");
    background-size: 100% 100%;
    transition: all 0.3s linear;
}

.mainMenu:hover {
    /* transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -o-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px); */
    /* background-color: #edebeb90; */
    /*善解人意的分割线*/
    /* transition: width 2s, height 2s, transform 2s; */
    transform: scale(1.1);
}

.outFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.footer {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    grid-column-start: span 3;
}
</style>
