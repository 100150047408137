<template>
    <div class="home" v-loading="loading">
        <div id="menu">
           

            <el-button @click="addDialogFormVisible =true" >导入数据</el-button>

            <el-button @click="Optimization()">优化计算</el-button>
              
   
        </div>
        <div id="search">
            <div id="leftsear">
                <label for="depth">渗碳层深度</label>
                <input type="text" name="depth" v-model="depthQueryStr" />
            </div>
            <div id="leftsear">
                <label for="depth">表面碳浓度</label>
                <input
                    type="text"
                    name="conc"
                    v-model="concentrationQueryStr"
                />
            </div>
            <div id="rightsear">
                <label for="conc">硬度</label>
                <input type="text" name="	hardness" v-model="hardnessQueryStr" />
     
                <el-button @click="getTableData()">查询</el-button>
            </div>
        </div>
        <div id="out">
            <div class="log">
                <img src="../assets/img/best.png" alt="" />
                <span>优化结果</span>
            </div>
            <div id="bestre">
                <div id="output">
                    <div id="fir">
                        <span>渗碳温度</span>
                        <div class="re">{{ best.cartem }}</div>
                    </div>
                    <div id="sec">
                        <span>扩散温度</span>
                        <div class="re">{{ best.diftem }}</div>
                    </div>
                    <div id="thi">
                        <span>强渗时间</span>
                        <div class="re">{{ best.inftim }}</div>
                    </div>
                    <div id="for">
                        <span>扩散时间</span>
                        <div class="re">{{ best.diftim }}</div>
                    </div>
                    <div id="fif">
                        <span>强渗碳浓度</span>
                        <div class="re">{{ best.strcon }}</div>
                    </div>
                    <div id="six">
                        <span>扩散碳浓度</span>
                        <div class="re">{{ best.difcon }}</div>
                    </div>
                    <!-- <div id="sev">
                        <span>渗碳层深度</span>
                        <div class="re">{{ best.deplay }}</div>
                    </div>
                    <div id="eig">
                        <span>表面碳浓度</span>
                        <div class="re">{{ best.surcon }}</div>
                    </div> -->
                </div>
            </div>
            <div class="log sec">
                <img src="../assets/img/list.png" alt="" />
                <span>优化列表</span>
            </div>
            <div id="result">
                <el-table :data="tableData" class="table">
                    <el-table-column
                        prop="CarburizingTemperature"
                        label="渗碳温度°C"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        label="扩散温度°C"
                        prop="DiffusionTemperature"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="InfiltrationTime"
                        label="强渗时间min"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="DiffusionTime"
                        label="扩散时间min"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="StrongCarburizingConcentration"
                        label="强渗碳浓度%"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="DiffusiveCarbonConcentration"
                        label="扩散碳浓度%"
                        align="center"
                    >
                    </el-table-column>
                    <!-- <el-table-column
                        prop="DepthOfCarburizedLayer"
                        label="渗碳层深度mm"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="SurfaceCarbonConcentration"
                        label="表面碳浓度%"
                        align="center"
                    >
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
        <el-dialog
            title="导入数据"
            :visible.sync="addDialogFormVisible"
            :append-to-body="true"
        >
            <el-form >
              
                <el-form-item label="数据文件" :label-width="formLabelWidth">
                    <el-upload
                        class="upload-demo"
                        drag
                        :action="baseUrl + 'Material/FileUpLoad'"
                        :multiple="false"
                        :limit="1"
                        ref="uploadAdd"
                        :on-success="addUploadSuccess"
                        :on-error="addUploadError"
                        accept="."
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            请将数据文件拖至此处，或
                            <em>点击上传</em>
                        </div>
                    </el-upload>
                </el-form-item>
             
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="AddConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import FullScreen from "../components/Yi-FullScreen.vue";
import Internationalization from "../components/Yi-Internationalize.vue";
import { SelectHeatTreatment, Url } from "../api/api";
export default {
    name: "Home",
    components: {
        FullScreen,
        Internationalization,
    },
    data() {
        return {
            baseUrl: Url,
            tableData: [], //表数据
            depthQueryStr: "",
            concentrationQueryStr: "",
            hardnessQueryStr: "",
            best: {
                cartem: "",
                diftem: "",
                inftim: "",
                diftim: "",
                strcon: "",
                difcon: "",
                deplay: "",
                surcon: "",
            },
            addDialogFormVisible:false,
            loading:false
        };
    },
    methods: {
        toLogin() {
            localStorage.removeItem("token");
            this.$router.push({ path: "/home" });
        },
        toHome() {
            this.$router.push({ path: "/home/main" });
        },
        //路由跳转
        toCosmap() {
            this.$router.push({ path: "/home/Calculate" });
        },
        toUserManage() {
            this.$router.push({ path: "/home/Usermanage" });
        },
        accessInit() {
            let accessStr = localStorage.getItem("access");
            this.accessList = accessStr.split(",");

            this.userType = localStorage.getItem("type");
        },
        toHelper() {
            this.$router.push({ path: "/home/Helper" });
        },

        //获取表格数据
        getTableData() {
            this.tableData = [];
            var pageData = {
                depthOfCarburizingLayer: parseFloat(this.depthQueryStr),
                surfaceCarbonConcentration: parseFloat(
                    this.concentrationQueryStr
                ),
                surfaceHardness: parseFloat(this.hardnessQueryStr),
            };
            SelectHeatTreatment(pageData).then((res) => {
                if (res) {
                    res = res.Data;
                    this.tableData = [];
                    this.best.cartem = res[0][0];
                    this.best.diftem = res[0][1];
                    this.best.inftim = res[0][2];
                    this.best.diftim = res[0][3];
                    this.best.strcon = res[0][4];
                    this.best.difcon = res[0][5];
                    this.best.deplay = res[0][6];
                    this.best.surcon = res[0][7];

                    res.forEach((element) => {
                        this.tableData.push({
                            CarburizingTemperature: element[0],
                            DiffusionTemperature: element[1],
                            InfiltrationTime: element[2],
                            DiffusionTime: element[3],
                            StrongCarburizingConcentration: element[4],
                            DiffusiveCarbonConcentration: element[5],
                            DepthOfCarburizedLayer: element[6],
                            SurfaceCarbonConcentration: element[7],
                        });
                    });
                } else {
                    console.log(typeof res);
                    this.tableData = [];
                    this.tableData.push(res);
                }
            });

    
        },
        Optimization(){
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.$notify({
                        title: "成功",
                        message: "数据回归成功！可在下方进行查询操作",
                        type: "success",
                    });
            }, 5000);
        }
    },
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    height: 80%;
}
#out {
    background: url("../assets/img/sback.png");
    background-size: 100% 100%;
    padding-top: 1%;
    /* height: 70%; */
    opacity: 90%;
    /* position: fixed; */
    width: 90%;
    margin: auto;
    height: 75%;
}
#menu{

    color: #ffffff;
    display: flex;
    height: 15%;
    font-size: 28px;
    /* 主轴居中 */
    justify-content: space-around;
    /* 侧轴剧中 */
    align-items: center;
}
#menu button {
    width: 10%;
    height:50%;
    font-weight: 700;
    font-size: 3rem;
    outline: none;
    border: 0px;
    margin-left: 4%;
    line-height: 3rem;
}
#menu button:hover {
    width: 10%;
    height: 50%;
    font-weight: 700;
    font-size: 3rem;
    outline: none;
    border: 0px;
    margin-left: 4%;
    line-height: 3rem;
}
#search {
    justify-content: space-between;
    color: #ffffff;
    display: flex;
    height: 25%;
    font-size: 28px;
    /* margin-bottom: 2%; */
}

input {
    outline: none;
    margin-left: 18px;
    height: 18%;
    font-size: 22px;
}


#search button {
    width: 25%;
    height: 25%;
    font-weight: 700;
    font-size: 3rem;
    outline: none;
    border: 0px;
    margin-left: 4%;
}
#search button:hover {
    width: 25%;
    height: 25%;
    font-weight: 700;
    font-size: 3rem;
    outline: none;
    border: 0px;
    margin-left: 4%;
}
#search #leftsear {
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;

    width: 50%;
}
#search #leftsear label {
    margin-left: 15%;
}
#search #rightsear {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 50%;
}
#search #rightsear button {
    margin-right: 15%;
}
#bestre {
    height: 15%;
    display: flex;
    align-items: flex-end;
}
#bestre #output {
    width: 100%;
    height: 100%;
    padding: 0 1.5% 0 1.5%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* font-size: 20px; */
}
#bestre #output .re {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 7%;
    color: #ffffff;
}
#bestre #output div {
    height: 100%;
}
#bestre #output img {
    height: 100%;
}
#bestre #output #fir {
    width: 10%;
    height: 100%;
    background: url("../assets/img/back1.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #sec {
    width: 10%;
    background: url("../assets/img/back2.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #thi {
    width: 10%;
    background: url("../assets/img/back3.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #for {
    width: 10%;
    background: url("../assets/img/back4.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #fif {
    width: 11%;
    background: url("../assets/img/back5.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #six {
    width: 11%;
    background: url("../assets/img/back6.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #sev {
    width: 11%;
    background: url("../assets/img/back7.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output #eig {
    width: 11%;
    background: url("../assets/img/back8.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#bestre #output div span {
    padding-left: 8px;
    color: #e4f3ff;
    font-size: 19px;
    /* margin-top: -3px; */
}
#result {
    height: 70%;
    /* display: flex; */
    /* justify-content: center; */
}
#result .table {
    width: 96%;
    height: 90%;
    margin: auto;
    font-size: 22px;
}
.log {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2%;
    margin-bottom: 12px;
}
.sec {
    margin-top: 40px;
}
.log img {
    width: 20px;
    margin-right: 3px;
}
.log span {
    color: #ffffff;
    font-size: 20px;
}
</style>
