var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tabs',{staticStyle:{"color":"black","min-height":"60vh"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{staticStyle:{"text-align":"center"},attrs:{"label":"Temper(STEMPER)","name":"first"}},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("Temper(STEMPER)")]),_c('processTable',{attrs:{"parentTableData":_vm.$store.state.ProcessProperties.STEMPER}})],1),_c('el-tab-pane',{staticStyle:{"text-align":"center"},attrs:{"label":"Control Function(TEMPSP)","name":"second"}},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("Control Function(TEMPSP)")]),_c('processTable',{attrs:{"parentTableData":_vm.$store.state.ProcessProperties.TEMPSP}})],1),_c('el-tab-pane',{staticStyle:{"text-align":"center"},attrs:{"label":"Convergence Control(MCONT)","name":"third"}},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("Convergence Control(MCONT)")]),_c('el-form',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"The type of solver"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.SolverType
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "SolverType", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.SolverType\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"The ICCG convergence decision value"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.NameICCGConvergenceDecision
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "NameICCGConvergenceDecision", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.NameICCGConvergenceDecision\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Maximum bandwidth adjustment factor"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.MaximumBandwidthAdjustmentFactor
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "MaximumBandwidthAdjustmentFactor", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.MaximumBandwidthAdjustmentFactor\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Bandwidth adjustment factor"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.BandwidthAdjustmentFactor
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "BandwidthAdjustmentFactor", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.BandwidthAdjustmentFactor\n\t\t\t\t\t\t\t\t"}})],1)],1)],1)],1),_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("The Recalculation")]),_c('el-form',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Recalculation"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.Recalculation
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "Recalculation", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.Recalculation\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"The calculation frequency"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.CalculationFrequency
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "CalculationFrequency", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.CalculationFrequency\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Permission maximum value of the serration number"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.PermissionMaximumValueOfTheSerrationNumber
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "PermissionMaximumValueOfTheSerrationNumber", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.PermissionMaximumValueOfTheSerrationNumber\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"The hourly width reduction rate"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.HourlyWidthReductionRate
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "HourlyWidthReductionRate", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.HourlyWidthReductionRate\n\t\t\t\t\t\t\t\t"}})],1)],1)],1)],1),_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("The diffusion calculation")]),_c('el-form',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"The repetition maximum"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.RepetitionMaximum
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "RepetitionMaximum", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.RepetitionMaximum\n\t\t\t\t\t\t\t\t"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"The convergence decision value"}},[_c('el-input',{model:{value:(
									_vm.$store.state.ProcessProperties.MCONT
										.ConvergenceDecision
								),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.MCONT
										, "ConvergenceDecision", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t$store.state.ProcessProperties.MCONT\n\t\t\t\t\t\t\t\t\t\t.ConvergenceDecision\n\t\t\t\t\t\t\t\t"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }