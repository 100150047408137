<template>
    <div class="container">
      <div class="main">
        <!-- <div style="overflow: hidden"></div> -->
        <div class="text-main">
          <div class="nav">
            <ul class="nav-u">
              <li class="vl" :class="{ active: show == 1 }">
                <i @click="goView(), tofirst()">我要参观</i>
              </li>
              <li class="vl" :class="{ active: show == 2 }">
                <i @click="goComputer(), toSecond()">我要上机</i>
              </li>
              <li class="vl" :class="{ active: show == 3 }">
                <i @click="goCoperate(), toThird()">我要合作</i>
              </li>
              <li class="vl" :class="{ active: show == 4 }">
                <i @click="goTeach(), toFourth()">视频教学</i>
              </li>
              <li class="vl" :class="{ active: show == 5 }">
                <i @click="goHome(), toFifth()">回到首页</i>
              </li>
            </ul>
          </div>
          <router-view />
        </div>
  
        <div class="footer">
          <div class="info">版权所有：&copy; COSMAP</div>
          <div class="info">中丨English丨日本語</div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Sidentify from "../components/Sidentify"; //**引入验证码组件**
  export default {
    components: {
      "v-sidentify": Sidentify,
    },
    data() {
      return {
        dialogVisible: false,
        validationEmaildialogVisible: false,
        verificationCode: "",
        validationEmailCode: "",
        agreementState: false,
        // 导航栏样式切换
        show: 1,
      };
    },
    watch: {
      "data.show"(item1, item2) {
        show1();
        {
          if (item == 1) {
            goView();
          }
          if (item == 2) {
            goComputer();
          }
          if (item == 3) {
            goCoperate();
          }
          if (item == 4) {
            goTeach();
          }
        }
      },
    },
  
    methods: {
      tofirst() {
        this.show = 1;
      },
      toSecond() {
        this.show = 2;
      },
      toThird() {
        this.show = 3;
      },
      toFourth() {
        this.show = 4;
      },
      toFifth() {
        this.show = 5;
      },
      goHome() {
              this.$router.push({ path: "master" });
          },
      goView() {
        this.$router.push({ path: "ToView" });
      },
      goComputer() {
        this.$router.push({ path: "ToComputer" });
      },
      goCoperate() {
        this.$router.push({ path: "ToCoperate" });
      },
      goTeach() {
        this.$router.push({ path: "ToTeach" });
      },
      goFuture() {
        this.$router.push({ path: "ToFUture" });
      },
    },
    mounted: function() {
      this.goView();
    },
    // handleClose(done) {
    //     this.$confirm("")
    //         .then((_) => {
    //             done();
    //         })
    //         .catch((_) => {});
    // },
  };
  </script>
  <style scoped>
  .container {
    width: 100%;
    height: 100%;
  }
  .main {
    background-color: #4780ff;
    height: 60vh;
    background-image: url("../assets/loginBackground.png");
    background-size: 100% 100%;
  }
  
  /*  */
  .text-main {
    position: relative;
    margin: auto;
    left: 0;
    top: 100px;
    bottom: 0;
    right: 0;
    width: 75%;
    height: 820px;
    border-radius: 2px;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    min-width: 600px;
  }
  .nav {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20%;
    height: 200px;
    border-radius: 2px;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
    padding: 6px;
    min-width: 150px;
    /* background-color: #4780ff; */
  }
  .nav ul {
    display: inline;
    height: 100%;
    /* weight:100% */
  }
  .nav li {
    width: 100%;
    height: 20%;
    border-bottom: 1px solid #ccc;
  }
  .nav li i {
    position: absolute;
    width: 50%;
    text-align: center;
    padding: 5px 16px;
    line-height: 32px;
    text-align: center;
    font-size: 15px;
    color: black;
    font-style: normal;
    font-family: "Microsoft Yahei", "新宋体";
  }
  .nav li i:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .active {
    background-color: #f2f8fc;
    border-left: 5px solid #008fc7;
    border-bottom: none;
  }
  .active i {
    color: #008fc7 !important;
  }
  
  /*  */
  .footer {
    position: relative;
    top: 200px;
    /* background-color: white; */
    text-align: center;
  
    height: 60px;
  }
  .footer .info {
    font-size: 15px;
    color: #8492a6;
    width: 200px;
    margin: 0 auto;
  }
  
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  </style>
  