export const lang = {
    personalCenter: 'センター',

    changeLanguage: '言語を切り替え',

    heatTreatmentOptimizationSystem: '熱処理最適化システム',

    intelligentManufacturing: 'インテリジェント',

    accountManagement: 'アカウント管理',

    cosmap: 'COSMAP',

    copyright: '著作権所有',

    mainTitle: '先進熱処理知能製造',

    logout: 'ログアウト',

    userGuide: '使用マニュアル',

    startCalculation: '計算を開始します',

    fullScreenExitFullScreen: 'フルスクリーン/全画面終了',

    home: 'ホームページ',

    firstpage: 'ホームページ',

    equipmentManagement: '設備庫',

    materialManagement: 'マテリアルライブラリー',

    optimizeManagement: 'プロセス最適化ライブラリ',

    modelManagement: 'モデルライブラリー',

    platformGuide: 'プラットフォーム使用ガイド',

    cosmapHelper: 'COSMAPのガイド',

    guideToAdvancedHeatTreatmentSystems: '高度な熱処理システムのガイド',

    accountManagement: 'アカウント管理',

    ProcessManagement: 'プロセス管理',

    HeatTreatmenCalculation: 'ねつしょりけいさん',

    EnergyConsumptionAnalysis: 'エネルギー消費量分析',

    SafetyAndEnvironmentalProtection: '安全で環境に優しい',

    RateOfProgress: 'しんちょく',

    EquipmentManagement: 'デバイス管理',

    IntelligentHeatTreatmentSystem: 'インテリジェント熱処理システム',

    guideToAdvancedHeatTreatmentSystems: '高度な熱処理システムのガイド',

    heatTreatmentIntelligentManufacturingSystem: '熱処理インテリジェント製造システム',

    pleaseEnterOneUserName: 'ユーザー名を入力してください',

    pleaseInputPassword: 'パスワードを入力してください',

    pleaseEnterTheVerificationCode: '認証コードを入力してください',

    forgetPassword: 'パスワードを忘れる',

    applicationForUse: '使用を申し込む',

    login: 'ログイン',

    EnlargeScreen: '拡大画面',

    ReduceScreen: 'スクリーンを縮小',
};