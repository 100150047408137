<template>
    <div class="header-menu-item" @click="ChangeLanguage">
        <i class="el-icon-expandziyuan"></i>
    </div>
</template>
<script>
export default {
    name: "",
    methods: {
        ChangeLanguage() {
            if (this.$i18n.locale == "zh") {
                this.$i18n.locale = "en";
            } else if (this.$i18n.locale == "en") {
                this.$i18n.locale = "jp";
            } else {
                this.$i18n.locale = "zh";
            }
        },
        mounted() {},
    },
};
</script>
<style scoped>
</style>
