<template>
	<div>
		<el-table
			ref="processTable"
			:data="tableData"
			highlight-current-row
			@current-change="handleCurrentChange"
			max-height="450"
		>
			<el-table-column prop="No" label="NO." width="250px">
				<template slot-scope="scope">
					<el-input size="mini" v-model="scope.row.No"></el-input>
				</template>
			</el-table-column>
			<el-table-column
				prop="HeatingTime"
				label="Heating Time"
				width="250px"
			>
				<template slot-scope="scope">
					<el-input size="mini" v-model="scope.row.Time"></el-input>
				</template>
			</el-table-column>
			<el-table-column
				prop="StepNumber"
				label="Step Number"
				width="250px"
			>
				<template slot-scope="scope">
					<el-input
						size="mini"
						v-model="scope.row.StepNumber"
					></el-input>
				</template>
			</el-table-column>
			<el-table-column
				prop="OutputInterval"
				label="Output Interval"
				width="250px"
			>
				<template slot-scope="scope">
					<el-input
						size="mini"
						v-model="scope.row.OutputInterval"
					></el-input>
				</template>
			</el-table-column>
		</el-table>
		<el-button @click="addRow" size="mini" round>添加数据</el-button>
		<el-button @click="removeCurrentRow" size="mini" round
			>取消选中行</el-button
		>
		<el-button @click="deleteRow" size="mini" round>删除数据</el-button>
		<el-button size="mini" round>批量添加</el-button>
	</div>
</template>
<script>
export default {
	props: ['parentTableData'],
	data() {
		return {
			tableData: this.parentTableData,
			currentRow: {},
		};
	},
	mounted() {},
	methods: {
		addRow() {
			this.tableData.push({
				No: '',
				Time: '',
				StepNumber: 0,
				OutputInterval: 0,
			});
		},
		deleteRow() {
			var a = this.tableData.indexOf(this.currentRow);
			this.tableData.splice(a, 1);
			console.log(this.tableData);
		},

		handleCurrentChange(val) {
			this.currentRow = val;
		},
		removeCurrentRow() {
			this.$refs.processTable.setCurrentRow();
		},
	},
};
</script>
