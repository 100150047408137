<template>
    <div class="container">
        <div class="main">
            <div style="overflow: hidden"></div>
            <div class="register-main">
                <div class="register-main-content">
                    <div class="login-title">切换您的账号</div>

                    <div class="register-input">
                        <el-form
                            :model="addForm"
                            ref="form"
                            label-width="80px"
                            label-position="top"
                            :rules="rules"
                        >
                            <el-form-item label="邮箱:" prop="email">
                                <el-input
                                    placeholder="请输入您的邮箱"
                                    v-model="addForm.email"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-message"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="姓名:">
                                <el-input
                                    placeholder="请输入您的姓名"
                                    v-model="addForm.name"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-s-custom"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="用户名:">
                                <el-input
                                    placeholder="请输入用户名"
                                    v-model="addForm.username"
                                >
                                    <i
                                        slot="prefix"
                                        class="
                                            el-input__icon
                                            el-icon-user-solid
                                        "
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="密码:">
                                <el-input
                                    placeholder="请输入密码"
                                    v-model="addForm.password"
                                    show-password
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-lock"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="联系电话:">
                                <el-input
                                    placeholder="请输入联系电话"
                                    v-model="addForm.tel"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-phone"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="通讯地址:">
                                <el-input
                                    placeholder="请输入通讯地址"
                                    v-model="addForm.address"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-s-home"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="验证码:">
                                <div class="sidentifyContent">
                                    <el-input
                                        placeholder="请输入验证码"
                                        v-model="verificationCode"
                                    >
                                        <i
                                            slot="prefix"
                                            class="
                                                el-input__icon
                                                el-icon-s-claim
                                            "
                                        ></i>
                                    </el-input>
                                    <v-sidentify></v-sidentify>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="login-main-right-button">
                        <el-button
                            style="width: 100%; font-size: 18px"
                            type="danger"
                            @click="RegisterConfirm"
                            >注册</el-button
                        >
                        <div>
                            <div style="position: relative">
                                <el-button
                                    style="
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-left: 2%;
                                    "
                                    type="text"
                                    v-if="agreementState"
                                    >您已同意用户协议</el-button
                                >
                                <el-button
                                    style="
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-left: 2%;
                                    "
                                    type="text"
                                    @click="WatchAgreement"
                                    v-if="!agreementState"
                                    >点击阅读用户协议</el-button
                                >
                                <p
                                    style="
                                        position: absolute;
                                        top: 20%;
                                        margin-left: 85%;
                                        font-size: 16px;
                                        font-weight: 600;
                                    "
                                >
                                    <a href="/">已有账号?</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer">
                <div class="info">版权所有：&copy; COSMAP</div>
                <div class="info">中丨English丨日本語</div>
            </div>
        </div>

        <el-dialog
            title="验证您的邮箱"
            :visible.sync="validationEmaildialogVisible"
            width="60%"
        >
            <div style="height: 70px">
                为了验证您的邮箱，请检查您输入邮箱中的邮件，并将其中的验证码输入到下方
            </div>
            <div style="height: 100px">
                <el-form label-width="80px">
                    <el-form-item label="验证码:">
                        <el-input
                            placeholder="请输入您收到的验证码"
                            v-model="validationEmailCode"
                        >
                            <i
                                slot="prefix"
                                class="el-input__icon el-icon-message"
                            ></i>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="validationEmaildialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="validationEmail"
                    >确定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog title="用户协议" :visible.sync="dialogVisible" width="60%">
            <div style="height: 300px">用户协议</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AgreeAgreement"
                    >接 受</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    RegisterUser,
    SendEmailToRegister,
    RegisterEmailValidation,
} from "../api/api";
import Sidentify from "../components/Sidentify"; //**引入验证码组件**
export default {
    components: {
        "v-sidentify": Sidentify,
    },
    data() {
        return {
            dialogVisible: false,
            validationEmaildialogVisible: false,
            verificationCode: "",
            validationEmailCode: "",
            agreementState: false,

            addForm: {
                email: null,
                username: "",
                password: "",
                name: "",
                tel: "",
                address: "",
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern:
                            /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                        message: "邮箱格式异常",
                        trigger: "blur",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        // this.notice();
    },
    methods: {
        WatchAgreement() {
            this.dialogVisible = true;
        },
        AgreeAgreement() {
            this.agreementState = true;
            this.dialogVisible = false;
        },
        validationEmailClick() {},
        validationEmail() {
            let validateFlag = false;
            let parms = {
                Email: this.addForm.email,
                Code: this.validationEmailCode,
            };
            RegisterEmailValidation(parms).then((res) => {
                if (res == true) {
                    RegisterUser(this.addForm).then((res) => {
                        if (res == true) {
                            this.$message({
                                message:
                                    "注册成功，请等待管理员审核，即将在5秒后跳转至主页登陆",
                                type: "success",
                            });
                            setTimeout(() => {
                                this.$router.push({
                                    path: "/Helper",
                                });
                            }, 5000);
                        } else {
                            this.$message.error("注册失败，请联系管理员！");
                        }
                        this.validationEmaildialogVisible = false;
                    });
                } else {
                    this.$message.error("您的验证码输入有误！");
                }
            });
        },
        //点击确认添加按钮
        RegisterConfirm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    console.log(valid);
                } else {
                    return;
                }
            });
            if (this.agreementState == false) {
                this.$message({
                    message: "请先接受用户协议",
                    type: "warning",
                });
                return;
            }
            var parms = {
                Email: this.addForm.email,
            };
            SendEmailToRegister(parms).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "已将验证码发送到您的邮箱",
                        type: "success",
                    });
                    this.validationEmaildialogVisible = true;
                } else {
                    this.$message.error("注册失败，请联系管理员！");
                }
            });
        },
        notice() {
            //提示登录成功
            this.$notify({
                title: "登陆成功",
                message: "您好，欢迎使用先进热处理智能制造系统",
                type: "success",
            });
        },
    },
};
</script>
<style scoped>
.main {
    background-color: #4780ff;
    height: 60vh;
    background-image: url("../assets/loginBackground.png");
    background-size: 100% 100%;
}
.main .register-main {
    margin: 10vh auto 5vh auto;
    padding-bottom: 10px;
    background-color: white;

    width: 900px;
    border-radius: 2px;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
}

.register-main-content {
    margin: 0 auto;

    width: 500px;
    height: 100%;

    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.login-main-right-button {
    width: 100%;
    height: 70px;
    margin-bottom: 30px;
}

.login-main-right-button a {
    color: #8492a6;
    font-size: 15px;
}
.register-input {
    min-height: 190x;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.login-title {
    padding-top: 30px;

    text-align: center;
    color: #f56c6c;
    font-size: 26px;
    font-weight: 550;
    border-bottom: 1px solid #f56c6c;
    height: 45px;
    margin-bottom: 10px;
}
.sidentifyContent {
    display: flex;
    flex-direction: row;
}
.sidentifyContent .el-input__icon {
    height: auto;
}
.footer {
    background-color: white;
    text-align: center;

    height: 60px;
}
.footer .info {
    font-size: 15px;
    color: #8492a6;
    width: 200px;
    margin: 0 auto;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>