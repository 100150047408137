<template>
    <div class="container">
        <div class="main">
            <div class="login-main">
                <div class="login-main-left">
                    <div class="block">
                        <el-carousel
                            height="400px"
                            class="login-main-left-carousel"
                        >
                            <!-- <el-carousel-item v-for="item in 4" :key="item">
                                <h3 class="small">{{ item }}</h3>
                            </el-carousel-item> -->
                            <el-carousel-item :key="1">
                                <img
                                    src="../assets/slider1.png"
                                    width="100%"
                                    height="100%"
                                />
                            </el-carousel-item>
                            <el-carousel-item :key="2">
                                <img
                                    src="../assets/slider2.png"
                                    width="100%"
                                    height="100%"
                                />
                            </el-carousel-item>
                            <el-carousel-item :key="3">
                                <img
                                    src="../assets/slider3.png"
                                    width="100%"
                                    height="100%"
                                />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <div class="login-main-right">
                    <div class="login-title">
                        {{
                            $t(
                                "lang.heatTreatmentIntelligentManufacturingSystem"
                            )
                        }}
                    </div>
                    <div class="login-input">
                        <el-input
                            :placeholder="$t('lang.pleaseEnterOneUserName')"
                            v-model="username"
                        >
                            <i
                                slot="prefix"
                                class="el-input__icon el-icon-user-solid"
                            ></i>
                        </el-input>
                        <el-input
                            :placeholder="$t('lang.pleaseInputPassword')"
                            v-model="password"
                            show-password
                        >
                            <i
                                slot="prefix"
                                class="el-input__icon el-icon-lock"
                            ></i>
                        </el-input>
                        <div class="sidentifyContent">
                            <el-input
                                :placeholder="
                                    $t('lang.pleaseEnterTheVerificationCode')
                                "
                                v-model="verificationCode"
                                @keyup.enter.native="login"
                            >
                                <i
                                    slot="prefix"
                                    class="el-input__icon el-icon-s-claim"
                                ></i>
                            </el-input>
                            <v-sidentify></v-sidentify>
                        </div>
                    </div>
                    <div class="login-main-right-button" >
                        <el-button
                            style="width: 100%; font-size: 18px"
                            type="danger"
                            @click="login"
                            round
                            >{{ $t("lang.login") }}</el-button
                        >
                        <div style="margin-top: 5px">
                            <div style="width: 100%; text-align: center">
                                <span
                                    style="
                                    position: relative;
                                    <!-- left: 80%; -->
                                    color: black;
                                "
                                    ><a href="/PasswordForget">{{
                                        $t("lang.forgetPassword")
                                    }}</a></span
                                >
                            </div>
                            <div style="width: 100%; text-align: center">
                                <span
                                    style="
                                        position: reletive;
                                        left: 80%;
                                        color: black;
                                    "
                                >
                                    <a href="/register">{{
                                        $t("lang.applicationForUse")
                                    }}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="changeLang-button">
                    <button type="button" class="btn" @click="ChangeZh">
                        简体中文</button
                    >丨
                    <button type="button" class="btn" @click="ChangeEn">
                        English</button
                    >丨
                    <button type="button" class="btn" @click="ChangeJp">
                        Japanese
                    </button>
                </div>
            </div>
            <div class=""></div>
        </div>
        <div class="footer">
            <div class="info">
                {{ $t("lang.copyright") }}：&copy; {{ $t("lang.cosmap") }}
            </div>
        </div>
    </div>
</template>
<script>
import Sidentify from "../components/Sidentify"; //**引入验证码组件**
import { Login } from "../api/api";
export default {
    components: {
        "v-sidentify": Sidentify,
    },
    data() {
        return {
            username: "",
            password: "",
            verificationCode: "",
        };
    },
    methods: {
        ChangeZh() {
            this.$i18n.locale = "zh";
        },
        ChangeEn() {
            this.$i18n.locale = "en";
        },
        ChangeJp() {
            this.$i18n.locale = "jp";
        },

        login: function () {
            if (this.verificationCode != "1234") {
                this.$message.error("验证码输入错误");
                return;
            }
            let data = {
                name: this.username,
                pass: this.password,
            };
            Login(data).then((res) => {
                console.log(res);
                if (res != undefined) {
                    let token = res.token;
                    let id = res.user.Id;
                    let access = res.user.Access;
                    let name = res.user.Name;
                    let type = res.user.Type;
                    let ValidityTime = res.user.ValidityTime;

                    localStorage.setItem("name", name);
                    localStorage.setItem("access", access);
                    localStorage.setItem("id", id);
                    localStorage.setItem("token", token);
                    localStorage.setItem("type", type);
                    localStorage.setItem("V_time", ValidityTime);
                    console.log(localStorage.getItem("access"));
                    this.$router.push({
                        path: "/Master",
                    });
                    //提示登录成功
                    this.$notify({
                        title: "登陆成功",
                        message:
                            name + "，您好，欢迎使用先进热处理智能制造系统",
                        type: "success",
                    });
                } else {
                    this.$message.error("账号或密码错误");
                }
            });
            // if (this.username == "user" && this.password == "123456") {
            //     this.$notify({
            //         title: "登陆成功",
            //         message: "用户1，您好，欢迎使用先进热处理智能制造系统",
            //         type: "success",
            //     });
            //     this.$router.push({
            //         path: "/home",
            //         query: {
            //             aa: "1",
            //             bb: "2",
            //         },
            //     });
            // } else if (this.username == "user2" && this.password == "123456") {
            //     this.$notify({
            //         title: "账号无效",
            //         message: "您好，您的账号无效，请及时购买/续费",
            //     });
            // } else {
            //     this.$message.error("账号或密码错误");
            // }
        },
    },
};
</script>
<style scoped>
.main {
    background-color: #4780ff;
    height: 65vh;
    background-image: url("../assets/loginBackground.jpg");
    background-size: 100% 100%;
}
.main .login-main {
    position: relative; /*设置position属性*/
    top: 25vh; /*偏移*/
    margin-top: 30vh;
    margin: 0 auto;

    width: 1100px;
    height: 520px;

    border-radius: 2px;
    /* border: 1px solid #ebedf0; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
}
.login-main-left {
    background-color: white;
    width: 700px;
    height: 100%;
    float: left;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.login-main-left-carousel {
    padding: 0px 20px;
}
.login-main-right {
    background-color: white;
    width: 320px;
    height: 100%;
    float: left;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.login-main-right-button {
    width: 100%;
    height: 70px;
}
.login-main-right-button a {
    color: #000;
    font-size: 15px;
    overflow: hidden;
}
.login-input {
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.login-title {
    text-align: center;
    color: #f56c6c;
    font-size: 26px;
    font-weight: 550;
    border-bottom: 1px solid #f56c6c;
    height: 45px;
}
.sidentifyContent {
    display: flex;
    flex-direction: row;
}
.footer {
    background-color: white;
    height: 35vh;
    text-align: center;
}
.footer .info {
    position: relative;
    top: 80%;
    color: #8492a6;
    width: 300px;
    margin: 0 auto;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.changeLang-button {
    background-color: white;
    text-align: center;
    color: #8492a6;
}
.btn {
    background-color: white;
    color: #212325;
    cursor: pointer;
}
</style>
