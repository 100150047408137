var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{attrs:{"label-position":"top"}},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("MSUFDC ( Carburization )")]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"label":"Surface diffusion coefficient"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.DIFUSSION.MSUFDC
                                .SurfaceDiffusionCoefficient
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.DIFUSSION.MSUFDC
                                , "SurfaceDiffusionCoefficient", $$v)},expression:"\n                            $store.state.ProcessProperties.DIFUSSION.MSUFDC\n                                .SurfaceDiffusionCoefficient\n                        "}})],1)],1),_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"label":"Suface circumstance concentration"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.DIFUSSION.MSUFDC
                                .SufaceCircumstanceConcentration
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.DIFUSSION.MSUFDC
                                , "SufaceCircumstanceConcentration", $$v)},expression:"\n                            $store.state.ProcessProperties.DIFUSSION.MSUFDC\n                                .SufaceCircumstanceConcentration\n                        "}})],1)],1)],1),_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("MSUFDN ( Nitriding )")]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"label":"Surface diffusion coefficient"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.DIFUSSION.MSUFDN
                                .SurfaceDiffusionCoefficient
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.DIFUSSION.MSUFDN
                                , "SurfaceDiffusionCoefficient", $$v)},expression:"\n                            $store.state.ProcessProperties.DIFUSSION.MSUFDN\n                                .SurfaceDiffusionCoefficient\n                        "}})],1)],1),_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"label":"Suface circumstance concentration"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.DIFUSSION.MSUFDN
                                .SufaceCircumstanceConcentration
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.DIFUSSION.MSUFDN
                                , "SufaceCircumstanceConcentration", $$v)},expression:"\n                            $store.state.ProcessProperties.DIFUSSION.MSUFDN\n                                .SufaceCircumstanceConcentration\n                        "}})],1)],1)],1),_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("MSUFDO ( Other )")]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"label":"Surface diffusion coefficient"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.DIFUSSION.MSUFDO
                                .SurfaceDiffusionCoefficient
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.DIFUSSION.MSUFDO
                                , "SurfaceDiffusionCoefficient", $$v)},expression:"\n                            $store.state.ProcessProperties.DIFUSSION.MSUFDO\n                                .SurfaceDiffusionCoefficient\n                        "}})],1)],1),_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"label":"Suface circumstance concentration"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.DIFUSSION.MSUFDO
                                .SufaceCircumstanceConcentration
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.DIFUSSION.MSUFDO
                                , "SufaceCircumstanceConcentration", $$v)},expression:"\n                            $store.state.ProcessProperties.DIFUSSION.MSUFDO\n                                .SufaceCircumstanceConcentration\n                        "}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }