export const lang = {
	login: '登录',

	register: '注册',

	rember: '记住密码',

	home: '首页',

	about: '关于',

	aboutDesc: '这是关于页面',

	usermanagement: '用户管理',

	user: '用户',

	onlineComputing: '在线计算',

	reserve: '预留',

	firstpage: '主页',

	changeLanguege: '切换语言',

	showHideideNavigationBar: '显示/隐藏侧边导航栏',

	fullScreenExitFullScreen: '全屏/退出全屏',

	dropDownList: '下拉菜单',

	find: '查找',

	add: '添加',

	refresh: '刷新列表',

	downLoad: '下载',

	error: '异常',

	device: '设备',

	message: '消息',

	personalCenter: '个人中心',

	changeLanguage: '切换语言',

	heatTreatmentOptimizationSystem: '热处理优化系统',

	intelligentManufacturing: '智能制造',

	accountManagement: '账户管理',

	cosmap: 'COSMAP',

	copyright: '版权所有',

	mainTitle: '先进热处理智能制造',

	logout: '退出登录',

	userGuide: '使用指南',

	startCalculation: '开始计算',

	equipmentManagement: '设备库',

	materialManagement: '材料物性与冷却介质库',

	optimizeManagement: '工艺参数库',

	modelManagement: '模型库',

	platformGuide: '平台使用指南',

	cosmapHelper: 'COSMAP指南',

	guideToAdvancedHeatTreatmentSystems: '先进热处理系统指南',

	heatTreatmentIntelligentManufacturingSystem: '热处理智能制造系统',

	IntelligentHeatTreatmentSystem: '热处理智能制造系统',

	ProcessManagement: '进度质量',

	HeatTreatmenCalculation: '热处理计算',

	EnergyConsumptionAnalysis: '能耗分析',

	SafetyAndEnvironmentalProtection: '安全环保',

	RateOfProgress: '流程管理',

	EquipmentManagement: '设备管理',

	pleaseEnterOneUserName: '请输入用户名',

	pleaseInputPassword: '请输入密码',

	pleaseEnterTheVerificationCode: '请输入验证码',

	forgetPassword: '忘记密码',

	applicationForUse: '申请使用',

	EnlargeScreen: '放大屏幕',

	ReduceScreen: '减小屏幕',
};
