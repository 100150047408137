<template>
	<dv-full-screen-container>
		<div class="container" ref="resize">
			<div class="top">
				<div class="strip"></div>
				<div class="content">
					{{ $t('lang.IntelligentHeatTreatmentSystem') }}
				</div>
				<div class="strip">
					<div class="right-menu">
						<el-tooltip
							class="item"
							effect="dark"
							:content="$t('lang.accountManagement')"
							placement="bottom-end"
						>
							<i class="el-icon-menu" @click="toUserManage"></i>
						</el-tooltip>
						<el-tooltip
							class="item"
							effect="dark"
							:content="$t('lang.personalCenter')"
							placement="bottom-end"
						>
							<i class="el-icon-user-solid"></i>
						</el-tooltip>
						<el-tooltip
							class="item"
							effect="dark"
							:content="$t('lang.userGuide')"
							placement="bottom-end"
						>
							<i class="el-icon-question" @click="toHelper"></i>
						</el-tooltip>
						<el-tooltip
							class="item"
							effect="dark"
							:content="$t('lang.firstpage')"
							placement="bottom-end"
						>
							<i class="el-icon-s-home" @click="toHome"></i>
						</el-tooltip>
						<!-- 全屏按钮 -->
						<el-tooltip
							effect="dark"
							:content="$t('lang.fullScreenExitFullScreen')"
							placement="bottom"
						>
							<i
								@click="GetFullCreeen"
								class="el-icon-full-screen"
							></i>
						</el-tooltip>
						<!-- 国际化按钮 -->
						<el-tooltip
							effect="dark"
							:content="$t('lang.changeLanguage')"
							placement="bottom"
							style="font-size: 28px !important"
						>
							<i
								@click="ChangeLanguage()"
								class="el-icon-expandziyuan"
								style="font-size: 28px"
							></i>
						</el-tooltip>
						<el-tooltip
							class="item"
							effect="dark"
							:content="$t('lang.logout')"
							placement="bottom-end"
						>
							<i
								class="el-icon-switch-button"
								@click="toLogin"
							></i>
						</el-tooltip>
					</div>
				</div>
			</div>
			<div class="main">
				<div class="left">
					<div @click="toUserManage">
						<span>{{ $t('lang.accountManagement') }}&nbsp;</span>
						<i class="el-icon-menu"></i>
					</div>
					<div>
						<span>{{ $t('lang.personalCenter') }}</span>
						<i class="el-icon-user-solid"></i>
					</div>
					<div @click="toHeatTreatmentOptimization">
						<span>
							热处理优化系统
							<!-- <i class="el-icon-full-screen"></i> -->
						</span>
					</div>
				</div>
				<div class="middle">
					<div class="nav-bak-inner">
						<div class="nav-item">
							<div class="nav-link">
								<div class="nav-item-link">
									<div class="nav-bg-link">
										<div class="img-text-link">
											<div class="img-text">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													width="71.978"
													height="71.051"
													viewBox="0 0 71.978 71.051"
												>
													<defs>
														<linearGradient
															id="linear-gradient"
															x1="9.387"
															y1="58.84"
															x2="9.605"
															y2="60.137"
															gradientUnits="objectBoundingBox"
														>
															<stop
																offset="0"
																stop-color="#0043d4"
															/>

															<stop
																offset="0.139"
																stop-color="#0041ce"
															/>
															<stop
																offset="0.321"
																stop-color="#003cbd"
															/>
															<stop
																offset="0.527"
																stop-color="#0133a2"
															/>
															<stop
																offset="0.75"
																stop-color="#02277d"
															/>
															<stop
																offset="0.984"
																stop-color="#03194d"
															/>
															<stop
																offset="1"
																stop-color="#04184a"
															/>
														</linearGradient>
														<linearGradient
															id="linear-gradient-2"
															x1="4.451"
															y1="46.795"
															x2="4.623"
															y2="48.624"
															xlink:href="#linear-gradient"
														/>
														<linearGradient
															id="linear-gradient-3"
															x1="6.326"
															y1="58.67"
															x2="6.508"
															y2="60.324"
															xlink:href="#linear-gradient"
														/>
													</defs>
													<g
														id="组_4"
														data-name="组 4"
														transform="translate(-929.17 -235.665)"
													>
														<rect
															id="矩形_9"
															data-name="矩形 9"
															width="70.785"
															height="20.763"
															rx="10.382"
															transform="matrix(1, 0.017, -0.017, 1, 930.375, 235.665)"
															fill="#fff"
															opacity="0.85"
														/>
														<rect
															id="矩形_10"
															data-name="矩形 10"
															width="70.785"
															height="20.763"
															rx="10.382"
															transform="matrix(1, 0.017, -0.017, 1, 929.951, 260.2)"
															fill="#fff"
															opacity="0.85"
														/>
														<rect
															id="矩形_11"
															data-name="矩形 11"
															width="70.785"
															height="20.763"
															rx="10.382"
															transform="matrix(1, 0.017, -0.017, 1, 929.528, 284.735)"
															fill="#fff"
															opacity="0.85"
														/>
														<rect
															id="矩形_12"
															data-name="矩形 12"
															width="16.044"
															height="7.55"
															rx="3.775"
															transform="matrix(1, 0.017, -0.017, 1, 978.387, 243.1)"
															opacity="0.85"
															fill="url(#linear-gradient)"
														/>
														<rect
															id="矩形_13"
															data-name="矩形 13"
															width="35.864"
															height="9.438"
															rx="4.719"
															transform="translate(958.147 267.294) rotate(0.988)"
															opacity="0.85"
															fill="url(#linear-gradient-2)"
														/>
														<rect
															id="矩形_14"
															data-name="矩形 14"
															width="24.539"
															height="7.55"
															rx="3.775"
															transform="matrix(1, 0.017, -0.017, 1, 969.975, 292.984)"
															opacity="0.85"
															fill="url(#linear-gradient-3)"
														/>
													</g>
												</svg>
												<span class="nav-text-link">{{
													$t(
														'lang.EquipmentManagement'
													)
												}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="nav-item-link">
									<div class="nav-bg-link">
										<div class="img-text-link">
											<div class="img-text">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													width="96.922"
													height="101.114"
													viewBox="0 0 96.922 101.114"
												>
													<defs>
														<linearGradient
															id="linear-gradient"
															x1="0.456"
															y1="15.889"
															x2="0.749"
															y2="17.061"
															gradientUnits="objectBoundingBox"
														>
															<stop
																offset="0"
																stop-color="#0043d4"
															/>
															<stop
																offset="0.139"
																stop-color="#0041ce"
															/>
															<stop
																offset="0.321"
																stop-color="#003cbd"
															/>
															<stop
																offset="0.527"
																stop-color="#0133a2"
															/>
															<stop
																offset="0.75"
																stop-color="#02277d"
															/>
															<stop
																offset="0.984"
																stop-color="#03194d"
															/>
															<stop
																offset="1"
																stop-color="#04184a"
															/>
														</linearGradient>
													</defs>
													<g
														id="组_3"
														data-name="组 3"
														transform="translate(-865.38 1066.647) rotate(-69)"
														opacity="0.85"
													>
														<path
															id="路径_15"
															data-name="路径 15"
															d="M1316.236,466.776s-97.838-48.113-77.506,32.381C1238.73,499.157,1277.063,576.221,1316.236,466.776Z"
															fill="#fff"
														/>
														<path
															id="路径_16"
															data-name="路径 16"
															d="M1291.345,478.2l-19.9-6.007-1.73,13-14.882,1.1,20.339,11.96,2.237-14.232,14.661-4.08Z"
															fill="url(#linear-gradient)"
														/>
													</g>
												</svg>
												<span class="nav-text-link">{{
													$t(
														'lang.SafetyAndEnvironmentalProtection'
													)
												}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="nav-item-link">
									<div class="nav-bg-link">
										<div class="img-text-link">
											<div class="img-text">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													width="110.518"
													height="109.551"
													viewBox="0 0 110.518 109.551"
												>
													<defs>
														<linearGradient
															id="linear-gradient"
															x1="-7.777"
															y1="2.391"
															x2="-7.415"
															y2="3.334"
															gradientUnits="objectBoundingBox"
														>
															<stop
																offset="0"
																stop-color="#0043d4"
															/>
															<stop
																offset="0.139"
																stop-color="#0041ce"
															/>
															<stop
																offset="0.321"
																stop-color="#003cbd"
															/>
															<stop
																offset="0.527"
																stop-color="#0133a2"
															/>
															<stop
																offset="0.75"
																stop-color="#02277d"
															/>
															<stop
																offset="0.984"
																stop-color="#03194d"
															/>
															<stop
																offset="1"
																stop-color="#04184a"
															/>
														</linearGradient>
													</defs>
													<g
														id="组_6"
														data-name="组 6"
														transform="matrix(0.799, 0.602, -0.602, 0.799, -348.846, -1275.269)"
														opacity="0.85"
													>
														<path
															id="路径_30"
															data-name="路径 30"
															d="M1150.806,848.456l12.88-32.677-.654-5.464-22.554-29.089-7.366-.26-31.462,4.092-5.221,4.048-12.544,30.029s-2.573,4.383-.26,7.366l23.711,30.581,38.249-4.578Z"
															fill="#fff"
														/>
														<path
															id="路径_31"
															data-name="路径 31"
															d="M1145.223,818.151s3.151-3.637-.821-4.14-19.787,2.205-19.787,2.205l-12.723-16.409s-5.54-4.064-4.307,2.146l13.879,17.9-6.9,17.289s-.67,5.3,3.059,2.406l7.475-16.544s1.081-3.227,1.66-2.481S1145.223,818.151,1145.223,818.151Z"
															fill="url(#linear-gradient)"
														/>
													</g>
												</svg>
												<span class="nav-text-link">{{
													$t('lang.RateOfProgress')
												}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="nav-item-link">
									<div class="nav-bg-link">
										<div class="img-text-link">
											<div class="img-text">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="99.802"
													height="67.999"
													viewBox="0 0 99.802 67.999"
												>
													<g
														id="组_2"
														data-name="组 2"
														transform="matrix(0.788, -0.616, 0.616, 0.788, -1083.057, -120.711)"
														opacity="0.85"
													>
														<rect
															id="矩形_6"
															data-name="矩形 6"
															width="37.752"
															height="16.044"
															rx="8.022"
															transform="matrix(-0.789, -0.615, 0.615, -0.789, 791.525, 857.825)"
															fill="#fff"
														/>
														<rect
															id="矩形_7"
															data-name="矩形 7"
															width="37.752"
															height="16.044"
															rx="8.022"
															transform="matrix(-0.789, -0.615, 0.615, -0.789, 807.769, 836.981)"
															fill="#fff"
														/>
														<rect
															id="矩形_8"
															data-name="矩形 8"
															width="37.752"
															height="16.044"
															rx="8.022"
															transform="matrix(-0.789, -0.615, 0.615, -0.789, 823.433, 816.882)"
															fill="#fff"
														/>
														<path
															id="路径_13"
															data-name="路径 13"
															d="M815.717,835.995l4.061-5.211s7.192,9.2,12,4.565.01-10.761-4.456-14.242l4.061-5.211s18.118,10.53,7.51,22.6S815.717,835.995,815.717,835.995Z"
															fill="#fff"
														/>
														<path
															id="路径_14"
															data-name="路径 14"
															d="M779.172,802.785l-3.981,5.272s-7.332-9.085-12.066-4.382.152,10.76,4.671,14.173l-3.981,5.272s-18.276-10.254-7.853-22.489S779.172,802.785,779.172,802.785Z"
															fill="#fff"
														/>
													</g>
												</svg>
												<span class="nav-text-link">{{
													$t(
														'lang.EnergyConsumptionAnalysis'
													)
												}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="nav-item-link">
									<div class="nav-bg-link">
										<div class="img-text-link">
											<div class="img-text">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													width="110.518"
													height="109.551"
													viewBox="0 0 110.518 109.551"
												>
													<defs>
														<linearGradient
															id="linear-gradient"
															x1="-7.777"
															y1="2.391"
															x2="-7.415"
															y2="3.334"
															gradientUnits="objectBoundingBox"
														>
															<stop
																offset="0"
																stop-color="#0043d4"
															/>
															<stop
																offset="0.139"
																stop-color="#0041ce"
															/>
															<stop
																offset="0.321"
																stop-color="#003cbd"
															/>
															<stop
																offset="0.527"
																stop-color="#0133a2"
															/>
															<stop
																offset="0.75"
																stop-color="#02277d"
															/>
															<stop
																offset="0.984"
																stop-color="#03194d"
															/>
															<stop
																offset="1"
																stop-color="#04184a"
															/>
														</linearGradient>
													</defs>
													<g
														id="组_6"
														data-name="组 6"
														transform="matrix(0.799, 0.602, -0.602, 0.799, -348.846, -1275.269)"
														opacity="0.85"
													>
														<path
															id="路径_30"
															data-name="路径 30"
															d="M1150.806,848.456l12.88-32.677-.654-5.464-22.554-29.089-7.366-.26-31.462,4.092-5.221,4.048-12.544,30.029s-2.573,4.383-.26,7.366l23.711,30.581,38.249-4.578Z"
															fill="#fff"
														/>
														<path
															id="路径_31"
															data-name="路径 31"
															d="M1145.223,818.151s3.151-3.637-.821-4.14-19.787,2.205-19.787,2.205l-12.723-16.409s-5.54-4.064-4.307,2.146l13.879,17.9-6.9,17.289s-.67,5.3,3.059,2.406l7.475-16.544s1.081-3.227,1.66-2.481S1145.223,818.151,1145.223,818.151Z"
															fill="url(#linear-gradient)"
														/>
													</g>
												</svg>
												<span class="nav-text-link">{{
													$t('lang.ProcessManagement')
												}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="nav-center" @click="toHomeMain">
								<span class="center-text-link">{{
									$t('lang.HeatTreatmenCalculation')
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div @click="ChangeLanguage()">
						<i
							class="el-icon-expandziyuan"
							style="font-size: 28px"
						></i
						><span>&nbsp;{{ $t('lang.changeLanguage') }}</span>
					</div>
					<div @click="toHelper">
						<i class="el-icon-question"></i>
						<span>&nbsp;{{ $t('lang.userGuide') }}</span>
					</div>
					<div @click="toChatIMSHT">
						<i class="el-icon-s-opportunity"></i>
						<span>&nbsp;chat-imsht</span>
					</div>
				</div>
			</div>
		</div>
	</dv-full-screen-container>
</template>

<script>
import FullScreen from '../components/Yi-FullScreen.vue';
import Internationalization from '../components/Yi-Internationalize.vue';
export default {
	name: 'Master',
	methods: {
		ChangeLanguage() {
			if (this.$i18n.locale == 'zh') {
				this.$i18n.locale = 'en';
			} else if (this.$i18n.locale == 'en') {
				this.$i18n.locale = 'jp';
			} else {
				this.$i18n.locale = 'zh';
			}
		},
		//处理
		GetFullCreeen() {
			this.isFullScreen = !this.isFullScreen;
			this.isFullScreen
				? this.InFullCreeen(document.documentElement)
				: this.OutFullCreeen(document);
		},
		InFullCreeen(element) {
			let el = element;
			let rfs =
				el.requestFullScreen ||
				el.webkitRequestFullScreen ||
				el.mozRequestFullScreen ||
				el.msRequestFullScreen;

			if (typeof rfs != 'undefined' && rfs) {
				rfs.call(el);
			} else if (typeof window.ActiveXObject != 'undefined') {
				let wscript = new ActiveXObject('WScript.Shell');
				if (wscript != null) {
					wscript.SendKeys('{F11}');
				}
			}
		},
		OutFullCreeen(element) {
			let el = element;
			let cfs =
				el.cancelFullScreen ||
				el.webkitCancelFullScreen ||
				el.mozCancelFullScreen ||
				el.exitFullScreen ||
				el.msExitFullscreen;
			console.log(cfs);
			if (typeof cfs != 'undefined' && cfs) {
				cfs.call(el);
			} else if (typeof window.ActiveXObject != 'undefined') {
				let wscript = new ActiveXObject('WScript.Shell');
				if (wscript != null) {
					wscript.SendKeys('{F11}');
				}
			}
		},
		fullscreenElement() {
			var fullscreenEle =
				document.fullscreenElement ||
				document.mozFullScreenElement ||
				document.webkitFullscreenElement;
			return fullscreenEle;
		},

		toHomeMain() {
			this.$router.push({ path: '/newhome/Calculate' });
		},
		//路由跳转
		toLogin() {
			localStorage.removeItem('token');
			this.$router.push({ path: '/' });
		},
		toChatIMSHT() {
			window.open('http://imsht.ddns.net:1002');
		},
		toHome() {
			this.$router.push({ path: '/master' });
		},
		//路由跳转
		toCosmap() {
			this.$router.push({ path: '/home/Calculate' });
		},
		toUserManage() {
			this.$router.push({ path: '/newhome/Usermanage' });
		},
		accessInit() {
			let accessStr = localStorage.getItem('access');
			this.accessList = accessStr.split(',');
			this.userType = localStorage.getItem('type');
		},
		toHelper() {
			this.$router.push({ path: '/Helper' });
		},
		toHeatTreatmentOptimization() {
			this.$router.push({ path: '/home/HeatTreatmentOptimization' });
		},
	},
	mounted: function () {
		this.accessInit();
		window.onresize = () => {
			if (!this.fullscreenElement()) {
				this.isFullScreen = false;
			}
			// else {
			//     this.isFullScreen = true;
			// }
		};
	},
	components: {
		FullScreen,
		Internationalization,
	},
};
</script>

<style>
@media (min-width: 0px) and (max-width: 1536px) {
	html {
		font-size: 45.5%;
	}
}
@media (min-width: 1537px) and (max-width: 3000px) {
	html {
		font-size: 60%;
	}
}
</style>

<style scoped>
.container {
	width: 100%;
	height: 100%;
	background-color: #0e0d4f;
	font-size: 62.5%;
}
.top {
	width: 100%;
	height: 8.4rem;
	top: 4.63rem;
	position: relative;
	text-align: center;
	display: flex;
	justify-content: space-between;
}
.top .content {
	height: 8.4rem;
	width: auto;
	line-height: 8.4rem;
	font-size: 58.4px;
	color: white;
	white-space: nowrap;
}
.top .strip {
	top: 3.37rem;
	width: 69.3rem;
	height: 0.4rem;
	position: relative;
	background: linear-gradient(80deg, #1572ff, #1262df, #041b50);
}
.top .strip:nth-child(1) {
	background: linear-gradient(270deg, #1572ff, #1262df, #041b50);
}

.top .strip:nth-child(3) .right-menu {
	margin-top: -5rem;
}

.right-menu {
	position: fixed;
	font-size: 30px;
	font-weight: 700;
	left: 87%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 230px;
}

.main {
	width: 100%;
	height: 99.1rem;
	display: flex;
	position: relative;
	justify-content: space-between;
}
.main .left {
	width: 36rem;
	height: 57.8rem;
	top: 17rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.main .left > div {
	text-align: center;
	line-height: 10rem;
	background: linear-gradient(270deg, #1572ff, #1262df, #041b50);
	font-size: 28px;
	height: 10rem;
	color: white;
}
.main .left > div:hover {
	transform: scale(1.1);
	cursor: pointer;
}
.main .left > div:nth-child(2) {
	width: 30rem;
}
.main .middle {
	width: 120rem;
	position: relative;
}
.main .middle .nav-bak-inner {
	width: 28rem;
	height: 28rem;
	margin: 39rem auto 0;
	position: relative;
	z-index: 10;
}
.main .middle .nav-center {
	width: 28rem;
	height: 28rem;
	position: absolute;
	border-radius: 50%;
	background: url(../assets/img/home/center.png) no-repeat center;
	background-size: cover;
}
.main .middle .nav-center .center-text-link {
	color: #001e41;
	display: inline-block;
	transform: rotateZ(0deg);
	padding-left: 6rem;
	padding-top: 18rem;
	font-size: 28px;
	color: white;
}
.main .middle .nav-center:hover {
	transform: scale(1.05);
	cursor: pointer;
}
.main .middle .nav-item {
	position: absolute;
}
.main .middle .nav-link {
	position: absolute;
}
.main .middle .nav-item-link {
	width: 42.7rem;
	height: 40rem;
	top: -26rem;
	left: -7.35rem;
	position: absolute;
	transform-origin: 21.35rem 40rem;
	transform: rotateZ(0deg);
}
.main .middle .nav-item-link:nth-child(2) {
	transform: rotateZ(72deg);
}
.main .middle .nav-item-link:nth-child(2) .nav-bg-link {
	background-image: url(../assets/img/home/shanxing.png);
	background-size: cover;
}
.main .middle .nav-item-link:nth-child(2) .nav-bg-link .img-text-link {
	transform: rotate(-72deg);
}
.main .middle .nav-item-link:nth-child(3) {
	transform: rotateZ(144deg);
}
.main .middle .nav-item-link:nth-child(3) .nav-bg-link {
	background-image: url(../assets/img/home/shanxing.png);
	background-size: cover;
	background-repeat: no-repeat;
}
.main .middle .nav-item-link:nth-child(3) .nav-bg-link .img-text-link {
	margin-top: 8rem;
	transform: rotateZ(-144deg);
}
.main .middle .nav-item-link:nth-child(4) {
	transform: rotateZ(216deg);
}
.main .middle .nav-item-link:nth-child(4) .nav-bg-link {
	background-image: url(../assets/img/home/shanxing.png);
	background-size: cover;
	background-repeat: no-repeat;
}
.main .middle .nav-item-link:nth-child(4) .nav-bg-link .img-text-link {
	margin-top: 6rem;
	transform: rotateZ(-216deg);
}
.main
	.middle
	.nav-item-link:nth-child(4)
	.nav-bg-link
	.img-text-link
	.img-text {
	margin: 1rem 0;
}
.main .middle .nav-item-link:nth-child(5) {
	transform: rotateZ(288deg);
}
.main .middle .nav-item-link:nth-child(5) .nav-bg-link {
	background-image: url(../assets/img/home/shanxing.png);
	background-size: cover;
}
.main .middle .nav-item-link:nth-child(5) .nav-bg-link .img-text-link {
	transform: rotateZ(-288deg);
}
.main
	.middle
	.nav-item-link:nth-child(5)
	.nav-bg-link
	.img-text-link
	.img-text {
	margin: 0 0;
}
.main .middle .nav-bg-link {
	background-image: url(../assets/img/home/shanxing.png);
	background-size: cover;
	width: 100%;
	height: 67%;
	background-repeat: no-repeat;
	transition: all 1s linear 0;
	position: relative;
}
.main .middle .nav-bg-link .img-text-link {
	width: 13.2rem;
	height: 11.5rem;
	position: absolute;
	margin-left: 14.75rem;
	margin-top: 4rem;
	transform-origin: 50% 50%;
}
.main .middle .nav-bg-link .img-text-link .img-text {
	width: 15rem;
	text-align: center;
	margin: 0 auto;
}

.main .middle .nav-bg-link .nav-text-link {
	display: block;
}
.main .middle .nav-bg-link .img-text-link .nav-text-link {
	width: 100%;
	height: 4.4rem;
	font-size: 2.5rem;
	line-height: 3.3rem;
	color: white;
	transform: rotateZ(0deg);
}
.main .middle .nav-bg-link:hover {
	transform: scale(1.05);
	cursor: pointer;
}
.main .right {
	width: 36rem;
	height: 57.8rem;
	top: 17rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}
.main .right div {
	width: 36rem;
	height: 10rem;
	text-align: center;
	line-height: 10rem;
	background: linear-gradient(90deg, #1572ff, #1262df, #041b50);
	font-size: 28px;
	color: white;
}
.main .right div:nth-child(2) {
	width: 30rem;
}
.main .right div:hover {
	transform: scale(1, 1.1);
	cursor: pointer;
}
</style>
