<template>
	<div>
		<div>
			<el-col :span="24" class="toolbar" style="padding-bottom: 20px">
				<el-input style="width: 200px" placeholder="搜索名称" v-model="queryStr"></el-input>&nbsp;&nbsp;
				<el-button @click="refreshTable()">查询</el-button>
				<el-button type="danger" @click="refreshTable()">刷新列表</el-button>
				<el-button type="primary" @click="changeShapeDataDialogTableVisible()">Shape Data</el-button>
				<el-button type="primary" @click="changeAnalisysConditionDialogTableVisible()">Analisys
					Condition</el-button>
				<el-button type="primary" @click="changeMatirialDataDialogTableVisible()">Matirial Data</el-button>
				<el-button type="success" @click="addDialogFormVisible = true">
					{{ $t('lang.startCalculation') }}</el-button>
			</el-col>
			<el-table :data="tableData" style="min-height: 55vh; bacground-color: #ffffff60">
				<el-table-column prop="Name" label="实验名称" width="500">
				</el-table-column>
				<el-table-column label="实验状态">
					<template slot-scope="scope">
						<div v-if="scope.row.State == 0">正在分配计算资源</div>
						<div v-if="scope.row.State == 1">正在计算</div>
						<div v-if="scope.row.State == 2">结束</div>
						<div v-if="scope.row.State == 9">计算异常</div>
					</template>
				</el-table-column>
				<el-table-column prop="CreateTime" label="创建时间">
				</el-table-column>
				<el-table-column prop="FinishTime" label="完成时间">
				</el-table-column>

				<el-table-column fixed="right" label="操作" width="170">
					<template slot-scope="scope">
						<el-button v-if="scope.row.State == 2 && !scope.row.downloading" @click="handleDownload(scope.row)"
							type="success" size="small"><span v-if="scope.row.compress">资源压缩中</span><span
								v-else>提取结果</span></el-button>
						<el-progress v-if="scope.row.State == 2 && scope.row.downloading"
							:percentage="scope.row.downloadProgress" size="small" />
						<el-button v-if="scope.row.State != 2" type="success" size="small">正在计算</el-button>
						<el-button v-if="scope.row.State != 0" @click="handleProcess(scope.row)" type="text"
							size="small">查看进度</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="totalNumber"></el-pagination>
			</div>
		</div>
		<el-dialog title="查看进度" :visible.sync="processDialogFormVisible" :append-to-body="true">
			<pre>
             {{ processLog }}
        </pre>
		</el-dialog>

		<!-- SHAPE-DATA列表弹窗 -->
		<el-dialog title="SHAPE-DATA列表" :visible.sync="shapeDataDialogTableVisible" :append-to-body="true" width="1200px">
			<el-table @selection-change="handleSelectionChange" :data="shapeDatatableData" ref="table"
				class="single-select-table" style="min-height: 55vh; bacground-color: #ffffff60">
				<el-table-column type="selection" width="55"> </el-table-column>
				<!-- <el-table-column
                            prop="Id"
                            label="Id"
                            width="150"
                            :show-overflow-tooltip="true"
                        >
                        </el-table-column> -->
				<el-table-column prop="Name" label="材料名称" width="220">
				</el-table-column>
				<el-table-column prop="Name" label="材料简介" width="400" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="Type" label="材料类别" width="100" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="CreateTime" label="发布时间">
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @size-change="handleShapeDataSizeChange" @current-change="handleShapeDataCurrentChange"
					:current-page="shapeDataCurrentPage" :page-sizes="shapeDataPageSizes" :page-size="shapeDataPageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="shapeDataTotalNumber"></el-pagination>
			</div>
		</el-dialog>
		<!-- SHAPE-DATA列表弹窗 -->

		<!-- Analisys-Condition列表弹窗 -->
		<el-dialog title="Analisys-Condition列表" :visible.sync="analisysConditionDialogTableVisible" :append-to-body="true"
			width="1200px">
			<el-table @selection-change="analisysConditionHandleSelectionChange" :data="analisysConditionTableData"
				ref="analisystable" class="single-select-table" style="min-height: 55vh; bacground-color: #ffffff60">
				<el-table-column type="selection" width="55"> </el-table-column>

				<el-table-column prop="Name" label="材料名称" width="220">
				</el-table-column>
				<el-table-column prop="Name" label="材料简介" width="400" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="Type" label="材料类别" width="100" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="CreateTime" label="发布时间">
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @size-change="handleAnalisysConditionSizeChange"
					@current-change="handleAnalisysConditionCurrentChange" :current-page="analisysConditionCurrentPage"
					:page-sizes="analisysConditionPageSizes" :page-size="analisysConditionPageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="analisysConditionTableData"></el-pagination>
			</div>
		</el-dialog>
		<!-- Analisys-Condition列表弹窗 -->

		<!-- Matirial-Data列表弹窗 -->
		<el-dialog title="Matirial-Data列表" :visible.sync="matirialDataDialogTableVisible" :append-to-body="true"
			width="1200px">
			<el-table @selection-change="matirialDataHandleSelectionChange" :data="matirialDataConditionTableData"
				ref="matirialtable" class="single-select-table" style="min-height: 55vh; bacground-color: #ffffff60">
				<el-table-column type="selection" width="55"> </el-table-column>

				<el-table-column prop="Name" label="材料名称" width="220">
				</el-table-column>
				<el-table-column prop="Name" label="材料简介" width="400" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="Type" label="材料类别" width="100" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="CreateTime" label="发布时间">
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @size-change="handleMatirialDataSizeChange" @current-change="handleMatirialDataCurrentChange"
					:current-page="matirialDataCurrentPage" :page-sizes="matirialDataPageSizes"
					:page-size="matirialDataPageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="100"></el-pagination>
			</div>
		</el-dialog>
		<!-- Matirial-Data列表弹窗 -->

		<!-- 开始计算弹窗 -->
		<el-dialog title="开始计算" :visible.sync="addDialogFormVisible" :append-to-body="true" width="1200px">
			<el-form :model="form" label-position="left">
				<el-form-item label="实验名称" :label-width="formLabelWidth">
					<el-input v-model="form.name" autocomplete="off" placeholder="实验名称"></el-input>
				</el-form-item>
				<el-form-item label="Shape Data" :label-width="formLabelWidth">
					<el-upload v-if="customerCheckShapeData == null" class="upload-demo" drag
						:action="baseUrl + 'ShapeData/FileUpLoad'" :multiple="false" :limit="1" ref="uploadNas"
						:on-success="nasUploadSuccess" :on-error="nasUploadError" accept=".">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">
							请将(.nas)文件拖至此处，或
							<em>点击上传</em>
						</div>
					</el-upload>
					<el-col :span="22">
						<el-input v-if="customerCheckShapeData != null" v-model="customerCheckShapeDataName"
							:disabled="true"></el-input>
					</el-col>
					<el-button v-if="customerCheckShapeData != null" icon="el-icon-delete"
						@click="DeletecustomerCheckShapeData()"></el-button>
				</el-form-item>
				<el-form-item label="Analisys Condition" :label-width="formLabelWidth">
					<el-upload v-if="customerCheckAnalisys == null" class="upload-demo" drag
						:action="baseUrl + 'ProcessProperties/FileUpLoad'" :multiple="false" :limit="1" ref="uploadPos"
						:on-success="posUploadSuccess" :on-error="posUploadError">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">
							请将(.pos)文件拖至此处，或
							<em>点击上传</em>
						</div>
					</el-upload>
					<el-col :span="22">
						<el-input v-if="customerCheckAnalisys != null" v-model="customerCheckAnalisysName"
							:disabled="true"></el-input>
					</el-col>
					<el-button v-if="customerCheckAnalisys != null" icon="el-icon-delete"
						@click="DeletecustomerCheckAnalisys()"></el-button>
				</el-form-item>
				<el-form-item label="Matirial Data" :label-width="formLabelWidth">
					<el-upload v-if="customerCheckMaterial == null" class="upload-demo" drag
						:action="baseUrl + 'Material/FileUpLoad'" :multiple="false" :limit="1" ref="uploadDat"
						:on-success="datUploadSuccess" :on-error="datUploadError">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">
							请将（.dat）文件拖至此处，或
							<em>点击上传</em>
						</div>
					</el-upload>
					<el-col :span="22">
						<el-input v-if="customerCheckMaterial != null" v-model="customerCheckMaterialName"
							:disabled="true"></el-input>
					</el-col>
					<el-button v-if="customerCheckMaterial != null" icon="el-icon-delete"
						@click="DeletecustomerCheckMaterial()"></el-button>
				</el-form-item>

				<el-form-item label="文件配置" :label-width="formLabelWidth">
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
						@change="handleCheckAllChange">全选</el-checkbox>
					<div style="margin: 15px 0"></div>
					<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
						<el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addDialogFormVisible = false">取 消</el-button>
				<!-- <el-button @click="outputcon()">显示</el-button> -->
				<el-button type="primary" @click="CreateCalculate">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 开始计算弹窗 -->
	</div>
</template>

<script>
import {
	Url,
	AddCalculate,
	SelectCalculate,
	GetCaculateLog,
	GetCaculateResult,
	SelectMaterial,
	SelectShapeData,
	SelectProcessProperties,
} from '../api/api';

const cityOptions = [
	'Carbon density',
	'Nitrogen density',
	'No.3 Component dens.',
	'Temperature(integ.pont)',
	'Temperature(node)',
	'Heat flow vector_1',
	'Heat flow vector_2',
	'Heat flow vector_3',
	'Stress σxx',
	'Stress σyy',
	'Stress σzz',
	'Stress σxy',
	'Stress σyz',
	'Stress σzx',
	'Strain εxx',
	'Strain εyy',
	'Strain εzz',
	'Strain εxy',
	'Strain εyz',
	'Strain εzx',
	'Equeva. stress σ',
	'Equeva. strain ε',
	'Plastic strain',
	'Dreep strain',
	'Volume fraction(Austenite)',
	'Volume fraction(Parlite/berlite)',
	'Volume fraction(Martensite)',
	'Volume fraction(Felite)',
	'Volume fraction(εcarbonite)',
	'Volume fraction(Sementite)',
	'Greesize',
	'Hardness',
	'Volume fraction(α)',
	'Volume fraction(γ)',
	"Volume fraction(γ')",
	'Volume fraction(ε)',
	'Nitrogen Hardness',
	'X_displacement',
	'Y_displacement',
	'z_displacement',
	'TOTAL_displacement',
];

export default {
	name: 'Calculate',
	data() {
		return {
			downloads: {},
			baseUrl: Url,
			tableData: [], //表数据
			shapeDatatableData: [],
			analisysConditionTableData: [],
			matirialDataConditionTableData: [],

			queryStr: '',

			currentPage: 1, //当前是第几页
			shapeDataCurrentPage: 1,
			analisysConditionCurrentPage: 1,
			matirialDataCurrentPage: 1,

			pageSize: 7,
			shapeDataPageSize: 7,
			analisysConditionPageSize: 7,
			matirialDataPageSize: 7,

			pageSizes: [7, 10, 20, 30],
			shapeDataPageSizes: [7, 10, 20, 30],
			analisysConditionPageSizes: [7, 10, 20, 30],
			matirialDataPageSizes: [7, 10, 20, 30],

			totalNumber: 0, //
			shapeDataTotalNumber: 0, //共计多少条数据
			analisysConditionTotalNumber: 0,
			matirialDataTotalNumber: 0,

			orderBy: '',

			addDialogFormVisible: false,
			shapeDataDialogTableVisible: false,
			analisysConditionDialogTableVisible: false,
			matirialDataDialogTableVisible: false,

			processDialogFormVisible: false,
			processLog: '',
			config: {
				header: [
					'实验名称',
					'状态',
					'开始时间',
					'完成时间',
					'查看结果',
				],

				index: true,
				columnWidth: [50],
				align: ['center'],
				carousel: 'page',
			},
			formLabelWidth: '120px',
			checkList: [],

			checkAll: false,
			checkedCities: [],
			cities: cityOptions,
			isIndeterminate: true,

			//待计算的数据
			form: {
				name: '',
				nasPath: '',
				posPath: '',
				datPath: '',
				configuration: [],

			},
			formLabelWidth: '140px',

			//用户从库中选出的待计算的模型
			customerCheckShapeData: null,
			customerCheckShapeDataName: null,

			//用户从库中选出的待计算的工艺参数
			customerCheckAnalisys: null,
			customerCheckAnalisysName: null,

			//用户从库中选出的待计算的材料
			customerCheckMaterial: null,
			customerCheckMaterialName: null,

			downloadpage: 1,
		};
	},
	methods: {
		handleCheckAllChange(val) {
			this.checkedCities = val ? cityOptions : [];
			this.isIndeterminate = false;
		},
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.cities.length;
			this.isIndeterminate =
				checkedCount > 0 && checkedCount < this.cities.length;
		},
		outputcon() {
			console.log(this.checkedCities);
		},
		DeletecustomerCheckShapeData() {
			this.customerCheckShapeDataName = null,
				this.customerCheckShapeData = null
		},
		DeletecustomerCheckMaterial() {
			this.customerCheckMaterialName = null,
				this.customerCheckMaterial = null
		},
		DeletecustomerCheckAnalisys() {
			this.customerCheckAnalisysName = null,
				this.customerCheckAnalisys = null
		},

		//路由跳转
		getTableData() {
			var pageData = {
				name: this.queryStr,
				desc: false,
				currentPage: this.currentPage - 1,
				pageNumber: this.pageSize,
			};
			SelectCalculate(pageData).then((res) => {
				this.tableData = res.data;
				this.totalNumber = res.count;
			});
		},
		//刷新表格
		refreshTable() {
			this.getTableData();
		},
		drawPie(id) {
			this.charts = echarts.init(document.getElementById(id));
			this.charts.setOption();
		},
		//点击确认开始计算按钮
		CreateCalculate() {
			console.log(this.checkedCities);
			this.cities.forEach((element) => {
				if (this.checkedCities.indexOf(element) != -1)
					this.form.configuration.push(true);
				else this.form.configuration.push(false);
			});
			console.log(this.form.configuration);
			AddCalculate(this.form).then((res) => {
				console.log(res);
				this.addDialogFormVisible = false;
				if (res == true) {
					this.$message({
						message: '创建实验成功',
						type: 'success',
					});
					this.getTableData();

					this.$refs.uploadNas.clearFiles();
					this.$refs.uploadPos.clearFiles();
					this.$refs.uploadDat.clearFiles();
				} else {
					this.$message.error('创建实验失败');
				}
				// this.getTableData();
			});
			this.form.configuration = [];
		},
		//.Nas文件导入成功后的处理
		nasUploadSuccess(response, file, fileList) {
			this.$message({
				message: '.Nas文件上传成功！',
				type: 'success',
			});
			this.form.nasPath = response.Data;
		},
		//.Nas文件导入失败后的处理
		nasUploadError(error, file, fileList) {
			this.$message.error(error.message);
			this.$refs.uploadNas.clearFiles();
		},
		//.Pos文件导入成功后的处理
		posUploadSuccess(response, file, fileList) {
			this.$message({
				message: '.Pos文件上传成功！',
				type: 'success',
			});
			this.form.posPath = response.Data;
		},
		//.Pos导入失败后的处理
		posUploadError(error, file, fileList) {
			this.$message.error(error.message);
			this.$refs.uploadPos.clearFiles();
		},
		//.Dat文件导入成功后的处理
		datUploadSuccess(response, file, fileList) {
			this.$message({
				message: '.Dat文件上传成功！',
				type: 'success',
			});
			this.form.datPath = response.Data;
		},
		//.Dat导入失败后的处理
		datUploadError(error, file, fileList) {
			this.$message.error(error.message);
			this.$refs.uploadDat.clearFiles();
		},
		//提出计算结果
		handleDownload(row) {
			this.$set(row, 'downloading', true);
			this.$set(row, 'downloadProgress', 0);
			let token = localStorage.getItem('token');
			let xhr = new XMLHttpRequest();
			xhr.open('GET', Url + 'Calculate/GetResult?id=' + row.Id, true);
			xhr.responseType = 'blob';
			xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			this.downloads[row.Id] = true; 

			xhr.onprogress = (event) => {
				if (event.lengthComputable) {
					let percentCompleted = Math.round((event.loaded / event.total) * 100);
					this.$set(row, 'downloadProgress', percentCompleted);
					if (percentCompleted == 100) {
						this.$set(row, 'compress', true);
						setTimeout(() => {
							this.$set(row, 'compress', false);
						}, 3000);
						this.downloads[row.Id] = false;
					}
				}
			}

			xhr.onload = () => {
				if (xhr.status === 200) {
					let blob = new Blob([xhr.response], { type: 'application' });
					let a = document.createElement('a');
					let url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = `${row.Name ? row.Name : 'result'}.zip`;
					let body = document.getElementsByTagName('body')[0];
					body.appendChild(a);
					a.click();
					body.removeChild(a);
					window.URL.revokeObjectURL(url);
					this.$set(row, 'downloading', false);
					 
				} else {
					console.error(`Error ${xhr.status}: ${xhr.statusText}`);
					this.$message.error('提取结果失败');
					this.$set(row, 'downloading', false);
					this.downloads[row.Id] = false; 
				}
			}

			xhr.onerror = () => {
				console.error("Request failed");
				this.$message.error('提取结果失败');
				this.$set(row, 'downloading', false);
				this.downloads[row.Id] = false; 
			}

			xhr.send();
		},
		//提出计算结果
		handleProcess(row) {
			GetCaculateLog(row.Id).then((res) => {
				this.processLog = res;
				this.addDialogFormVisible = false;
				this.getTableData();
				this.processDialogFormVisible = true;
			});
		},
		//切换表格每页显示条数
		handleSizeChange(val) {
			this.pageSize = val;
			this.getTableData();
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(this.downloads);
			if (Object.values(this.downloads).includes(true)) {
				this.$message({
					message: '文件下载过程中无法进行该操作',
				});
				this.currentPage = this.downloadpage;
				return;
			}
			this.downloadpage = val;
			this.currentPage = val;
			this.getTableData();
			console.log(`当前页: ${val}`);
		},
		/*ShapeData列表*/
		changeShapeDataDialogTableVisible() {
			this.shapeDataDialogTableVisible = true;
			this.getShapeDataTableData();
		},
		getShapeDataTableData() {
			var pageData = {
				name: this.queryStr,
				desc: false,
				currentPage: this.shapeDataCurrentPage - 1,
				pageNumber: this.shapeDataPageSize,
			};
			SelectShapeData(pageData).then((res) => {
				this.shapeDatatableData = res.data;
				this.shapeDataTotalNumber = res.count;
			});
			console.log(pageData);
		},
		handleSelectionChange(val) {
			// 单选
			if (val.length > 1) {
				this.$refs.table.clearSelection();
				this.$refs.table.toggleRowSelection(val.pop());
			}
			//如果val不为空时 就绑定用户选中的工艺参数，否则把选中的val也清空
			if (val.length != 0) {
				this.customerCheckShapeData = val;
				this.customerCheckShapeDataName = this.customerCheckShapeData[0].Name;
				this.form.nasPath = this.customerCheckShapeData[0].FilePath;
			} else {
				this.customerCheckShapeData = null;
				this.form.nasPath = null;
			}
		},
		//切换表格每页显示条数
		handleShapeDataSizeChange(val) {
			this.shapeDataPageSize = val;
			this.getShapeDataTableData();
			console.log(`每页 ${val} 条`);
		},
		//切换表格当前页
		handleShapeDataCurrentChange(val) {
			this.shapeDataCurrentPage = val;
			this.getShapeDataTableData();
			console.log(`当前页: ${val}`);
		},
		/*ShapeData列表*/

		/*Analisys-Condition列表*/
		changeAnalisysConditionDialogTableVisible() {
			this.analisysConditionDialogTableVisible = true;
			this.getAnalisysConditionTableData();
		},
		getAnalisysConditionTableData() {
			var pageData = {
				name: this.queryStr,
				desc: false,
				currentPage: this.analisysConditionCurrentPage - 1,
				pageNumber: this.analisysConditionPageSize,
			};
			SelectProcessProperties(pageData).then((res) => {
				this.analisysConditionTableData = res.data;
				this.analisysConditionTotalNumber = res.count;
			});
			console.log(pageData);
		},
		analisysConditionHandleSelectionChange(val) {
			// 单选
			if (val.length > 1) {
				this.$refs.analisystable.clearSelection();
				this.$refs.analisystable.toggleRowSelection(val.pop());
			}
			//如果val不为空时 就绑定用户选中的工艺参数，否则把选中的val也清空
			if (val.length != 0) {
				this.customerCheckAnalisys = val;
				this.customerCheckAnalisysName = this.customerCheckAnalisys[0].Name;
				this.form.posPath = this.customerCheckAnalisys[0].FilePath;
			} else {
				this.customerCheckAnalisys = null;
				this.form.posPath = null;
			}
		},
		//切换表格每页显示条数
		handleAnalisysConditionSizeChange(val) {
			this.analisysConditionPageSize = val;
			this.getAnalisysConditionTableData();
			console.log(`每页 ${val} 条`);
		},
		//切换表格当前页
		handleAnalisysConditionCurrentChange(val) {
			this.analisysConditionCurrentPage = val;
			this.getAnalisysConditionTableData();
			console.log(`当前页: ${val}`);
		},
		/*Analisys-Condition列表*/

		/*Matirial-Data列表*/
		changeMatirialDataDialogTableVisible() {
			this.matirialDataDialogTableVisible = true;
			this.getMatirialDataTableData();
		},
		getMatirialDataTableData() {
			var pageData = {
				name: this.queryStr,
				desc: false,
				currentPage: this.matirialDataCurrentPage - 1,
				pageNumber: this.matirialDataPageSize,
			};
			SelectMaterial(pageData).then((res) => {
				this.matirialDataConditionTableData = res.data;
				this.matirialDataTotalNumber = res.count;
			});
		},
		//用户选中要使用的dat文件
		matirialDataHandleSelectionChange(val) {
			// 使得table只能单选
			if (val.length > 1) {
				this.$refs.matirialtable.clearSelection();
				this.$refs.matirialtable.toggleRowSelection(val.pop());
			}
			//如果val不为空时 就绑定用户选中的材料，否则把选中的val也清空
			if (val.length != 0) {
				this.customerCheckMaterial = val;
				this.customerCheckMaterialName = this.customerCheckMaterial[0].Name;
				this.form.datPath = this.customerCheckMaterial[0].FilePath;
			} else {
				this.customerCheckMaterial = null;
				this.form.datPath = null;
			}
		},
		//切换表格每页显示条数
		handleMatirialDataSizeChange(val) {
			this.matirialDataPageSize = val;
			this.getMatirialDataTableData();
			console.log(`每页 ${val} 条`);
		},
		//切换表格当前页
		handleMatirialDataCurrentChange(val) {
			this.matirialDataCurrentPage = val;
			this.getMatirialDataTableData();
			console.log(`当前页: ${val}`);
		},
		/*Matirial-Data列表*/
	},

	//调用
	mounted() {
		this.getTableData();
	},
};
</script>

<style>
.mainBox3>.border-box-content {
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr 3fr;
	font-size: 25px;
	font-weight: 700;
	grid-gap: 20px;
	padding: 76px 40px;
}

/* .dv-border-box-10 .border-box-content {
} */
</style>

<style scoped>
.mainContainer {
	font-size: 20px;
	background-image: url('../assets/mainBackground.png');
	background-size: 100% 100%;
	-webkit-box-shadow: 0 0 3px #00f;
	box-shadow: 0 0 3px #00f;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.mainMenu {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 341px;
	flex-direction: column;
	height: 205px;
	background-color: #edebeb69;
	border-radius: 25px;
}

.mainMenu:hover {
	transform: translate(0, -10px);
	-webkit-transform: translate(0, -10px);
	-moz-transform: translate(0, -10px);
	-o-transform: translate(0, -10px);
	-ms-transform: translate(0, -10px);
	background-color: #edebeb90;
}

.outFlex {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 95%;
}

.menu-item {
	margin: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #edebeb69;
	border-radius: 25px;
	width: 80%;
	height: 50px;
	font-size: 20px;
	transition: all 0.2s linear;
}

.menu-item:hover {
	transform: translate(0, -10px);
	-webkit-transform: translate(0, -10px);
	-moz-transform: translate(0, -10px);
	-o-transform: translate(0, -10px);
	-ms-transform: translate(0, -10px);
	background-color: #edebeb90;
}

.calculate-middle {
	background-color: #fff;
}

.main-table {
	/* 【宽度的设定依据？】 */
	width: 65vw;
	padding: 20px 0px;
	margin: 0 auto;
}

.single-select-table thead .el-table-column--selection .cell {
	display: none;
}
</style>
