var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("RADIATION (MRADI)")]),_c('el-form',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"Radiation Medium Name"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.RADIATION.Coolant
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.RADIATION, "Coolant", $$v)},expression:"\n                            $store.state.ProcessProperties.RADIATION.Coolant\n                        "}})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"Boundary Number"}},[_c('el-input',{model:{value:(
                            _vm.$store.state.ProcessProperties.RADIATION
                                .BoundaryNumber
                        ),callback:function ($$v) {_vm.$set(_vm.$store.state.ProcessProperties.RADIATION
                                , "BoundaryNumber", $$v)},expression:"\n                            $store.state.ProcessProperties.RADIATION\n                                .BoundaryNumber\n                        "}})],1)],1)],1)],1),_vm._l((_vm.$store.state.ProcessProperties.RADIATION
            .Boundary),function(item,index){return _c('el-form',{key:index,attrs:{"label-position":"top"}},[_c('el-card',{staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Boundary Index："+_vm._s(index + 1))]),(_vm.PopButtonShow(index))?_c('el-button',{staticStyle:{"float":"right","padding":"3px 3px"},attrs:{"type":"text"},on:{"click":_vm.RADIATIONPop}},[_vm._v("减少数据")]):_vm._e(),(_vm.AddButtonShow(index))?_c('el-button',{staticStyle:{"float":"right","padding":"3px 3px"},attrs:{"type":"text"},on:{"click":_vm.RADIATIONAdd}},[_vm._v("添加数据")]):_vm._e()],1),_c('el-card',{attrs:{"shadow":"hover"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"Boundary Index："}},[_c('el-input',{model:{value:(item.BoundaryIndex),callback:function ($$v) {_vm.$set(item, "BoundaryIndex", $$v)},expression:"item.BoundaryIndex"}})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"Ambient  Temperature："}},[_c('el-input',{model:{value:(item.AmbientTemperature),callback:function ($$v) {_vm.$set(item, "AmbientTemperature", $$v)},expression:"item.AmbientTemperature"}})],1)],1)],1)],1),_c('br'),_c('el-card',{attrs:{"shadow":"hover"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"Temperature："}})],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"Radiation coefficient："}})],1)],1),_vm._l((item.TemperatureList),function(temperature,temperatureIndex){return _c('el-row',{key:temperatureIndex,attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":4}},[_c('el-form-item',[_c('el-input',{model:{value:(temperature.Temperature),callback:function ($$v) {_vm.$set(temperature, "Temperature", $$v)},expression:"temperature.Temperature"}})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',[_c('el-input',{model:{value:(
                                    temperature.HeatTransferCoefficient
                                ),callback:function ($$v) {_vm.$set(temperature, "HeatTransferCoefficient", $$v)},expression:"\n                                    temperature.HeatTransferCoefficient\n                                "}})],1)],1)],1)}),_c('el-button',{on:{"click":function($event){return _vm.BoundaryAdd(index)}}},[_vm._v("添加数据")]),_c('el-button',{on:{"click":function($event){return _vm.BoundaryPop(index)}}},[_vm._v("减少数据")])],2)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }