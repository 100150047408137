<template>
    <div class="container">
        <div class="main">
            <div style="overflow: hidden"></div>
            <div class="register-main">
                <div class="register-main-content">
                    <div class="login-title">申请使用</div>

                    <div class="register-input">
                        <el-form
                            :model="addForm"
                            ref="form"
                            label-width="80px"
                            label-position="top"
                            :rules="rules"
                        >
                            <el-form-item label="邮箱:" prop="email">
                                <el-input
                                    placeholder="请输入您的邮箱"
                                    v-model="addForm.email"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-message"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="姓名:">
                                <el-input
                                    placeholder="请输入您的姓名"
                                    v-model="addForm.name"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-s-custom"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="用户名:">
                                <el-input
                                    placeholder="请输入用户名"
                                    v-model="addForm.username"
                                >
                                    <i
                                        slot="prefix"
                                        class="
                                            el-input__icon
                                            el-icon-user-solid
                                        "
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="密码:">
                                <el-input
                                    placeholder="请输入密码"
                                    v-model="addForm.password"
                                    show-password
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-lock"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="联系电话:">
                                <el-input
                                    placeholder="请输入联系电话"
                                    v-model="addForm.tel"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-phone"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="通讯地址:">
                                <el-input
                                    placeholder="请输入通讯地址"
                                    v-model="addForm.address"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-s-home"
                                    ></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="验证码:">
                                <div class="sidentifyContent">
                                    <el-input
                                        placeholder="请输入验证码"
                                        v-model="verificationCode"
                                    >
                                        <i
                                            slot="prefix"
                                            class="
                                                el-input__icon
                                                el-icon-s-claim
                                            "
                                        ></i>
                                    </el-input>
                                    <v-sidentify></v-sidentify>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="login-main-right-button">
                        <el-button
                            style="width: 100%; font-size: 18px"
                            type="danger"
                            @click="RegisterConfirm"
                            >注册</el-button
                        >
                        <div>
                            <div style="position: relative">
                                <el-button
                                    style="
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-left: 2%;
                                    "
                                    type="text"
                                    v-if="agreementState"
                                    >您已同意用户协议</el-button
                                >
                                <el-button
                                    style="
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-left: 2%;
                                    "
                                    type="text"
                                    @click="WatchAgreement"
                                    v-if="!agreementState"
                                    >点击阅读用户协议</el-button
                                >
                                <p
                                    style="
                                        position: absolute;
                                        top: 20%;
                                        margin-left: 85%;
                                        font-size: 16px;
                                        font-weight: 600;
                                    "
                                >
                                    <a href="/">已有账号?</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer">
                <div class="info">版权所有：&copy; COSMAP</div>
                <div class="info">中丨English丨日本語</div>
            </div>
        </div>

        <el-dialog
            title="验证您的邮箱"
            :visible.sync="validationEmaildialogVisible"
            width="60%"
        >
            <div style="height: 70px">
                为了验证您的邮箱，请检查您输入邮箱中的邮件，并将其中的验证码输入到下方
            </div>
            <div style="height: 100px">
                <el-form label-width="80px">
                    <el-form-item label="验证码:">
                        <el-input
                            placeholder="请输入您收到的验证码"
                            v-model="validationEmailCode"
                        >
                            <i
                                slot="prefix"
                                class="el-input__icon el-icon-message"
                            ></i>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="validationEmaildialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="validationEmail"
                    >确定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog title="用户协议" :visible.sync="dialogVisible" width="60%">
            <div style="min-height: 300px">
                <pre>
欢迎阅读《APP用户服务协议》(以下简称“本协议”)。本协议阐述之条款和条件适用于您（以下简称“用户”）使用APP的各种产品和服务。
1. 服务协议的确认 
1.1
先进热处理智能制造科技有限公司及相关关联企业（以下合称“先进热处理智能制造公司”）同意按照本协议的规定及其不时发布的操作规则提供基于互联网移动网的先进热处理智能制造相关服务（以下简称“先进热处理智能制造服务”）。
1.2
先进热处理智能制造服务使用人（以下简称“用户”）应当基于了解本协议全部内容、在独立思考的基础上认可、同意本协议的全部条款并按照页面上的提示完成全部的注册程序，用户的注册、登录、使用等行为将视为完全接受本协议及先进热处理智能制造公司公示的各项规则、规范。
1.3
先进热处理智能制造公司享有对先进热处理智能制造网站上一切活动的监督、提示、检查、纠正等权利。
2. 服务内容 
2.1
先进热处理智能制造服务的具体内容由先进热处理智能制造公司根据实际情况提供，包括但不限于授权用户通过其帐号上传、搜索并向互相关联的用户推送相关信息、授权用户对其进行收录、分享等，先进热处理智能制造公司有权对其提供的服务或产品形态进行升级或其他调整，均以APP\网站内容更新的方式通知用户，不单独通知用户。
2.2
先进热处理智能制造公司提供的先进热处理智能制造服务中可能包括广告，用户同意在使用过程中显示先进热处理智能制造和第三方供应商、合作伙伴提供的广告。
2.3
先进热处理智能制造公司仅提供与先进热处理智能制造服务相关的技术服务等，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。
3. 服务变更、中断或终止 
3.1
鉴于网络服务的特殊性（包括但不限于不可抗力、网络稳定性、系统稳定性、技术故障、用户所在位置、用户关机、用户手机病毒或恶意的网络攻击行为及其他任何技术、互联网络、通信线路、内容侵权等原因），用户同意，先进热处理智能制造公司有权随时中断或终止部分或全部先进热处理智能制造服务，先进热处理智能制造公司将尽可能及时以合理方式通知用户，并尽可能在第一时间对此进行修复。但对因此导致用户不能发送和接受阅读信息、或接发错信息，先进热处理智能制造公司不承担任何责任，用户须承担以上风险。
3.2
用户理解，先进热处理智能制造公司需要定期或不定期地对提供先进热处理智能制造服务的平台或相关设备进行检修和维护，如因此类情况而造成服务在合理期间内的中断，先进热处理智能制造公司将尽可能事先通知，但无需为此承担任何责任。
3.3
用户提供的个人资料不真实、用户违反法律法规国家政策或本协议规定的使用规则，先进热处理智能制造公司有权随时中断或终止向用户提供本协议项下的先进热处理智能制造服务，而无需对用户或任何第三方承担任何责任。
4. 使用规则 
4.1
用户在申请注册先进热处理智能制造服务时，必须向先进热处理智能制造公司提供准确的主体资料（用户为个人时，包括姓名、性别、出生年月、电话、邮箱等；用户为单位时，包括名称、注册地址、注册号等），如主体资料有任何变动，必须及时更新。因用户提供资料的真实性问题导致协议双方或第三方的任何损失均由用户承担。
4.2
用户注册成功后，将获得一个先进热处理智能制造帐号及由用户设置的密码，该用户帐号和密码由用户负责保管；用户应当对该用户帐号所有行为负相关法律责任。
用户在使用先进热处理智能制造服务过程中，必须遵循以下原则: 
(1)遵守中国有关的法律和法规； 
(2)不得为任何非法目的而使用先进热处理智能制造服务； 
(3)遵守所有与网络服务有关的网络协议、规定和程序； 
(4)不得利用先进热处理智能制造服务系统进行任何可能对互联网的正常运转造成不利影响的行为；
(5) 不得利用先进热处理智能制造网络服务系统进行任何不利于先进热处理智能制造公司的行为。 
4.3
用户不得使用先进热处理智能制造服务制作、上传、发送、传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息:
(1) 反对宪法所确定的基本原则的； 
(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 
(3)损害国家荣誉和利益的； 
(4)煽动民族仇恨、民族歧视，破坏民族团结的； 
(5)破坏国家宗教政策，宣扬邪教和封建迷信的； 
(6)散布谣言，扰乱社会秩序，破坏社会稳定的； 
(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； 
(8)侮辱或者诽谤他人，侵害他人合法权益的；
(9)含有法律、行政法规禁止的其他内容的。 
4.4
用户同意在任何情况下不使用其他用户的帐号或密码。在您怀疑他人在使用您的帐号或密码时，您同意立即通知先进热处理智能制造公司。
4.5
用户有权更改、删除在先进热处理智能制造服务中的个人资料、注册信息及传送内容等，但删除有关信息的同时也会删除任何您储存在系统中的文字和图片。用户需承担该风险。
4.6
如因用户违反本协议中的任何条款，先进热处理智能制造公司有权依据本协议终止对违约用户先进热处理智能制造帐号提供服务。
4.7
如用户注册先进热处理智能制造帐号后长期不登录该帐号，先进热处理智能制造公司有权回收该帐号，以免造成资源浪费，由此带来问题均由用户自行承担。
5. 知识产权 
5.1
先进热处理智能制造公司提供先进热处理智能制造服务中所包含的任何文本、图片、标识、音频、视频资料均受著作权、商标权、专利权及其他财产权法律的保护。
5.2
未经相关权利人和先进热处理智能制造公司的同意，上述资料均不得以任何方式被展示于其他任何第三方平台或被用于其他任何商业目的；用户不得擅自复制、修改、编纂上述内容、或创造与内容有关的衍生产品。
6. 隐私保护 
6.1
本协议所指的“隐私”包括《电信和互联网用户个人信息保护规定》第4条规定的用户个人信息的内容以及未来不时制定或修订的法律法规中明确规定的隐私应包括的内容。
6.2
保护用户隐私和个人数据是先进热处理智能制造公司的一项基本制度，先进热处理智能制造公司将采取各种制度、安全技术和程序等措施来保护用户隐私和个人数据不被未经授权的访问、使用或泄漏，并保证不会将单个用户的注册资料及用户在使用先进热处理智能制造服务时存储在先进热处理智能制造公司的非公开内容向除合作单位以外的第三方公开或用于任何非法的用途，但下列情况除外：
(1) 事先获得用户的明确授权； 
(2) 根据有关法律法规的要求； 
(3)按照相关政府主管部门的要求； 
(4) 为维护社会公众的利益； 
(5)用户侵害本协议项下先进热处理智能制造公司的合法权益的情况下而为维护先进热处理智能制造公司的合法权益所必须。
6.3
用户在注册先进热处理智能制造帐号或使用先进热处理智能制造服务的过程中，需要提供一些必要的信息，例如：姓名、电话、邮箱、性别、出生年月、名称、注册地址、注册号等。为向用户提供帐号注册服务或进行用户身份识别，需要用户填写手机号码；部分功能需要用户授权使用用户的相机或相册；部分功能需要用户授权访问用户的手机通讯录等。若用户不授权或提供的信息不完整，则无法使用本服务或在使用过程中受到限制。用户授权提供的信息，先进热处理智能制造公司承诺将采取措施保护用户的个人信息安全。
6.4
为了向用户提供更好的用户体验和提高先进热处理智能制造的服务质量，先进热处理智能制造公司将可能会收集使用或向第三方提供用户的非个人隐私信息。先进热处理智能制造公司将对该第三方使用用户个人数据的行为进行监督和管理，尽一切可能努力保护用户个人信息的安全。
7. 免责声明 
7.1
用户在使用先进热处理智能制造服务的过程中应遵守国家法律法规及政策规定，对其所制作、上传、发送、传播的信息和内容承担任何法律责任，与先进热处理智能制造公司无关。
7.2
先进热处理智能制造在其页面上向用户显示、推送的任何信息和内容如系先进热处理智能制造公司利用技术手段根据用户指令从互联网任何第三方网站搜索、定位、匹配后推荐给用户而非先进热处理智能制造公司单方制作的，则该显示、推送的信息和内容并不代表先进热处理智能制造公司及先进热处理智能制造的观点，先进热处理智能制造公司并不对上述信息的准确性和正确性负责。
7.3先进热处理智能制造公司提供先进热处理智能制造服务中所包含的任何文本、图片、标识、音频、视频资料均为教学用途，不涉及任何实体物品的商业活动。
7.4先进热处理智能制造在其页面上向用户显示、推送的任何信息和内容如存在侵权任何第三方知识产权的嫌疑，权利人和相关利害关系人应当向先进热处理智能制造公司发出权利通知，先进热处理智能制造公司经过核实后根据有关法律法规有权采取包括但不限于断开该侵权内容的链接或删除并停止传输该侵权内容，但先进热处理智能制造公司并不对该侵权内容承担法律责任。
7.5用户违反本协议的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿先进热处理智能制造公司，使之免受损害。
8. 其他条款 
8.1
先进热处理智能制造公司有权随时修改本协议的任何条款，一旦本协议的内容发生变动，先进热处理智能制造公司将会在先进热处理智能制造平台中公布修改后的协议内容或通过其他适当方式向用户公布修改内容。用户不同意上述修改内容，有权选择停止使用先进热处理智能制造服务，但用户继续使用，则视为接受先进热处理智能制造公司对本协议相关条款的修改。
8.2
本《协议》所定的任何条款无论因何种原因部分或全部无效或不具有执行力，本协议的其余条款仍应有效并具备约束力。
8.3
本协议的订立、执行和解释及纠纷的解决均应适用中华人民共和国法律并受中华人民共和国法院管辖。如双方就本协议内容或执行发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方均可向先进热处理智能制造公司所在地的有管辖权的人民法院提起诉讼。
8.4
本协议的版权归先进热处理智能制造公司所有，本协议各项条款内容的最终解释权及修改权归先进热处理智能制造公司所有。
                </pre>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AgreeAgreement"
                    >接 受</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    RegisterUser,
    SendEmailToRegister,
    RegisterEmailValidation,
} from "../api/api";
import Sidentify from "../components/Sidentify"; //**引入验证码组件**
export default {
    components: {
        "v-sidentify": Sidentify,
    },
    data() {
        return {
            dialogVisible: false,
            validationEmaildialogVisible: false,
            verificationCode: "",
            validationEmailCode: "",
            agreementState: false,

            addForm: {
                email: null,
                username: "",
                password: "",
                name: "",
                tel: "",
                address: "",
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern:
                            /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                        message: "邮箱格式异常",
                        trigger: "blur",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        WatchAgreement() {
            this.dialogVisible = true;
        },
        AgreeAgreement() {
            this.agreementState = true;
            this.dialogVisible = false;
        },
        validationEmailClick() {},
        validationEmail() {
            let validateFlag = false;
            let parms = {
                Email: this.addForm.email,
                Code: this.validationEmailCode,
            };
            RegisterEmailValidation(parms).then((res) => {
                if (res == true) {
                    RegisterUser(this.addForm).then((res) => {
                        if (res == true) {
                            this.$message({
                                message:
                                    "注册成功，请等待管理员审核，即将在5秒后跳转至主页登陆",
                                type: "success",
                            });
                            setTimeout(() => {
                                this.$router.push({
                                    path: "/Helper",
                                });
                            }, 5000);
                        } else {
                            this.$message.error("注册失败，请联系管理员！");
                        }
                        this.validationEmaildialogVisible = false;
                    });
                } else {
                    this.$message.error("您的验证码输入有误！");
                }
            });
        },
        //点击确认添加按钮
        RegisterConfirm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    console.log(valid);
                } else {
                    return;
                }
            });
            if (this.agreementState == false) {
                this.$message({
                    message: "请先接受用户协议",
                    type: "warning",
                });
                return;
            }
            var parms = {
                Email: this.addForm.email,
            };
            SendEmailToRegister(parms).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "已将验证码发送到您的邮箱",
                        type: "success",
                    });
                    this.validationEmaildialogVisible = true;
                } else {
                    this.$message.error("注册失败，请联系管理员！");
                }
            });
        },
        // handleClose(done) {
        //     this.$confirm("")
        //         .then((_) => {
        //             done();
        //         })
        //         .catch((_) => {});
        // },
    },
};
</script>
<style scoped>
.main {
    background-color: #4780ff;
    height: 60vh;
    background-image: url("../assets/loginBackground.png");
    background-size: 100% 100%;
}
.main .register-main {
    margin: 10vh auto 5vh auto;
    padding-bottom: 10px;
    background-color: white;

    width: 900px;
    border-radius: 2px;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
}

.register-main-content {
    margin: 0 auto;

    width: 500px;
    height: 100%;

    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.login-main-right-button {
    width: 100%;
    height: 70px;
    margin-bottom: 30px;
}

.login-main-right-button a {
    color: #8492a6;
    font-size: 15px;
}
.register-input {
    min-height: 190x;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.login-title {
    padding-top: 30px;

    text-align: center;
    color: #f56c6c;
    font-size: 26px;
    font-weight: 550;
    border-bottom: 1px solid #f56c6c;
    height: 45px;
    margin-bottom: 10px;
}
.sidentifyContent {
    display: flex;
    flex-direction: row;
}
.sidentifyContent .el-input__icon {
    height: auto;
}
.footer {
    background-color: white;
    text-align: center;

    height: 60px;
}
.footer .info {
    font-size: 15px;
    color: #8492a6;
    width: 200px;
    margin: 0 auto;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}
</style>