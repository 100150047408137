import { render, staticRenderFns } from "./PasswordForget.vue?vue&type=template&id=25df8345&scoped=true&"
import script from "./PasswordForget.vue?vue&type=script&lang=js&"
export * from "./PasswordForget.vue?vue&type=script&lang=js&"
import style0 from "./PasswordForget.vue?vue&type=style&index=0&id=25df8345&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25df8345",
  null
  
)

export default component.exports