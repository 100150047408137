<template>
    <div>
        <el-divider content-position="left">RADIATION (MRADI)</el-divider>
        <el-form>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-form-item label="Radiation Medium Name">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.RADIATION.Coolant
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Boundary Number">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.RADIATION
                                    .BoundaryNumber
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-form
            v-for="(item, index) in $store.state.ProcessProperties.RADIATION
                .Boundary"
            :key="index"
            label-position="top"
        >
            <el-card style="margin-bottom: 5px">
                <div slot="header" class="clearfix">
                    <span>Boundary Index：{{ index + 1 }}</span>
                    <el-button
                        v-if="PopButtonShow(index)"
                        @click="RADIATIONPop"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >减少数据</el-button
                    >
                    <el-button
                        v-if="AddButtonShow(index)"
                        @click="RADIATIONAdd"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >添加数据</el-button
                    >
                </div>
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item label="Boundary Index：">
                                <el-input
                                    v-model="item.BoundaryIndex"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Ambient  Temperature：">
                                <el-input
                                    v-model="item.AmbientTemperature"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
                <br />
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item label="Temperature："> </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Radiation coefficient：">
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row
                        :gutter="20"
                        v-for="(
                            temperature, temperatureIndex
                        ) in item.TemperatureList"
                        :key="temperatureIndex"
                    >
                        <el-col :span="4">
                            <el-form-item>
                                <el-input
                                    v-model="temperature.Temperature"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-input
                                    v-model="
                                        temperature.HeatTransferCoefficient
                                    "
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="BoundaryAdd(index)">添加数据</el-button>
                    <el-button @click="BoundaryPop(index)">减少数据</el-button>
                </el-card>
            </el-card>
        </el-form>
    </div>
</template>
<script>
import processTable from "../components/processTable";
export default {
    components: {
        processTable,
    },
    computed: {},
    data() {
        return {
            activeTab: "first",
            // ProcessProperties: this.$store.state.ProcessProperties,
        };
    },
    mounted() {},
    methods: {
        RADIATIONAdd() {
            this.$store.commit("RADIATIONAdd", "");
        },
        RADIATIONPop() {
            this.$store.commit("RADIATIONPop", "");
        },
        BoundaryAdd(index) {
            this.$store.commit("RADIATIONBoundaryTemperatureListAdd", index);
        },
        BoundaryPop(index) {
            this.$store.commit("RADIATIONBoundaryTemperatureListPop", index);
        },
        AddButtonShow(index) {
            return (
                index ==
                this.$store.state.ProcessProperties.RADIATION.Boundary.length -
                    1
            );
        },
        PopButtonShow(index) {
            return (
                index ==
                    this.$store.state.ProcessProperties.RADIATION.Boundary
                        .length -
                        1 && index != 0
            );
        },
    },
};
</script>
