<template>
    <div>
        <el-divider content-position="left">TEMP (MTHER)</el-divider>
        <el-form>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-form-item label="Temperature Order Name">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.TEMP
                                    .TemperatureOrderName
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="TH">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.TEMP.THNumber
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-form
            v-for="(item, index) in $store.state.ProcessProperties.TEMP.TH"
            :key="'root' + index"
            label-position="top"
        >
            <el-card style="margin-bottom: 5px">
                <div slot="header" class="clearfix">
                    <span>TH Index：{{ index + 1 }}</span>
                    <el-button
                        v-if="PopButtonShow(index)"
                        @click="TEMPPop"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >减少数据</el-button
                    >
                    <el-button
                        v-if="AddButtonShow(index)"
                        @click="TEMPAdd"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >添加数据</el-button
                    >
                </div>
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item label="Time:"> </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Temperature："> </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="20"
                        v-for="(
                            temperature, temperatureIndex
                        ) in item.TimeTemperature"
                        :key="'sub1' + temperatureIndex"
                    >
                        <el-col :span="4">
                            <el-form-item>
                                <el-input v-model="temperature.Time"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-input
                                    v-model="temperature.Temperature"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="TimeTemperatureAdd(index)"
                        >添加数据</el-button
                    >
                    <el-button @click="TimeTemperaturePop(index)"
                        >减少数据</el-button
                    >
                </el-card>
                <br />
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item label="Start Node:"> </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="End Node"> </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Node interval:">
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Time function number">
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="20"
                        v-for="(node, nodeIndex) in item.NodeInfo"
                        :key="'sub2' + nodeIndex"
                    >
                        <el-col :span="4">
                            <el-form-item>
                                <el-input v-model="node.StartNode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-input v-model="node.EndNode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-input
                                    v-model="node.NodeInterval"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-input
                                    v-model="node.TimeFunctionNumber"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="NodeInfoAdd(index)">添加数据</el-button>
                    <el-button @click="NodeInfoPop(index)">减少数据</el-button>
                </el-card>
            </el-card>
        </el-form>
    </div>
</template>
<script>
import processTable from "../components/processTable";
export default {
    components: {
        processTable,
    },
    computed: {},
    data() {
        return {
            activeTab: "first",
            // ProcessProperties: this.$store.state.ProcessProperties,
        };
    },
    mounted() {},
    methods: {
        TEMPAdd() {
            this.$store.commit("TEMPAdd", "");
        },
        TEMPPop() {
            this.$store.commit("TEMPPop", "");
        },
        TimeTemperatureAdd(index) {
            this.$store.commit("TEMPTimeTemperatureListAdd", index);
        },
        TimeTemperaturePop(index) {
            this.$store.commit("TEMPTimeTemperatureListPop", index);
        },
        NodeInfoAdd(index) {
            this.$store.commit("TEMPNodeInfoListAdd", index);
        },
        NodeInfoPop(index) {
            this.$store.commit("TEMPNodeInfoListPop", index);
        },
        AddButtonShow(index) {
            return (
                index == this.$store.state.ProcessProperties.TEMP.TH.length - 1
            );
        },
        PopButtonShow(index) {
            return (
                index ==
                    this.$store.state.ProcessProperties.TEMP.TH.length - 1 &&
                index != 0
            );
        },
    },
};
</script>
