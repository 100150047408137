<template>
    <div>
        <el-form label-position="top">
            <el-divider content-position="left"
                >MSUFDC ( Carburization )</el-divider
            >
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-form-item label="Surface diffusion coefficient">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.DIFUSSION.MSUFDC
                                    .SurfaceDiffusionCoefficient
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="Suface circumstance concentration">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.DIFUSSION.MSUFDC
                                    .SufaceCircumstanceConcentration
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-divider content-position="left"
                >MSUFDN ( Nitriding )</el-divider
            >
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-form-item label="Surface diffusion coefficient">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.DIFUSSION.MSUFDN
                                    .SurfaceDiffusionCoefficient
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="Suface circumstance concentration">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.DIFUSSION.MSUFDN
                                    .SufaceCircumstanceConcentration
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-divider content-position="left">MSUFDO ( Other )</el-divider>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-form-item label="Surface diffusion coefficient">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.DIFUSSION.MSUFDO
                                    .SurfaceDiffusionCoefficient
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="Suface circumstance concentration">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.DIFUSSION.MSUFDO
                                    .SufaceCircumstanceConcentration
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import processTable from "../components/processTable";
export default {
    components: {
        processTable,
    },
    computed: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {
        SubprocessAdd() {},
    },
};
</script>
