<template>
    <div>
        <el-divider content-position="left">DEFORMATION (MSUFD)</el-divider>
        <el-form>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-form-item label="Deformation boundary number ">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.Deformation
                                    .DeformationAddBoundaryNumber
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-form
            v-for="(item, index) in $store.state.ProcessProperties.Deformation
                .DeformationList"
            :key="'root' + index"
            label-position="top"
        >
            <el-card style="margin-bottom: 5px">
                <div slot="header" class="clearfix">
                    <span>Deformation Index：{{ index + 1 }}</span>
                    <el-button
                        v-if="PopButtonShow(index)"
                        @click="DeformationPop"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >减少数据</el-button
                    >
                    <el-button
                        v-if="AddButtonShow(index)"
                        @click="DeformationAdd"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >添加数据</el-button
                    >
                </div>
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label="Time:"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Deformation:"> </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="20"
                        v-for="(
                            deformation, deformationIndex
                        ) in item.TimeDeformation"
                        :key="'sub1' + deformationIndex"
                    >
                        <el-col :span="3">
                            <el-form-item>
                                <el-input v-model="deformation.Time"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="deformation.Deformation"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="TimeDeformationAdd(index)"
                        >添加数据</el-button
                    >
                    <el-button @click="TimeDeformationPop(index)"
                        >减少数据</el-button
                    >
                </el-card>
                <br />
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label="Node:"> </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="Direction Time Function:">
                            </el-form-item>
                        </el-col>

                        <el-col :span="9">
                            <el-form-item label="Coefficient of displacement:">
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label=""> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="X"> </el-form-item>
                        </el-col>

                        <el-col :span="3">
                            <el-form-item label="Y"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Z"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="X"> </el-form-item>
                        </el-col>

                        <el-col :span="3">
                            <el-form-item label="Y"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Z"> </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="20"
                        v-for="(node, nodeIndex) in item.NodeInfo"
                        :key="'sub2' + nodeIndex"
                    >
                        <el-col :span="3">
                            <el-form-item>
                                <el-input v-model="node.Node"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.DirectionTimeFunctionX"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.DirectionTimeFunctionY"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.DirectionTimeFunctionZ"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.CoefficientDisplacementX"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.CoefficientDisplacementY"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.CoefficientDisplacementZ"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="NodeInfoAdd(index)">添加数据</el-button>
                    <el-button @click="NodeInfoPop(index)">减少数据</el-button>
                </el-card>
            </el-card>
        </el-form>
    </div>
</template>
<script>
import processTable from "../components/processTable";
export default {
    components: {
        processTable,
    },
    computed: {},
    data() {
        return {
            activeTab: "first",
            // ProcessProperties: this.$store.state.ProcessProperties,
        };
    },
    mounted() {},
    methods: {
        DeformationAdd() {
            this.$store.commit("DeformationAdd", "");
        },
        DeformationPop() {
            this.$store.commit("DeformationPop", "");
        },
        TimeDeformationAdd(index) {
            this.$store.commit("DeformationTimeDeformationAdd", index);
        },
        TimeDeformationPop(index) {
            this.$store.commit("DeformationTimeDeformationPop", index);
        },
        NodeInfoAdd(index) {
            this.$store.commit("DeformationNodeInfoListAdd", index);
        },
        NodeInfoPop(index) {
            this.$store.commit("DeformationNodeInfoListPop", index);
        },
        AddButtonShow(index) {
            return (
                index ==
                this.$store.state.ProcessProperties.Deformation.DeformationList
                    .length -
                    1
            );
        },
        PopButtonShow(index) {
            return (
                index ==
                    this.$store.state.ProcessProperties.Deformation
                        .DeformationList.length -
                        1 && index != 0
            );
        },
    },
};
</script>
