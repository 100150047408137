<template>
    <div>
        <!-- <div class="menu">
            <div class="menu-item" @click="toUserManage">广域网用户管理</div>
            <div class="menu-item">局域网用户管理</div>
            <div class="menu-item" @click="toRegisterManage">查看申请</div>
            <div class="menu-item" @click="toCompanyManage">单位管理</div>
            <div class="menu-item" @click="toLog">安全日志</div>
        </div> -->

        <div class="main-table">
            <el-col :span="24" class="toolbar" style="padding-bottom: 20px">
                <el-input
                    style="width: 200px"
                    placeholder="搜索名称"
                    v-model="queryStr"
                ></el-input
                >&nbsp;&nbsp;
                <el-button @click="refreshTable()">查询</el-button>
                <el-button type="danger" @click="refreshTable()"
                    >刷新列表</el-button
                >
                <el-button type="primary" @click="changeDialogFormVisible()"
                    >添加</el-button
                >
            </el-col>
            <el-table
                :data="tableData"
                style="min-height: 55vh; bacground-color: #ffffff60"
            >
                <el-table-column
                    prop="Id"
                    label="Id"
                    width="150"
                    :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="Username" label="用户名" width="150">
                </el-table-column>
                <el-table-column
                    prop="Type"
                    label="用户类型"
                    width="150"
                ></el-table-column>
                <el-table-column prop="Name" label="姓名" width="150">
                </el-table-column>
                <el-table-column
                    prop="Tel"
                    label="联系方式"
                    width="150"
                ></el-table-column>
                <el-table-column
                    prop="Address"
                    label="通讯地址"
                    width="150"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="Company.Name"
                    label="单位名称"
                    width="150"
                ></el-table-column>
                <el-table-column prop="CreateTime" label="注册时间" width="180">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleEdit(scope.row)"
                            type="text"
                            size="small"
                            >编辑</el-button
                        >
                        <el-button
                            @click="handleDelete(scope.row)"
                            type="text"
                            size="small"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNumber"
                ></el-pagination>
            </div>
        </div>

        <el-dialog
            title="添加用户"
            :visible.sync="addDialogFormVisible"
            :append-to-body="true"
        >
            <el-form :model="addForm">
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.name"
                        autocomplete="off"
                        placeholder="请输入用户姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用户名" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.username"
                        autocomplete="off"
                        placeholder="请输入用户名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.password"
                        autocomplete="off"
                        placeholder="请输入密码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.tel"
                        autocomplete="off"
                        placeholder="请输入联系方式"
                    ></el-input>
                </el-form-item>
                <el-form-item label="通讯地址" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.address"
                        autocomplete="off"
                        placeholder="请输入通讯地址"
                    ></el-input>
                </el-form-item>
                <el-form-item label="单位" :label-width="formLabelWidth">
                    <el-select
                        v-model="addForm.companyId"
                        placeholder="请选择单位"
                    >
                        <el-option
                            v-for="(item, i) in companyList"
                            :label="item.Name"
                            :value="item.Id"
                            :key="i"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别" :label-width="formLabelWidth">
                    <el-select
                        v-model="addForm.type"
                        placeholder="请输入用户类型"
                    >
                        <!-- <el-option label="管理员" value="管理员"></el-option> -->
                        <el-option
                            label="普通用户"
                            value="普通用户"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="权限管理" :label-width="formLabelWidth">
                    <el-checkbox-group v-model="addAccess">
                        <el-checkbox label="COSMAP" name="type"></el-checkbox>
                        <el-checkbox
                            label="热处理优化系统"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox label="设备库" name="type"></el-checkbox>
                        <el-checkbox label="材料库" name="type"></el-checkbox>
                        <el-checkbox
                            label="工艺优化库"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="AddConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="编辑用户"
            :visible.sync="editDialogFormVisible"
            :append-to-body="true"
        >
            <el-form :model="editForm">
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.name"
                        autocomplete="off"
                        placeholder="请输入用户姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用户名" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.username"
                        autocomplete="off"
                        placeholder="请输入用户名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.password"
                        autocomplete="off"
                        placeholder="请输入密码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.tel"
                        autocomplete="off"
                        placeholder="请输入联系方式"
                    ></el-input>
                </el-form-item>
                <el-form-item label="通讯地址" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.address"
                        autocomplete="off"
                        placeholder="请输入通讯地址"
                    ></el-input>
                </el-form-item>
                <el-form-item label="单位" :label-width="formLabelWidth">
                    <el-select
                        v-model="editForm.companyId"
                        placeholder="请选择单位"
                    >
                        <el-option
                            v-for="(item, i) in companyList"
                            :label="item.Name"
                            :value="item.Id"
                            :key="i"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别" :label-width="formLabelWidth">
                    <el-select
                        v-model="editForm.type"
                        placeholder="请输入用户类型"
                    >
                        <!-- <el-option label="管理员" value="管理员"></el-option> -->
                        <el-option
                            label="普通用户"
                            value="普通用户"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="权限管理" :label-width="formLabelWidth">
                    <el-checkbox-group v-model="editAccess">
                        <el-checkbox label="COSMAP" name="type"></el-checkbox>
                        <el-checkbox
                            label="热处理优化系统"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox label="设备库" name="type"></el-checkbox>
                        <el-checkbox label="材料库" name="type"></el-checkbox>
                        <el-checkbox
                            label="工艺优化库"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    SelectUser,
    SelectCompanyUser,
    AddUser,
    DeleteUser,
    EditUser,
    SelectAllCompany,
} from "../api/api";
export default {
    components: {},
    data() {
        return {
            tableData: [], //表数据
            queryStr: "",
            currentPage: 1, //当前是第几页
            pageSize: 7,
            pageSizes: [7, 10, 20, 30],
            totalNumber: 0, //共计多少条数据
            orderBy: "",
            addDialogFormVisible: false,
            editDialogFormVisible: false,
            addAccess: [],
            editAccess: [],
            addForm: {
                username: "",
                password: "",
                name: "",
                type: "",
                access: "",
                tel: "",
                address: "",
                companyId: "",
            },
            editForm: {
                id: "",
                username: "",
                password: "",
                name: "",
                type: "",
                access: "",
                tel: "",
                address: "",
                companyId: "",
            },
            companyList: [],
            formLabelWidth: "120px",
        };
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        //返回首页
        toHome() {
            this.$router.push({ path: "/home" });
        },
        toRegisterManage() {
            this.$router.push({ path: "/home/registerManage" });
        },
        toUserManage() {
            this.$router.push({ path: "/home/userManage" });
        },
        toCompanyManage() {
            this.$router.push({ path: "/home/CompanyManage" });
        },
        toLog() {
            this.$router.push({ path: "/home/Log" });
        },
        //获取表格数据
        getTableData() {
            var pageData = {
                name: this.queryStr,
                desc: false,
                currentPage: this.currentPage - 1,
                pageNumber: this.pageSize,
                username: localStorage.getItem("name"),
                //_CompanyId: "064371E84362493EB1EB8E6E378E5963",
            };
            SelectCompanyUser(pageData).then((res) => {
                this.tableData = res.data;
                this.totalNumber = res.count;
            });

            SelectAllCompany().then((res) => {
                this.companyList = res;
            });
        },
        //刷新表格
        refreshTable() {
            this.getTableData();
        },
        //点击添加按钮（改变添加dialog状态）
        changeDialogFormVisible() {
            this.addDialogFormVisible = true;
        },
        //点击确认添加按钮
        AddConfirm() {
            this.addForm.access = this.addAccess.toString();
            AddUser(this.addForm).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "添加用户成功！",
                        type: "success",
                    });
                } else {
                    this.$message.error("添加用户失败！");
                }
                this.addDialogFormVisible = false;
                this.getTableData();
            });
        },
        //点击修改按钮
        handleEdit(row) {
            this.editForm.id = row.Id;
            this.editForm.name = row.Name;
            this.editForm.username = row.Username;
            this.editForm.password = row.Password;
            this.editForm.type = row.Type;
            this.editForm.address = row.Address;
            this.editForm.tel = row.Tel;
            this.editForm.companyId = row.CompanyId;
            let accessStr = row.Access;
            if (accessStr != null) {
                this.editAccess = accessStr.split(",");
            } else {
                this.editAccess = [];
            }
            this.editDialogFormVisible = true;
        },
        //点击确认修改按钮
        EditConfirm() {
            this.editForm.access = this.editAccess.toString();
            EditUser(this.editForm).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "编辑用户成功！",
                        type: "success",
                    });
                    this.getTableData();
                } else {
                    this.$message.error("编辑用户失败！");
                }
                this.editDialogFormVisible = false;
                this.getTableData();
            });
        },
        //点击删除按钮
        handleDelete(row) {
            var Data = {
                id: row.Id,
            };
            console.log(row);
            DeleteUser(Data).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "删除用户成功！",
                        type: "success",
                    });
                    this.getTableData();
                } else {
                    this.$message.error("删除用户失败！");
                }
            });
        },

        //切换表格每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
            console.log(`每页 ${val} 条`);
        },
        //切换表格当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
            console.log(`当前页: ${val}`);
        },
    },
};
</script>

<style>
.mainBox2 > .border-box-content {
    padding: 76px 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-size: 25px;
    font-weight: 700;
    grid-gap: 20px;
}
</style>

<style scoped>
.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.menu-item {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edebeb69;
    border-radius: 25px;
    width: 80%;
    height: 50px;
    font-size: 20px;
    transition: all 0.2s linear;
}
.menu-item:hover {
    transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -o-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    background-color: #edebeb90;
}
</style>
