<template>
    <div class="container">
        <div class="main">
            <div style="overflow: hidden"></div>
            <div class="register-main">
                <div class="register-main-content">
                    <div class="login-title">忘记密码</div>

                    <div class="register-input">
                        <el-form
                            ref="form"
                            label-width="80px"
                            label-position="top"
                            size="medium"
                            :rules="rules"
                            :model="addForm"
                        >
                            <el-form-item label="邮箱:" prop="useremail">
                                <el-input
                                    placeholder="您绑定的邮箱"
                                    v-model="addForm.useremail"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-postcard"
                                    ></i>
                                </el-input>
                            </el-form-item>

                            <el-form-item
                                label="验证码:"
                                prop="validationEmailCode"
                            >
                                <div class="sidentifyContent">
                                    <el-input
                                        placeholder="请输入验证码"
                                        v-model="addForm.validationEmailCode"
                                    >
                                        <i
                                            slot="prefix"
                                            class="
                                                el-input__icon
                                                el-icon-s-claim
                                            "
                                        ></i>
                                    </el-input>
                                    <el-button
                                        style="width: 100%; font-size: 18px"
                                        type="danger"
                                        :disabled="IsDisabled"
                                        @click="ForgetPosswordConfirm"
                                        >{{ ButtonName }}</el-button
                                    >
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="密码:"
                                v-if="show"
                                prop="passWord"
                            >
                                <el-input
                                    placeholder="请输入您的密码"
                                    type="password"
                                    v-model="addForm.passWord"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-s-custom"
                                    ></i>
                                </el-input>
                            </el-form-item>

                            <el-form-item
                                label="确认密码:"
                                v-if="show"
                                prop="repassWord"
                            >
                                <el-input
                                    placeholder="再次输入密码"
                                    type="password"
                                    v-model="addForm.repassWord"
                                >
                                    <i
                                        slot="prefix"
                                        class="
                                            el-input__icon
                                            el-icon-user-solid
                                        "
                                    ></i>
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="login-main-right-button">
                        <el-button
                            style="width: 100%; font-size: 18px"
                            type="danger"
                            @click="validationEmail"
                            >修改密码</el-button
                        >
                        <div style="margin-top: 10px">
                            <span style="margin-left: 220px">
                                <a href="/">返回登陆</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class=""></div>
            <div class="footer">
                <div class="info">版权所有：&copy; COSMAP</div>
                <div class="info">中丨English丨日本語</div>
            </div>
        </div>
    </div>
</template>
<script>
import { SendEmailToPasswordForget, GetPassWord } from "../api/api";
import Sidentify from "../components/Sidentify"; //**引入验证码组件**
export default {
    components: {
        "v-sidentify": Sidentify,
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.addForm.repassWord !== "") {
                    this.$refs.addForm.validateField("repassWord");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.addForm.passWord) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            show: false,
            addForm: {
                useremail: null,
                validationEmailCode: "",
                passWord: "",
                repassWord: "",
            },

            rules: {
                useremail: [
                    {
                        required: true,
                        message: "邮箱不能为空",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern:
                            /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                        message: "邮箱格式异常",
                        trigger: "blur",
                    },
                ],

                validationEmailCode: [
                    {
                        required: true,
                        message: "验证码不能为空",
                        trigger: "blur",
                    },
                ],
                passWord: [
                    {
                        required: true,
                        validator: validatePass,
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 16,
                        message: "密码必须为6-16位",
                        trigger: "blur",
                    },
                ],
                repassWord: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
            },
            ButtonName: "发送验证码",
            Time: 60,
            IsDisabled: false,
        };
    },
    methods: {
        //最终提交表单
        validationEmail() {
            let ForgotPasswordFinalForm = {
                Email: this.addForm.useremail,
                Code: this.addForm.validationEmailCode,
                password: this.addForm.passWord,
                repassWord: this.addForm.repassWord,
            };
            GetPassWord(ForgotPasswordFinalForm).then((res) => {
                if (res == "密码已修改") {
                    this.$message({
                        message: "密码已修改",
                        type: "success",
                    });
                } else {
                    this.$message.error({
                        message: res,
                    });
                }
            });
        },

        //点击发送验证码按钮
        ForgetPosswordConfirm() {
            //验证邮箱是否正确，正确之后再发送验证码
            this.$refs.form.validateField("useremail", (message) => {
                if (message) {
                    this.$message.error(message);
                    return;
                } else {
                    this.show = true;
                    let me = this;
                    me.IsDisabled = true;
                    //60s倒计时
                    let interval = window.setInterval(function () {
                        me.ButtonName = "（" + me.Time + "秒）后重新发送";
                        --me.Time;
                        if (me.Time < 0) {
                            me.ButtonName = "重新发送";
                            me.Time = 60;
                            me.IsDisabled = false;
                            window.clearInterval(interval);
                        }
                    }, 1000);
                    var parms = {
                        Email: this.addForm.useremail,
                    };
                    //验证成功，发送验证码
                    SendEmailToPasswordForget(parms).then((res) => {
                        if (res == true) {
                            this.$message({
                                message: "已将验证码发送到您的邮箱",
                                type: "success",
                            });
                        } else {
                            this.$message.error({
                                message: "发送失败，请联系管理员",
                            });
                        }
                    });
                }
            });
        },
    },
};
</script>
<style scoped>
.main {
    background-color: #4780ff;
    height: 60vh;
    background-image: url("../assets/loginBackground.png");
    background-size: 100% 100%;
}
.main .register-main {
    margin: 10vh auto 5vh auto;
    padding-bottom: 10px;
    background-color: white;

    width: 900px;
    border-radius: 2px;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
    min-height: 500px;
}

.register-main-content {
    margin: 0 auto;

    width: 500px;
    height: 100%;

    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.login-main-right-button {
    width: 100%;
    height: 70px;
    margin-bottom: 30px;
}

.login-main-right-button a {
    color: #8492a6;
    font-size: 15px;
}
.register-input {
    min-height: 190x;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.login-title {
    padding-top: 30px;

    text-align: center;
    color: #f56c6c;
    font-size: 26px;
    font-weight: 550;
    border-bottom: 1px solid #f56c6c;
    height: 45px;
    margin-bottom: 10px;
}
.sidentifyContent {
    display: flex;
    flex-direction: row;
}
.sidentifyContent .el-input__icon {
    height: auto;
}
.footer {
    background-color: white;
    text-align: center;

    height: 60px;
}
.footer .info {
    font-size: 15px;
    color: #8492a6;
    width: 200px;
    margin: 0 auto;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>