<template>
    <div>
        <el-divider content-position="left">HYDROGEN (MHYDRO)</el-divider>
        <el-form>
            <el-row :gutter="20">
                <el-col :span="3">
                    <el-form-item label="TH">
                        <el-input
                            v-model="
                                $store.state.ProcessProperties.Hydrogen.THNumber
                            "
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-form
            v-for="(item, index) in $store.state.ProcessProperties.Hydrogen.TH"
            :key="'root' + index"
            label-position="top"
        >
            <el-card style="margin-bottom: 5px">
                <div slot="header" class="clearfix">
                    <span>TH Index：{{ index + 1 }}</span>
                    <el-button
                        v-if="PopButtonShow(index)"
                        @click="HydrogenPop"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >减少数据</el-button
                    >
                    <el-button
                        v-if="AddButtonShow(index)"
                        @click="HydrogenAdd"
                        style="float: right; padding: 3px 3px"
                        type="text"
                        >添加数据</el-button
                    >
                </div>
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label="Time:"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Concentration"> </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="20"
                        v-for="(
                            concentration, concentrationIndex
                        ) in item.TimeConcentration"
                        :key="'sub1' + concentrationIndex"
                    >
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="concentration.Time"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="concentration.Temperature"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="TimeConcentrationAdd(index)"
                        >添加数据</el-button
                    >
                    <el-button @click="TimeConcentrationPop(index)"
                        >减少数据</el-button
                    >
                </el-card>
                <br />
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label="Start Node:"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="End Node"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Increment:"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Number of the time function">
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row
                        :gutter="20"
                        v-for="(node, nodeIndex) in item.NodeInfoFirstPattern"
                        :key="'sub2' + nodeIndex"
                    >
                        <el-col :span="3">
                            <el-form-item>
                                <el-input v-model="node.StartNode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input v-model="node.EndNode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.NodeInterval"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.TimeFunctionNumber"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="NodeInfoFirstPatternAdd(index)"
                        >添加数据</el-button
                    >
                    <el-button @click="NodeInfoFirstPatternPop(index)"
                        >减少数据</el-button
                    >
                </el-card>

                <br />
                <el-card shadow="hover">
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label="Node number:"> </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="Number of the time function">
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row
                        :gutter="20"
                        v-for="(node, nodeIndex) in item.NodeInfoSecondPattern"
                        :key="'sub3' + nodeIndex"
                    >
                        <el-col :span="3">
                            <el-form-item>
                                <el-input v-model="node.NodeNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-input
                                    v-model="node.FunctionNumber"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button @click="NodeInfoSecondPatternAdd(index)"
                        >添加数据</el-button
                    >
                    <el-button @click="NodeInfoSecondPatternPop(index)"
                        >减少数据</el-button
                    >
                </el-card>
            </el-card>
        </el-form>
    </div>
</template>
<script>
import processTable from "../components/processTable";
export default {
    components: {
        processTable,
    },
    computed: {},
    data() {
        return {
            activeTab: "first",
            // ProcessProperties: this.$store.state.ProcessProperties,
        };
    },
    mounted() {},
    methods: {
        HydrogenAdd() {
            this.$store.commit("HydrogenAdd", "");
        },
        HydrogenPop() {
            this.$store.commit("HydrogenPop", "");
        },
        TimeConcentrationAdd(index) {
            this.$store.commit("TimeConcentrationAdd", index);
        },
        TimeConcentrationPop(index) {
            this.$store.commit("TimeConcentrationPop", index);
        },
        NodeInfoFirstPatternAdd(index) {
            this.$store.commit("NodeInfoFirstPatternAdd", index);
        },
        NodeInfoFirstPatternPop(index) {
            this.$store.commit("NodeInfoFirstPatternPop", index);
        },
        NodeInfoSecondPatternAdd(index) {
            this.$store.commit("NodeInfoSecondPatternAdd", index);
        },
        NodeInfoSecondPatternPop(index) {
            this.$store.commit("NodeInfoSecondPatternPop", index);
        },
        AddButtonShow(index) {
            return (
                index ==
                this.$store.state.ProcessProperties.Hydrogen.TH.length - 1
            );
        },
        PopButtonShow(index) {
            return (
                index ==
                    this.$store.state.ProcessProperties.Hydrogen.TH.length -
                        1 && index != 0
            );
        },
    },
};
</script>
