<template>
    <dv-border-box-1 class="mainBox" dur="5">
        <div
            class="outFlex"
            @click="toMaterial"
            v-if="!(accessList.indexOf('材料库') == -1)"
        >
            <div class="mainMenu">
                <img
                    src="../assets/mainMenuIcon2.png"
                    width="110px"
                    height="110px"
                />
                <div class="mainMenu-text">
                    {{ $t("lang.materialManagement") }}
                </div>
            </div>
        </div>

        <div class="outFlex mid" v-if="!(accessList.indexOf('材料库') == -1)">
            <img src="../assets/arrow.gif" width="120px" height="120px" />
        </div>
        <div
            class="outFlex"
            @click="toCosmap"
            v-if="!(accessList.indexOf('COSMAP') == -1)"
        >
            <div class="mainMenu">
                <img
                    src="../assets/mainMenuIcon2.png"
                    width="110px"
                    height="110px"
                />
                <div class="mainMenu-text">
                    {{ $t("lang.cosmap") }}
                </div>
            </div>
        </div>
        <div class="outFlex mid" v-if="!(accessList.indexOf('COSMAP') == -1)">
            <div class="arrow">
                <img src="../assets/arrow.gif" width="120px" height="120px" />
            </div>
        </div>

        <div
            class="outFlex"
            @click="toHeatTreatment"
            v-if="!(accessList.indexOf('热处理优化系统') == -1)"
        >
            <div class="mainMenu">
                <img
                    src="../assets/mainMenuIcon2.png"
                    width="110px"
                    height="110px"
                />
                <div class="mainMenu-text">
                    {{ $t("lang.heatTreatmentOptimizationSystem") }}
                </div>
            </div>
        </div>
        <div class="outFlex arrow0">
            <img src="../assets/arrow2.gif" width="162px" height="120px" />
        </div>
        <div class="outFlex arrow1">
            <img src="../assets/arrow3.gif" width="105px" height="129px" />
        </div>
        <div class="outFlex arrow2">
            <img src="../assets/arrow3.gif" width="105px" height="129px" />
        </div>
        <div class="outFlex arrow3">
            <img src="../assets/arrow2.gif" width="162px" height="120px" />
        </div>

        <div class="outFlex arrow4">
            <img src="../assets/arrow3.gif" width="105px" height="129px" />
        </div>
        <div
            class="outFlex"
            v-if="!(accessList.indexOf('热处理优化系统') == -1)"
        >
            <div class="mainMenu">
                <img
                    src="../assets/mainMenuIcon2.png"
                    width="110px"
                    height="110px"
                />
                <div class="mainMenu-text">
                    {{ $t("lang.modelManagement") }}
                </div>
            </div>
        </div>
        <div class="outFlex mid"></div>
        <div
            class="outFlex"
            @click="toOptimization"
            v-if="!(accessList.indexOf('工艺优化库') == -1)"
        >
            <div class="mainMenu">
                <img
                    src="../assets/mainMenuIcon2.png"
                    width="110px"
                    height="110px"
                />
                <div class="mainMenu-text">
                    {{ $t("lang.optimizeManagement") }}
                </div>
            </div>
        </div>

        <div class="outFlex mid"></div>
        <div
            class="outFlex"
            @click="toEquipment"
            v-if="!(accessList.indexOf('设备库') == -1)"
        >
            <div class="mainMenu">
                <img
                    src="../assets/mainMenuIcon2.png"
                    width="110px"
                    height="110px"
                />
                <div class="mainMenu-text">
                    {{ $t("lang.equipmentManagement") }}
                </div>
            </div>
        </div>

        <div class="footer">
            <div class="">
                <span>{{ $t("lang.copyright") }}&nbsp;:&nbsp;&copy;COSMAP</span>
            </div>
        </div>
    </dv-border-box-1>
</template>

<script>
export default {
    name: "Home",
    mounted() {
        this.accessInit();
    },
    data() {
        return {
            accessList: [],
            userType: "",
        };
    },
    methods: {
        //回到首页
        toLogin() {
            this.$router.push({ path: "/" });
        },
        //Cosmap
        toCosmap() {
            this.$router.push({ path: "/home/Calculate" });
        },
        //设备库
        toEquipment() {
            this.$router.push({ path: "/home/EquipmentManagement" });
        },
        //材料库
        toMaterial() {
            this.$router.push({ path: "/newhome/MaterialManagement" });
        },
        //工艺优化库
        toOptimization() {
            this.$router.push({ path: "/home/OptimizationManagement" });
        },
        //热处理优化
        toHeatTreatment() {
            this.$router.push({ path: "/home/HeatTreatmentOptimization" });
        },
        //用户管理
        toUserManage() {
            this.$router.push({ path: "/home/Usermanage" });
        },
        accessInit() {
            let accessStr = localStorage.getItem("access");
            this.accessList = accessStr.split(",");

            this.userType = localStorage.getItem("type");
            console.log(this.userType);
        },
    },
};
</script>
<style >
.dv-border-box-1 .border-box-content {
    padding: 37px 10px 10px 27px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 0.2fr; */
    font-size: 23px;
    font-weight: 700;
}
</style>
<style scoped>
.mainContainer {
    font-size: 20px;
    background-image: url("../assets/mainBackground.png");
    background-size: 100% 100%;
    -webkit-box-shadow: 0 0 3px #00f;
    box-shadow: 0 0 3px #00f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.mainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 341px;
    flex-direction: column;
    height: 205px;
    /* background-color: #edebeb69; */
    /* border-radius: 25px; */
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-image: url("../assets/homeItem.png");
    background-size: 100% 100%;
    transition: all 0.3s linear;
}

.mainMenu-text {
    text-align: center;
    width: 200px;
}

.mainMenu:hover {
    /* transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -o-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px); */
    /* background-color: #edebeb90; */
    /*善解人意的分割线*/
    /* transition: width 2s, height 2s, transform 2s; */
    transform: scale(1.1);
}

.outFlex {
    /* position: absolute; */
    display: flex;
    width: 26%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.mid {
    width: 10%;
}
.arrow0 {
    width: 35%;
    display: flex;
    justify-content: flex-end;
}

.arrow0 img {
    -moz-transform: scaleX(-1) scaleY(-1);
    -o-transform: scaleX(-1) scaleY(-1);
    -webkit-transform: scaleX(-1) scaleY(-1);
    transform: scaleX(-1) scaleY(-1);
}
.arrow1 {
    width: 12%;
    display: flex;
    justify-content: flex-end;
}
.arrow2 {
    width: 10%;
    display: flex;
    justify-content: flex-end;
}

.arrow2 img {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.arrow3 {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}
.arrow4 {
    width: 25%;
    display: flex;
    justify-content: center;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}
.footer {
    flex-grow: 1;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* grid-column-start: span 3; */
}
</style>
