<template>
	<div>
		<el-divider content-position="left"
			>The Control Date Of The Subprocess</el-divider
		>

		<el-form
			v-for="(item, index) in $store.state.ProcessProperties.Subprocess"
			:key="index"
			label-position="top"
		>
			<el-card style="margin-bottom: 5px">
				<div slot="header" class="clearfix">
					<span>Division Of The Subprocess：{{ index + 1 }}</span>
					<el-button
						v-if="PopButtonShow(index)"
						@click="SubprocessPop"
						style="float: right; padding: 3px 3px"
						type="text"
						>减少数据</el-button
					>
					<el-button
						v-if="AddButtonShow(index)"
						@click="SubprocessAdd"
						style="float: right; padding: 3px 3px"
						type="text"
						>添加数据</el-button
					>
				</div>
				<el-row :gutter="20">
					<el-col :span="3">
						<el-form-item label="Division Of The Subprocess(MSPRO)">
							<el-input v-model="item.MSPRO"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Elememt(MELM)">
							<el-radio-group v-model="item.MELM.Dimensions">
								<el-radio label="0">Two Dimensions</el-radio>
								<el-radio label="1">Three Dimensions</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item>
							<el-checkbox-group v-model="item.MELM.Options">
								<el-checkbox
									label="Heat Transmission"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Diffusion"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Radiation"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Force & Displacement"
									name="type"
								></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-form-item label="Diffusion(MDIFC)">
							<el-checkbox-group v-model="item.MDIFC">
								<el-checkbox
									label="Carbon"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Nitrogen"
									name="type"
								></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-form-item label="transformation(MTRANC)">
							<el-checkbox-group v-model="mtranc">
								<el-checkbox
									label="Diffusion"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="No Diffusion"
									name="type"
								></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Heat Conduction(MHEATC)">
							<el-checkbox-group v-model="item.MHEATC">
								<el-checkbox
									label="External Heat"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Latent Heat"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Heat (Dynamic)"
									name="type"
								></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="Strain(MEPSC)">
							<el-checkbox-group v-model="item.MEPSC">
								<el-checkbox
									label="Elasticity"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Plasticity"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Heat"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Transformation"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Transformation plasticity"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Creep"
									name="type"
								></el-checkbox>
								<el-checkbox
									label="Diffusion"
									name="type"
								></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
		</el-form>
	</div>
</template>
<script>
import processTable from '../components/processTable';
export default {
	components: {
		processTable,
	},
	computed: {},
	data() {
		return {
			activeTab: 'first',
			mtranc:[]
			// ProcessProperties: this.$store.state.ProcessProperties,
		};
	},
	mounted() {},
	methods: {
		SubprocessAdd() {
			this.$store.commit('SubprocessAdd', '');
		},
		SubprocessPop() {
			this.$store.commit('SubprocessPop', '');
		},
		AddButtonShow(index) {
			return (
				index ==
				this.$store.state.ProcessProperties.Subprocess.length - 1
			);
		},
		PopButtonShow(index) {
			return (
				index ==
					this.$store.state.ProcessProperties.Subprocess.length - 1 &&
				index != 0
			);
		},
	},
};
</script>
