<template>
    <div class="container">
        <el-tabs
            v-model="activeName"
            style="height: 100%; color: black; min-height: 60vh"
            class="processMenu"
        >
            <el-tab-pane
                label="上传材料文件"
                name="upload"
                style="text-align: center"
            >
                <div style="margin-top: 20vh"></div>
                <el-upload
                    class="upload-demo"
                    drag
                    action="https://jsonplaceholder.typicode.com/posts/"
                    multiple
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        可将材料物性文件拖拽到此处，或<em>点击上传</em>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传.dat文件
                    </div>
                </el-upload>
            </el-tab-pane>
            <el-tab-pane label="01 Main" name="first">
                <main1 />
            </el-tab-pane>

            <el-tab-pane label="02 Main" name="second">
                <main2 />
            </el-tab-pane>

            <el-tab-pane label="03 Subprocess" name="third">
                <subprocess />
            </el-tab-pane>

            <el-tab-pane label="04 HTC" name="fourth">
                <htc />
            </el-tab-pane>

            <el-tab-pane label="05 Temp" name="fifth">
                <temp />
            </el-tab-pane>

            <el-tab-pane label="06 RADIATION" name="sixth">
                <radiation />
            </el-tab-pane>

            <el-tab-pane label="07 LOAD" name="seventh">
                <load />
            </el-tab-pane>

            <el-tab-pane label="08 DEFORMATION" name="eighth">
                <deformation />
            </el-tab-pane>

            <el-tab-pane label="09 DEFUSSION" name="ninth">
                <defussion />
            </el-tab-pane>

            <el-tab-pane label="10 HYDROGEN" name="tenth">
                <hydrogen />
            </el-tab-pane>
        </el-tabs>

        <el-dialog
			title="添加工艺参数"
			:visible.sync="addDialogFormVisible"
			:append-to-body="true"
		>
			<el-form :model="addForm">
				<el-form-item label="名称" :label-width="formLabelWidth">
					<el-input
						v-model="addForm.name"
						autocomplete="off"
						placeholder="请输入工艺参数名称"
					></el-input>
				</el-form-item>
				<el-form-item label="简介" :label-width="formLabelWidth">
					<el-input
						v-model="addForm.introduction"
						autocomplete="off"
						placeholder="请输入简介"
					></el-input>
				</el-form-item>
				<el-form-item label="类别" :label-width="formLabelWidth">
					<el-select
						v-model="addForm.type"
						placeholder="请输入工艺参数类别"
					>
						<el-option label="类别1" value="类别1"></el-option>
						<el-option label="类别2" value="类别2"></el-option>
						<el-option label="类别3" value="类别3"></el-option>
						<el-option
							label="智能制造1"
							value="智能制造1"
						></el-option>
						<el-option
							label="智能制造2"
							value="智能制造2"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addDialogFormVisible = false"
					>取 消</el-button
				>
				<el-button type="primary" @click="saveToServer()"
					>确 定</el-button
				>
			</div>
		</el-dialog>

        <div style="width: 100%; text-align: center">
            <el-button type="success" icon="el-icon-download"
                >保存到本地</el-button
            >
            <el-button
                type="primary"
                icon="el-icon-upload"
                @click="MakeFormVisible()"
                >保存到云端</el-button
            >
            <el-button>重置</el-button>
        </div>
        
        
    </div>
</template>
<script>
// import {} from "@vue/composition-api";

import main1 from "./main1/index.vue";
import main2 from "./main2/index.vue";
import subprocess from "./subprocess/index.vue";
import htc from "./htc/index.vue";
import radiation from "./radiation/index.vue";
import temp from "./temp/index.vue";
import load from "./load/index.vue";
import deformation from "./deformation/index.vue";
import defussion from "./defussion/index.vue";
import hydrogen from "./hydrogen/index.vue";

import { SaveProcessPropertiesToServer,AddProcessProperties } from "../../../api/api";

export default {
    data() {
        return {
            addForm: {
				name: '',
				introduction: '',
				filePath: '',
				type: '',
			},
            activeName: "eighth",
            addDialogFormVisible: false,
            formLabelWidth: '120px',
        };
    },
    components: {
        main1,
        main2,
        subprocess,
        htc,
        radiation,
        temp,
        load,
        deformation,
        defussion,
        hydrogen,
    },
    mounted() {},
    methods: {
        MakeFormVisible(){
            this.addDialogFormVisible=true;//将表单设置为可见
        },
        saveToServer() {
            this.addDialogFormVisible=false;
            var data = this.$store.state.ProcessProperties;
            SaveProcessPropertiesToServer(data).then((res) => {
                this.AddToList(res)
                // this.tableData = res.data;
                // this.totalNumber = res.count;
            });
        },
        AddToList(path){
            //InputFileInformation();
            this.addForm.filePath = path;
			AddProcessProperties(this.addForm).then((res) => {
				if (res == true) {
					this.$message({
						message: '添加工艺成功！',
						type: 'success',
					});
				} else {
					this.$message.error('添加工艺失败！');
				}
			});
        },
        /*InputFileInformation(){
                this.$prompt('请输入邮箱', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    }).then(({ value }) => {
                        this.addForm.name=value;
                    }).catch(() => {
                this.$message({
                        type: 'info',
                        message: '取消输入'
                    });       
            })
        }*/
    }
}
</script>
<style scoped>
.processMenu >>> .el-form-item__label {
    font-size: 16px;
    min-height: 40px;
    text-align: left;
    line-height: 15px;
    padding: 0;
}
.processMenu >>> .el-form-item {
    margin-bottom: 15px;
}
</style>
