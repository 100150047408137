<template>
	<div>
		<el-tabs v-model="activeTab" style=" color: black; min-height: 60vh">
			<el-tab-pane
				label="Temper(STEMPER)"
				name="first"
				style="text-align: center"
			>
				<el-divider content-position="left">Temper(STEMPER)</el-divider>
				<processTable
					:parentTableData="$store.state.ProcessProperties.STEMPER"
				/>
			</el-tab-pane>
			<el-tab-pane
				label="Control Function(TEMPSP)"
				name="second"
				style="text-align: center"
			>
				<el-divider content-position="left"
					>Control Function(TEMPSP)</el-divider
				>
				<processTable
					:parentTableData="$store.state.ProcessProperties.TEMPSP"
				/>
			</el-tab-pane>
			<el-tab-pane
				label="Convergence Control(MCONT)"
				name="third"
				style="text-align: center"
			>
				<el-divider content-position="left"
					>Convergence Control(MCONT)</el-divider
				>
				<el-form>
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="The type of solver">
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.SolverType
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item
								label="The ICCG convergence decision value"
							>
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.NameICCGConvergenceDecision
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item
								label="Maximum bandwidth adjustment factor"
							>
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.MaximumBandwidthAdjustmentFactor
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="Bandwidth adjustment factor">
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.BandwidthAdjustmentFactor
									"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<el-divider content-position="left"
					>The Recalculation</el-divider
				>
				<el-form>
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="Recalculation">
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.Recalculation
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="The calculation frequency">
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.CalculationFrequency
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item
								label="Permission maximum value of the serration number"
							>
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.PermissionMaximumValueOfTheSerrationNumber
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item
								label="The hourly width reduction rate"
							>
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.HourlyWidthReductionRate
									"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>

				<el-divider content-position="left"
					>The diffusion calculation</el-divider
				>
				<el-form>
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="The repetition maximum">
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.RepetitionMaximum
									"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item
								label="The convergence decision value"
							>
								<el-input
									v-model="
										$store.state.ProcessProperties.MCONT
											.ConvergenceDecision
									"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
import processTable from '../components/processTable';
export default {
	components: {
		processTable,
	},
	computed: {
		// ProcessProperties: {
		// 	get() {
		// 		return this.$store.state.ProcessProperties;
		// 	},
		// 	set(value) {
		// 		// this.$store.commit('updateProcessProperties', value);
		// 	},
		// },
	},
	data() {
		return {
			activeTab: 'first',
			// ProcessProperties: this.$store.state.ProcessProperties,
		};
	},
	mounted() {},
	methods: {
		ProcessPropertiesChange(a) {
			// this.$store.commit(
			// 	'updateProcessProperties',
			// 	this.$store.state.ProcessProperties
			// );

			this.$store.commit('aaa', '');
		},
	},
};
</script>
