import Vue from 'vue';
import Vuex from 'vuex';

//挂载Vuex
Vue.use(Vuex);

//创建VueX对象
const store = new Vuex.Store({
    state: {
        openTab: [], //所有打开的路由
        activeIndex: '/main', //激活状态

        ProcessProperties: {
            ProcessNumber: '123',
            SHEAT: [{
                No: '0001',
                Time: '1234',
                StepNumber: 10,
                OutputInterval: 5,
            }, ],
            SDIFP: [{
                No: '2222',
                Time: '2345',
                StepNumber: 10,
                OutputInterval: 5,
            }, ],
            SQUEN: [{
                No: '3333',
                Time: '6789',
                StepNumber: 10,
                OutputInterval: 5,
            }, ],
            STEMPER: [{
                No: '3333',
                Time: '6789',
                StepNumber: 10,
                OutputInterval: 5,
            }, ],
            TEMPSP: [{
                No: '3333',
                Time: '6789',
                StepNumber: 10,
                OutputInterval: 5,
            }, ],
            MCONT: {
                SolverType: '1',
                ICCGConvergenceDecision: '',
                MaximumBandwidthAdjustmentFactor: '',
                BandwidthAdjustmentFactor: '',
                Recalculation: '',
                CalculationFrequency: '',
                PermissionMaximumValueOfTheSerrationNumber: '',
                HourlyWidthReductionRate: '',
                RepetitionMaximum: '',
                ConvergenceDecision: '',
            },
            Subprocess: [{
                MSPRO: '',
                MELM: {
                    Dimensions: '',
                    Options: [],
                },
                MDIFC: [],
                MHEATC: [],
                MEPSC: [],
            }, ],
            HTC: {
                Coolant: '',
                BoundaryNumber: 1,
                Boundary: [{
                    BoundaryIndex: '',
                    AmbientTemperature: '',
                    TemperatureList: [{
                        Temperature: '',
                        HeatTransferCoefficient: '',
                    }, ],
                }, ],
            },
            TEMP: {
                TemperatureOrderName: '',
                THNumber: 1,
                TH: [{
                    TimeTemperature: [{
                        Time: '',
                        Temperature: '',
                    }, ],
                    NodeInfo: [{
                        StartNode: '',
                        EndNode: '',
                        NodeInterval: '',
                        TimeFunctionNumber: '',
                    }, ],
                }, ],
            },
            RADIATION: {
                RadiationMediumName: '',
                BoundaryNumber: 1,
                Boundary: [{
                    BoundaryIndex: '',
                    AmbientTemperature: '',
                    TemperatureList: [{
                        Temperature: '',
                        RadiationCoefficient: '',
                    }, ],
                }, ],
            },
            LOAD: {
                LoadBoundaryNumber: '',
                LOADList: [{
                    TimeLoad: [{
                        Time: '',
                        Load: '',
                    }, ],
                    NodeInfo: [{
                        Node: '',
                        DirectionTimeFunctionX: '',
                        DirectionTimeFunctionY: '',
                        DirectionTimeFunctionZ: '',
                        LoadFactorX: '',
                        LoadFactorY: '',
                        LoadFactorZ: '',
                    }, ],
                }, ],
            },
            Deformation: {
                DeformationBoundaryNumber: '',
                DeformationList: [{
                    TimeDeformation: [{
                        Time: '',
                        Deformation: '',
                    }, ],
                    NodeInfo: [{
                        Node: '',
                        DirectionTimeFunctionX: '',
                        DirectionTimeFunctionY: '',
                        DirectionTimeFunctionZ: '',
                        CoefficientDisplacementX: '',
                        CoefficientDisplacementY: '',
                        CoefficientDisplacementZ: '',
                    }, ],
                }, ],
            },
            DIFUSSION: {
                MSUFDC: {
                    SurfaceDiffusionCoefficient: '',
                    SufaceCircumstanceConcentration: '',
                },
                MSUFDN: {
                    SurfaceDiffusionCoefficient: '',
                    SufaceCircumstanceConcentration: '',
                },
                MSUFDO: {
                    SurfaceDiffusionCoefficient: '',
                    SufaceCircumstanceConcentration: '',
                },
            },
            Hydrogen: {
                THNumber: 1,
                TH: [{
                    TimeConcentration: [{
                        Time: '',
                        Concentration: '',
                    }, ],
                    NodeInfoFirstPattern: [{
                        StartNode: '',
                        EndNode: '',
                        NodeInterval: '',
                        TimeFunctionNumber: '',
                    }, ],
                    NodeInfoSecondPattern: [{
                        NodeNumber: '',
                        FunctionNumber: '',
                    }, ],
                }, ],
            },
        },
        MaterialProperties: {
            Material: {
                MaterialName: '',
                MaterialCharacteRistic: {
                    Diffusion: '',
                    Transformation: '',
                    Heat: '',
                    Inelasticity: '',
                    Crystal: ''
                },
                MaterialElement: {
                    Fe: '',
                    C: '',
                    Si: '',
                    Mn: '',
                    Cr: '',
                    Mg: '',
                    Zn: '',
                    Al: '',
                    Ni: '',
                    Mo: '',
                    V: '',
                    Cu: '',
                    N: '',
                    P: '',
                    S: '',
                    Ti: '',
                    W: '',
                }
            },
            DiffsionCoefficient: {
                PhaseNumber: '',
                DataFormat: '',
                DiffusionElementNumber: '',
                Phase: '',
                DiffusionElement: '',
                FuctionalType: {
                    Concentration: '',
                    D0: '',
                    D1: '',
                    D2: '',
                    D3: '',
                    D4: '',
                },
                DiscretionType: {
                    Concentration: '',
                    Temperature: '',
                    DiffusionCoeffcient: '',
                },
            },
            ToAusenite_PT: {
                MHTRN: '',
                FUNCT: '',
                f11: {
                    Coefficienta0: '',
                    StartTemperaturea1: '',
                    Coefficienta2: '',
                    Coefficienta3: '',
                    EndTemperaturea4: '',
                    Coefficienta5: '',
                    Coefficienta6: '',
                },
                TTTF: {
                    Temperature: '',
                    Time: '',
                }
            },
            ToDiffuse_PT: {
                MQTRD: '',
                FUNCT: '',
                f11: {
                    Coefficienta0: '',
                    StartTemperaturea1: '',
                    Coefficienta2: '',
                    Coefficienta3: '',
                    EndTemperaturea4: '',
                    Coefficienta5: '',
                    Coefficienta6: '',
                },
                CCTF: {
                    Temperature: '',
                    Time: '',
                    CollingSpeed: '',
                },
                TTTF: {
                    Temperature: '',
                    Time: '',
                },
            },
            ToMartensite_PT: {
                MQTRN: '',
                KMMOD: {
                    InitialValue: '',
                    A: '',
                    B: '',
                    K: '',
                },
                MGMOD: {
                    K: '',
                    MS: '',
                    A: '',
                    B: '',
                    F: '',
                    G: '',
                },
            },
        },
    },
    mutations: {
        // 添加主页tabs
        add_tabs(state, data) {
            this.state.openTab.push(data);
        },
        // 删除主页tabs
        delete_tabs(state, route) {
            let index = 0;
            for (let option of state.openTab) {
                if (option.route === route) {
                    break;
                }
                index++;
            }
            this.state.openTab.splice(index, 1);
        },
        // 设置当前激活的tab
        set_active_index(state, index) {
            this.state.activeIndex = index;
        },

        //更新工艺参数文件缓存
        updateProcessProperties(state, data) {
            this.state.ProcessProperties = data;
        },

        //更新工艺参数文件热处理过程数
        updateProcessNumber(state, data) {
            this.state.ProcessProperties.ProcessNumber = data;
        },

        SubprocessAdd() {
            this.state.ProcessProperties.Subprocess.push({
                MSPRO: '',
                MELM: {
                    Dimensions: '',
                    Options: [],
                },
                MDIFC: [],
                MHEATC: [],
                MEPSC: [],
            });
        },
        SubprocessPop() {
            this.state.ProcessProperties.Subprocess.pop();
        },
        //HTC
        HTCAdd() {
            this.state.ProcessProperties.HTC.TH.push({
                BoundaryIndex: '',
                AmbientTemperature: '',
                TemperatureList: [{
                    Temperature: '',
                    HeatTransferCoefficient: '',
                }, ],
            });
            this.state.ProcessProperties.HTC.BoundaryNumber = this.state.ProcessProperties.HTC.Boundary.length;
        },
        HTCPop() {
            this.state.ProcessProperties.HTC.Boundary.pop();
            this.state.ProcessProperties.HTC.BoundaryNumber = this.state.ProcessProperties.HTC.Boundary.length;
        },
        HTCBoundaryTemperatureListAdd(state, BoundaryIndex) {
            this.state.ProcessProperties.HTC.Boundary[
                BoundaryIndex
            ].TemperatureList.push({
                Temperature: '',
                HeatTransferCoefficient: '',
            });
        },
        HTCBoundaryTemperatureListPop(state, BoundaryIndex) {
            this.state.ProcessProperties.HTC.Boundary[
                BoundaryIndex
            ].TemperatureList.pop();
        },
        //TEMP
        TEMPAdd() {
            this.state.ProcessProperties.TEMP.TH.push({
                TimeTemperature: [{
                    Time: '',
                    Temperature: '',
                }, ],
                NodeInfo: [{
                    StartNode: '',
                    EndNode: '',
                    NodeInterval: '',
                    TimeFunctionNumber: '',
                }, ],
            });
            this.state.ProcessProperties.TEMP.THNumber = this.state.ProcessProperties.TEMP.TH.length;
        },
        TEMPPop() {
            this.state.ProcessProperties.TEMP.TH.pop();
            this.state.ProcessProperties.TEMP.THNumber = this.state.ProcessProperties.TEMP.TH.length;
        },
        TEMPTimeTemperatureListAdd(state, index) {
            this.state.ProcessProperties.TEMP.TH[index].TimeTemperature.push({
                Time: '',
                Temperature: '',
            });
        },
        TEMPTimeTemperatureListPop(state, index) {
            this.state.ProcessProperties.TEMP.TH[index].TimeTemperature.pop();
        },
        TEMPNodeInfoListAdd(state, index) {
            this.state.ProcessProperties.TEMP.TH[index].NodeInfo.push({
                StartNode: '',
                EndNode: '',
                NodeInterval: '',
                TimeFunctionNumber: '',
            });
        },
        TEMPNodeInfoListPop(state, index) {
            this.state.ProcessProperties.TEMP.TH[index].NodeInfo.pop();
        },
        //RADIATION
        RADIATIONAdd() {
            this.state.ProcessProperties.RADIATION.Boundary.push({
                BoundaryIndex: '',
                AmbientTemperature: '',
                TemperatureList: [{
                    Temperature: '',
                    RadiationCoefficient: '',
                }, ],
            });
        },
        RADIATIONPop() {
            this.state.ProcessProperties.RADIATION.Boundary.pop();
        },
        RADIATIONBoundaryTemperatureListAdd(state, BoundaryIndex) {
            this.state.ProcessProperties.RADIATION.Boundary[
                BoundaryIndex
            ].TemperatureList.push({
                Temperature: '',
                RadiationCoefficient: '',
            });
        },
        RADIATIONBoundaryTemperatureListPop(state, BoundaryIndex) {
            this.state.ProcessProperties.RADIATION.Boundary[
                BoundaryIndex
            ].TemperatureList.pop();
        },

        //LOAD
        LOADAdd() {
            this.state.ProcessProperties.LOAD.LOADList.push({
                TimeLoad: [{
                    Time: '',
                    Load: '',
                }, ],
                NodeInfo: [{
                    Node: '',
                    DirectionTimeFunctionX: '',
                    DirectionTimeFunctionY: '',
                    DirectionTimeFunctionZ: '',
                    LoadFactorX: '',
                    LoadFactorY: '',
                    LoadFactorZ: '',
                }, ],
            });
            this.state.ProcessProperties.LOAD.LoadBoundaryNumber = this.state.ProcessProperties.LOAD.LOADList.length;
        },
        LOADPop() {
            this.state.ProcessProperties.LOAD.LOADList.pop();
            this.state.ProcessProperties.LOAD.LoadBoundaryNumber = this.state.ProcessProperties.LOAD.LOADList.length;
        },
        LOADTimeLoadAdd(state, index) {
            this.state.ProcessProperties.LOAD.LOADList[index].TimeLoad.push({
                Time: '',
                TempLoad: '',
            });
        },
        LOADTimeLoadPop(state, index) {
            this.state.ProcessProperties.LOAD.LOADList[index].TimeLoad.pop();
        },
        LOADNodeInfoListAdd(state, index) {
            this.state.ProcessProperties.LOAD.LOADList[index].NodeInfo.push({
                Node: '',
                DirectionTimeFunctionX: '',
                DirectionTimeFunctionY: '',
                DirectionTimeFunctionZ: '',
                LoadFactorX: '',
                LoadFactorY: '',
                LoadFactorZ: '',
            });
        },
        LOADNodeInfoListPop(state, index) {
            this.state.ProcessProperties.LOAD.LOADList[index].NodeInfo.pop();
        },

        //Deformation
        DeformationAdd() {
            this.state.ProcessProperties.Deformation.DeformationList.push({
                TimeDeformation: [{
                    Time: '',
                    Deformation: '',
                }, ],
                NodeInfo: [{
                    Node: '',
                    DirectionTimeFunctionX: '',
                    DirectionTimeFunctionY: '',
                    DirectionTimeFunctionZ: '',
                    CoefficientDisplacementX: '',
                    CoefficientDisplacementY: '',
                    CoefficientDisplacementZ: '',
                }, ],
            });
            this.state.ProcessProperties.Deformation.DeformationBoundaryNumber = this.state.ProcessProperties.Deformation.DeformationList.length;
        },
        DeformationPop() {
            this.state.ProcessProperties.Deformation.DeformationList.pop();
            this.state.ProcessProperties.Deformation.DeformationBoundaryNumber = this.state.ProcessProperties.Deformation.DeformationList.length;
        },
        DeformationTimeDeformationAdd(state, index) {
            this.state.ProcessProperties.Deformation.DeformationList[
                index
            ].TimeDeformation.push({
                Time: '',
                DeformationLoad: '',
            });
        },
        DeformationTimeDeformationPop(state, index) {
            this.state.ProcessProperties.Deformation.DeformationList[
                index
            ].TimeDeformation.pop();
        },
        DeformationNodeInfoListAdd(state, index) {
            this.state.ProcessProperties.Deformation.DeformationList[
                index
            ].NodeInfo.push({
                Node: '',
                DirectionTimeFunctionX: '',
                DirectionTimeFunctionY: '',
                DirectionTimeFunctionZ: '',
                CoefficientDisplacementX: '',
                CoefficientDisplacementY: '',
                CoefficientDisplacementZ: '',
            });
        },
        DeformationNodeInfoListPop(state, index) {
            this.state.ProcessProperties.Deformation.DeformationList[
                index
            ].NodeInfo.pop();
        },

        //Hydrogen
        HydrogenAdd() {
            this.state.ProcessProperties.Hydrogen.TH.push({
                TimeConcentration: [{
                    Time: '',
                    Concentration: '',
                }, ],
                NodeInfoFirstPattern: [{
                    StartNode: '',
                    EndNode: '',
                    NodeInterval: '',
                    TimeFunctionNumber: '',
                }, ],
                NodeInfoSecondPattern: [{
                    NodeNumber: '',
                    FunctionNumber: '',
                }, ],
            });
            this.state.ProcessProperties.Hydrogen.THNumber = this.state.ProcessProperties.Hydrogen.TH.length;
        },
        HydrogenPop() {
            this.state.ProcessProperties.Hydrogen.TH.pop();
            this.state.ProcessProperties.Hydrogen.THNumber = this.state.ProcessProperties.Hydrogen.TH.length;
        },
        TimeConcentrationAdd(state, index) {
            this.state.ProcessProperties.Hydrogen.TH[
                index
            ].TimeConcentration.push({
                Time: '',
                Concentration: '',
            });
        },
        TimeConcentrationPop(state, index) {
            this.state.ProcessProperties.Hydrogen.TH[
                index
            ].TimeConcentration.pop();
        },
        NodeInfoFirstPatternAdd(state, index) {
            this.state.ProcessProperties.Hydrogen.TH[
                index
            ].NodeInfoFirstPattern.push({
                StartNode: '',
                EndNode: '',
                NodeInterval: '',
                TimeFunctionNumber: '',
            });
        },
        NodeInfoFirstPatternPop(state, index) {
            this.state.ProcessProperties.Hydrogen.TH[
                index
            ].NodeInfoFirstPattern.pop();
        },
        NodeInfoSecondPatternAdd(state, index) {
            this.state.ProcessProperties.Hydrogen.TH[
                index
            ].NodeInfoSecondPattern.push({
                NodeNumber: '',
                FunctionNumber: '',
            });
        },
        NodeInfoSecondPatternPop(state, index) {
            this.state.ProcessProperties.Hydrogen.TH[
                index
            ].NodeInfoSecondPattern.pop();
        },
    },
});

export default store;