<template>
    <div>
        <div>
            <el-col :span="24" class="toolbar" style="padding-bottom: 20px">
                <el-input
                    style="width: 200px"
                    placeholder="搜索名称"
                    v-model="queryStr"
                ></el-input
                >&nbsp;&nbsp;
                <el-button @click="refreshTable()">查询</el-button>
                <el-button type="danger" @click="refreshTable()"
                    >刷新列表</el-button
                >
                <el-button type="primary" @click="changeDialogFormVisible()"
                    >添加</el-button
                >
            </el-col>
            <el-table
                :data="tableData"
                style="min-height: 55vh; bacground-color: #ffffff60"
            >
                <el-table-column
                    prop="Id"
                    label="Id"
                    width="150"
                    :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="Name" label="单位名称" width="150">
                </el-table-column>
                <el-table-column
                    prop="Contacts"
                    label="联系人"
                    width="150"
                ></el-table-column>
                <el-table-column prop="Tel" label="联系方式" width="180">
                </el-table-column>
                <el-table-column prop="Address" label="通讯地址" width="180">
                </el-table-column>
                <el-table-column
                    prop="ValidityTime"
                    label="有效截止日期"
                    width="180"
                >
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleEdit(scope.row)"
                            type="text"
                            size="small"
                            >编辑</el-button
                        >
                        <el-button
                            @click="handleDelete(scope.row)"
                            type="text"
                            size="small"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNumber"
                ></el-pagination>
            </div>
        </div>

        <el-dialog
            title="添加单位"
            :visible.sync="addDialogFormVisible"
            :append-to-body="true"
        >
            <el-form :model="addForm">
                <el-form-item label="单位名称" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.Name"
                        autocomplete="off"
                        placeholder="请输入单位名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.Contacts"
                        autocomplete="off"
                        placeholder="请输入联系人"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.Tel"
                        autocomplete="off"
                        placeholder="请输入联系方式"
                    ></el-input>
                </el-form-item>
                <el-form-item label="通讯地址" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.Address"
                        autocomplete="off"
                        placeholder="请输入通讯地址"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用户数量" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.MaximumNumberOfUserss"
                        autocomplete="off"
                        placeholder="请输入单位最大用户数量"
                    ></el-input>
                </el-form-item>
                <el-form-item label="有效期" :label-width="formLabelWidth">
                    <el-date-picker
                        v-model="addForm.ValidityTime"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="权限管理" :label-width="formLabelWidth">
                    <el-checkbox-group v-model="addAccess">
                        <el-checkbox label="COSMAP" name="type"></el-checkbox>
                        <el-checkbox
                            label="热处理优化系统"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox
                            label="智能制造1"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox
                            label="智能制造2"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox
                            label="智能制造3"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="AddConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            title="编辑用户"
            :visible.sync="editDialogFormVisible"
            :append-to-body="true"
        >
            <el-form :model="editForm">
                <el-form-item label="单位名称" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.Name"
                        autocomplete="off"
                        placeholder="请输入单位名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.Contacts"
                        autocomplete="off"
                        placeholder="请输入联系人"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.Tel"
                        autocomplete="off"
                        placeholder="请输入联系方式"
                    ></el-input>
                </el-form-item>
                <el-form-item label="通讯地址" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.Address"
                        autocomplete="off"
                        placeholder="请输入通讯地址"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用户数量" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.MaximumNumberOfUserss"
                        autocomplete="off"
                        placeholder="请输入单位最大用户数量"
                    ></el-input>
                </el-form-item>
                <el-form-item label="有效期" :label-width="formLabelWidth">
                    <el-date-picker
                        v-model="editForm.ValidityTime"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="权限管理" :label-width="formLabelWidth">
                    <el-checkbox-group v-model="editAccess">
                        <el-checkbox label="COSMAP" name="type"></el-checkbox>
                        <el-checkbox
                            label="热处理优化系统"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox
                            label="智能制造1"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox
                            label="智能制造2"
                            name="type"
                        ></el-checkbox>
                        <el-checkbox
                            label="智能制造3"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    SelectCompany,
    AddCompany,
    DeleteCompany,
    EditCompany,
} from "../api/api";
export default {
    components: {},
    data() {
        return {
            tableData: [], //表数据
            queryStr: "",
            currentPage: 1, //当前是第几页
            pageSize: 7,
            pageSizes: [7, 10, 20, 30],
            totalNumber: 0, //共计多少条数据
            orderBy: "",
            addDialogFormVisible: false,
            editDialogFormVisible: false,
            addAccess: [],
            editAccess: [],
            addForm: {
                Name: "",
                Contacts: "",
                Tel: "",
                Address: "",
                MaximumNumberOfUsers:"",
                ValidityTime: "",
            },
            editForm: {
                id: "",
                Name: "",
                Contacts: "",
                Tel: "",
                Address: "",
                ValidityTime: "",
                MaximumNumberOfUsers:"",
            },
            formLabelWidth: "120px",
        };
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        //返回首页
        toHome() {
            this.$router.push({ path: "/home" });
        },
        toRegisterManage() {
            this.$router.push({ path: "/home/registerManage" });
        },
        toUserManage() {
            this.$router.push({ path: "/home/userManage" });
        },
        toCompanyManage() {
            this.$router.push({ path: "/home/CompanyManage" });
        },
        toLog() {
            this.$router.push({ path: "/home/Log" });
        },
        //获取表格数据
        getTableData() {
            var pageData = {
                name: this.queryStr,
                desc: false,
                currentPage: this.currentPage - 1,
                pageNumber: this.pageSize,
            };
            SelectCompany(pageData).then((res) => {
                this.tableData = res.data;
                this.totalNumber = res.count;
            });
        },
        //刷新表格
        refreshTable() {
            this.getTableData();
        },
        //点击添加按钮（改变添加dialog状态）
        changeDialogFormVisible() {
            this.addDialogFormVisible = true;
        },
        //点击确认添加按钮
        AddConfirm() {
            this.addForm.access = this.addAccess.toString();
            AddCompany(this.addForm).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "添加单位成功！",
                        type: "success",
                    });
                } else {
                    this.$message.error("添加单位失败！");
                }
                this.addDialogFormVisible = false;
                this.tableData = [];
            });
        },
        //点击修改按钮
        handleEdit(row) {
            this.editForm.id = row.Id;
            this.editForm.Name = row.Name;
            this.editForm.Contacts = row.Contacts;
            this.editForm.Tel = row.Tel;
            this.editForm.Address = row.Address;
            this.editForm.ValidityTime = row.ValidityTime;

            //单位权限管理（未来可能加入）
            //let accessStr = row.Access;
            // if (accessStr != null) {
            //     this.editAccess = accessStr.split(",");
            // } else {
            //     this.editAccess = [];
            // }
            this.editDialogFormVisible = true;
        },
        //点击确认修改按钮
        EditConfirm() {
            this.editForm.access = this.editAccess.toString();
            EditCompany(this.editForm).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "编辑单位成功！",
                        type: "success",
                    });
                    this.getTableData();
                } else {
                    this.$message.error("编辑单位失败！");
                }
                this.editDialogFormVisible = false;
                this.tableData = [];
            });
        },
        //点击删除按钮
        handleDelete(row) {
            var Data = {
                id: row.Id,
            };
            console.log(row);
            DeleteCompany(Data).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "删除单位成功！",
                        type: "success",
                    });
                    this.getTableData();
                } else {
                    this.$message.error("删除用户失败！");
                }
            });
        },

        //切换表格每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
            console.log(`每页 ${val} 条`);
        },
        //切换表格当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
            console.log(`当前页: ${val}`);
        },
    },
};
</script>

<style>
.mainBox2 > .border-box-content {
    padding: 76px 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-size: 25px;
    font-weight: 700;
    grid-gap: 20px;
}
</style>

<style scoped>
.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.menu-item {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edebeb69;
    border-radius: 25px;
    width: 80%;
    height: 50px;
    font-size: 20px;
    transition: all 0.2s linear;
}
.menu-item:hover {
    transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -o-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    background-color: #edebeb90;
}

.main-table {
    width: 65vw;
    padding: 20px 0px;
    margin: 0 auto;
}
</style>
