<template>
    <div class="home">
        <!-- 主页主体 -->
        <el-container>
            <!-- 页面左侧导航开始 -->
            <el-aside width="auto" style="background-color: rgb(238, 241, 246)">
                <el-menu
                    :default-active="$route.path"
                    router
                    :default-openeds="['1', '2', '3', '4']"
                    :collapse="NavigationBarState"
                    mode="vertical"
                >
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-cpu"></i>
                            <span>数值模拟</span>
                        </template>
                        <el-menu-item index="/newhome/Calculate"
                            >COSMAP</el-menu-item
                        >
                        <el-menu-item index="/newhome/cogs"
                            >齿轮智能模拟制造</el-menu-item
                        >
                        <el-menu-item index="/newhome/bearing"
                            >轴承智能模拟制造</el-menu-item
                        >
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">
                            <i class="el-icon-box"></i>
                            <span>材料物性库</span>
                        </template>
                        <el-menu-item-group>
                            <template slot="title">自定义材料</template>
                            <el-menu-item index="/newhome/CreateMaterial"
                                >创建材料物性文件</el-menu-item
                            >
                            <el-menu-item index="/newhome/OrderManagement"
                                >编辑材料物性文件</el-menu-item
                            >
                            <el-menu-item index="/newhome/MaterialManagement"
                                >材料物性列表</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    accessList.findIndex(
                                        (x) => x.Right.Name == '订单管理'
                                    ) != -1
                                "
                                index="/home/WeeklyProductionPlanManagement"
                                >带权限的选项2</el-menu-item
                            >
                        </el-menu-item-group>
                        <el-menu-item-group>
                            <template slot="title">优化材料</template>
                            <el-menu-item index="/newhome/FirstManagefrom"
                                >冷却介质列表</el-menu-item
                            >
                        </el-menu-item-group>
                    </el-submenu>
                    <el-submenu index="3">
                        <template slot="title">
                            <i class="el-icon-s-tools"></i>
                            <span>工艺参数库</span>
                        </template>
                        <el-menu-item-group>
                            <template slot="title">自定义参数库</template>
                            <el-menu-item
                                index="/newhome/ProcessPropertiesManagement"
                                >工艺参数列表</el-menu-item
                            >
                            <el-menu-item index="/newhome/CreateProcess"
                                >创建工艺参数文件</el-menu-item
                            >
                            <el-menu-item index="/newhome/OrderManagement"
                                >编辑工艺参数文件</el-menu-item
                            >
                        </el-menu-item-group>
                        <el-menu-item-group>
                            <template slot="title">优化参数库</template>
                            <el-menu-item
                                index="/home/ProductTrackingCardManagement"
                                >工艺参数列表</el-menu-item
                            >
                        </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="4">
                        <template slot="title">
                            <i class="el-icon-coin"></i>
                            <span>模型库</span>
                        </template>
                        <el-menu-item index="/newhome/ModelList"
                            >模型列表</el-menu-item
                        >
                    </el-submenu>
                    <el-submenu index="5">
                        <template slot="title">
                            <i class="el-icon-s-custom"></i>
                            <span>账户管理</span>
                        </template>

                        <el-menu-item index="/newhome/Usermanage"
                            >用户管理</el-menu-item
                        >
                        <el-menu-item index="/newhome/Companymanage"
                            >单位管理</el-menu-item
                        >
                        <el-menu-item index="/newhome/Registermanage"
                            >申请管理</el-menu-item
                        >
                    </el-submenu>
                    <el-submenu index="6">
                        <template slot="title">
                            <i class="el-icon-lock"></i>
                            <span>安全管理</span>
                        </template>

                        <el-menu-item index="/newhome/Log"
                            >安全日志</el-menu-item
                        >
                    </el-submenu>
                </el-menu>
            </el-aside>
            <!-- 页面左侧导航结束 -->
            <el-container>
                <!-- 主页顶部导航开始 -->
                <el-header height="6vh">
                    <div @click="ToHome" class="logo">
                        <span>先进热处理智能制造</span>
                    </div>
                    <div class="header-menu">
                        <!-- 收起侧边导航栏按钮 -->
                        <el-tooltip
                            effect="dark"
                            content="显示/隐藏侧边导航栏"
                            placement="bottom"
                        >
                            <div
                                class="header-menu-item"
                                @click="CollapseNavigationBar"
                            >
                                <i
                                    :class="{
                                        'el-icon-s-fold': isFold,
                                        'el-icon-s-unfold': !isFold,
                                    }"
                                ></i>
                            </div>
                        </el-tooltip>

                        <!-- 全屏按钮 -->
                        <el-tooltip
                            effect="dark"
                            content="全屏/退出全屏"
                            placement="bottom"
                        >
                            <FullScreen />
                        </el-tooltip>

                        <!-- 缩小屏幕 -->
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('lang.ReduceScreen')"
                            placement="bottom-end"
                        >
                            <div class="header-menu-item" @click="ReduceScreen">
                                <i class="el-icon-remove"></i>
                            </div>
                        </el-tooltip>
                        <!-- 放大屏幕 -->
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('lang.EnlargeScreen')"
                            placement="bottom-end"
                        >
                            <div
                                class="header-menu-item"
                                @click="EnlargeScreen"
                            >
                                <i class="el-icon-circle-plus"></i>
                            </div>
                        </el-tooltip>
                    </div>
                    <el-dropdown @command="HandleDropDownListCommand">
                        <span class="el-dropdown-link">
                            更多
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="logout"
                                >退出</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div class="header-menu-right">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="账户管理"
                            placement="bottom-end"
                        >
                            <div
                                class="header-menu-item-right"
                                @click="toUserManage"
                            >
                                <i class="el-icon-menu"></i>
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('lang.personalCenter')"
                            placement="bottom-end"
                        >
                            <div
                                class="header-menu-item-right"
                                @click="toUserManage"
                            >
                                <i class="el-icon-user-solid"></i>
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('lang.userGuide')"
                            placement="bottom-end"
                        >
                            <div
                                class="header-menu-item-right"
                                @click="toHelper"
                            >
                                <i
                                    class="el-icon-question"
                                    @click="toHelper"
                                ></i>
                            </div>
                        </el-tooltip>
                        <!-- 国际化按钮 -->
                        <el-tooltip
                            effect="dark"
                            :content="$t('lang.changeLanguage')"
                            placement="bottom"
                        >
                            <Internationalization
                                class="header-menu-item-right"
                            />
                        </el-tooltip>
                        <!-- 回到主页按钮 -->
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('lang.firstpage')"
                            placement="bottom-end"
                        >
                            <div class="header-menu-item-right" @click="ToHome">
                                <i class="el-icon-s-home"></i>
                            </div>
                        </el-tooltip>
                    </div>
                </el-header>
                <!-- 主页顶部导航结束 -->

                <!-- 页面主界面开始 -->
                <el-main>
                    <!-- 主页标签选项卡 -->
                    <Tabs />
                    <div class="SubPageContainer">
                        <!-- 嵌入子路由界面 -->
                        <router-view />
                    </div>
                </el-main>
                <!-- 页面主界面结束 -->
            </el-container>
        </el-container>
        <!-- 主页主体结束 -->
    </div>
</template>

<script>
import Tabs from "../components/Yi-Tabs.vue";
import FullScreen from "../components/Yi-FullScreen.vue";
import Internationalization from "../components/Yi-Internationalize.vue";
export default {
    name: "Home",
    components: { Tabs, FullScreen, Internationalization },
    data() {
        return {
            NavigationBarState: false,
            isMessage: false, //是否收到消息
            isFold: true,
            accessList: [],
            size: 1.0,
        };
    },
    methods: {
        // 返回首页
        ToHome: function () {
            this.$router.push({
                path: "/Master",
            });
        },
        //显示/隐藏侧边导航栏
        CollapseNavigationBar: function () {
            this.NavigationBarState = !this.NavigationBarState;
            this.isFold = !this.isFold;
        },
        //处理顶部导航栏下拉菜单
        HandleDropDownListCommand(command) {
            switch (command) {
                case "logout": {
                    this.Logout();
                }
            }
            // this.$message('click on item ' + command);
        },

        //退出登陆
        Logout: function () {
            this.$message({
                message: "账户退出成功！",
                type: "success",
            });
            this.$router.push({
                path: "../",
            });
        },
        /*消息中心模块 */
        // 查看是否有新消息
        GetNewMessage() {
            // NewMessageCount({ Id: this.userId }).then((res) => {
            //     console.log(res);
            //     if (res != 0 && res != undefined) {
            //         this.isMessage = true;
            //     }
            // });
        },
        BeginGetNewMessage() {
            setInterval(this.GetNewMessage, 180000);
        },
        // 查看消息中心
        ToMessage: function () {
            this.isMessage = false;
            this.$router.push({
                path: "/newhome/MessageManagement",
            });
        },
        /*消息中心模块结束 */

        //放大屏幕
        EnlargeScreen: function () {
            this.size = this.size + 0.1;
            document.body.style.zoom = this.size;
        },
        //减小屏幕
        ReduceScreen: function () {
            this.size = this.size - 0.1;
            document.body.style.zoom = this.size;
        },

        toUserManage() {
            this.$router.push({ path: "/newhome/Usermanage" });
        },

        toHelper() {
            this.$router.push({ path: "/newhome/Helper" });
        },
    },
    mounted() {
        // // var t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
        // if (document.body.clientWidth > 1920) {
        //     document.body.style.zoom = 1 * 1.1;
        // }
        // 就去修改页面的缩放比例
        /*从localStorage中获取用户权限*/
        // let accessStr = JSON.parse(localStorage.getItem("access"));
        // this.accessList = accessStr.RoleRights;
    },
};
</script>

<style>
.el-main {
    height: 90vh;
    padding: 0px !important;
}
.el-header {
    background-color: rgb(145, 145, 145);
    line-height: 6vh;
    font-size: 15px;
}
.el-header .logo span {
    float: left;
    color: white;
    font-size: 24px;
}
.el-header .header-menu {
    padding: 0px 10px 0px 10px;
    float: left;
}
.el-header .header-menu-item {
    padding: 0px 10px 0px 10px;
    float: left;
    font-size: 20px;
    color: #fff;
}

.el-header .header-menu-right {
    padding: 0px 10px 0px 10px;
    float: right;
}
.el-header .header-menu-item-right {
    padding: 0px 10px 0px 10px;
    font-size: 20px;
    color: #fff !important;
    float: right;
}
.header-menu :hover {
    background-color: rgb(102, 177, 255);
}

.header-menu-right :hover {
    background-color: rgb(102, 177, 255);
}
.el-header .el-dropdown {
    color: #fff !important;
    float: right;
}

.el-menu {
    background-color: rgb(238, 241, 246) !important;
}
.el-main {
    background-color: rgb(238, 241, 246);
}
.el-aside {
    color: #333;
    height: 100vh;
}
/* 修改侧边导航栏bug */
.el-menu:not(.el-menu--collapse) {
    min-width: 200px;
    transition: 0s;
}
/* 子页面路由 */
.SubPageBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
/* 子页面容器 */
.SubPageContainer {
    border: 1px solid #ebedf0;
    border-radius: 4px;
    min-height: 70vh;
    padding: 30px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
/* 去掉el-tabs的margin */
.el-tabs__header {
    margin-bottom: 1vh !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: rgb(145, 145, 145) !important;
}

.el-table,
.el-table__expanded-cell {
    background-color: transparent !important;
}

.el-table th,
.el-table tr {
    background-color: transparent !important;
    border-bottom-color: rgb(145, 145, 145) !important;
}

/*如果不加这个 table的右侧fix 栏就也会透明 */
.el-table__fixed-right {
    background-color: rgb(238, 241, 246) !important;
}
</style>

