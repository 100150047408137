export const lang = {
    login: 'login',

    register: 'register',

    rember: 'rember',

    home: 'home',

    about: 'about',

    aboutDesc: 'This is an about page',

    usermanagement: 'User Management',

    user: 'User',

    onlineComputing: 'Online Computing',

    reserve: 'Reserve',

    firstpage: 'First Page',

    changeLanguege: 'Change Languege',

    showHideideNavigationBar: 'Show/Hide side navigation bar',

    fullScreenExitFullScreen: 'Full screen /Exit full screen',

    dropDownList: 'Drop Down List',

    find: 'Find',

    add: 'Add',

    refresh: 'Refresh List',

    downLoad: 'Download',

    error: 'Error',

    device: 'Device',

    message: 'Message',

    personalCenter: 'Personal Center',

    changeLanguage: 'Change Language',

    // heatTreatmentOptimizationSystem: 'Heat treatment optimization system',

    heatTreatmentOptimizationSystem: 'Heat Treatment Optimization',

    intelligentManufacturing: 'Intelligent  Manufacturing',

    accountManagement: 'Account Management',

    cosmap: 'COSMAP Management',

    copyright: 'Copyright',

    mainTitle: 'Advanced Heat Treatment',

    logout: 'Logout',

    userGuide: 'User Guide',

    startCalculation: 'Start Calculation',

    equipmentManagement: 'Equipment Library',

    EquipmentManagement: 'Equipment Management',

    materialManagement: 'Material Library',

    optimizeManagement: 'Process Optimization Library',

    platformGuide: 'platformGuide',

    cosmapHelper: 'COSMAP Helper',

    guideToAdvancedHeatTreatmentSystems: 'Guide To Advanced Heat Treatment Systems',

    modelManagement: 'Model Library',
    heatTreatmentIntelligentManufacturingSystem: 'Heat treatment intelligent manufacturing system',

    pleaseEnterOneUserName: 'Please enter one user name',

    pleaseInputPassword: 'Please input a password ',

    pleaseEnterTheVerificationCode: ' Please enter the verification code',

    forgetPassword: 'Forgot password?',

    applicationForUse: 'Application for use',

    ProcessManagement: 'Process Management',

    EnergyConsumptionAnalysis: 'Energy Consumption Analysis',

    SafetyAndEnvironmentalProtection: 'Safety And Environmental Protection',

    HeatTreatmenCalculation: ' Heat Treatmen Calculation',

    IntelligentHeatTreatmentSystem: 'Intelligent Heat Treatment System',

    RateOfProgress: 'Rate Of Progress',

    EnlargeScreen: 'EnlargeScreen',

    ReduceScreen: 'ReduceScreen',

};