<template>
    <div class="container">
        <el-col :span="24" class="toolbar">
            <el-input
                style="width: 200px"
                placeholder="搜索名称"
                v-model="queryStr"
            ></el-input
            >&nbsp;&nbsp;
            <el-button @click="queryData()">查询</el-button>
            <el-button @click="clearQuery()">清空</el-button>
            <el-button type="danger" @click="refreshTable()"
                >刷新列表</el-button
            >
            <el-button
                type="primary"
                @click="changeAddDialogFormVisible()"
                >添加</el-button
            >
        </el-col>
        <el-table
                        :data="tableData"
                        style="min-height: 55vh; bacground-color: #ffffff60"
                    >
                        <!-- <el-table-column
                            prop="Id"
                            label="Id"
                            width="150"
                            :show-overflow-tooltip="true"
                        >
                        </el-table-column> -->
                        <el-table-column
                            prop="Name"
                            label="材料名称"
                            width="220"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Introduction"
                            label="材料简介"
                            width="400"
                            :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Type"
                            label="材料类别"
                            width="100"
                            :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column prop="CreateTime" label="发布时间">
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="200">
                            <template slot-scope="scope">
                                <el-button
                                    @click="handleEdit(scope.row)"
                                    type="text"
                                    size="small"
                                    >编辑</el-button
                                >
                                <el-button
                                    @click="handleDelete(scope.row)"
                                    type="text"
                                    size="small"
                                    >删除</el-button
                                >
                                <el-button
                                    @click="handleEdit(scope.row)"
                                    type="text"
                                    size="small"
                                    >下载</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
        <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumber"
            ></el-pagination>
        </div>
       <el-dialog
            title="添加材料"
            :visible.sync="addDialogFormVisible"
            :append-to-body="true"
        >
            <el-form :model="addForm">
                <el-form-item label="名称" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.name"
                        autocomplete="off"
                        placeholder="请输入材料名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="简介" :label-width="formLabelWidth">
                    <el-input
                        v-model="addForm.introduction"
                        autocomplete="off"
                        placeholder="请输入简介"
                    ></el-input>
                </el-form-item>
                <el-form-item label="附件" :label-width="formLabelWidth">
                    <el-upload
                        class="upload-demo"
                        drag
                        :action="baseUrl + 'Material/FileUpLoad'"
                        :multiple="false"
                        :limit="1"
                        ref="uploadAdd"
                        :on-success="addUploadSuccess"
                        :on-error="addUploadError"
                        accept="."
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            请将文件拖至此处，或
                            <em>点击上传</em>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="类别" :label-width="formLabelWidth">
                    <el-select
                        v-model="addForm.type"
                        placeholder="请输入设备类别"
                    >
                        <el-option label="类别1" value="类别1"></el-option>
                        <el-option label="类别2" value="类别2"></el-option>
                        <el-option label="类别3" value="类别3"></el-option>
                        <el-option
                            label="智能制造1"
                            value="智能制造1"
                        ></el-option>
                        <el-option
                            label="智能制造2"
                            value="智能制造2"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="AddConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            title="编辑材料"
            :visible.sync="editDialogFormVisible"
            :append-to-body="true"
        >
            <el-form :model="editForm">
                <el-form-item label="名称" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.name"
                        autocomplete="off"
                        placeholder="请输入材料名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="简介" :label-width="formLabelWidth">
                    <el-input
                        v-model="editForm.introduction"
                        autocomplete="off"
                        placeholder="请输入材料简介"
                    ></el-input>
                </el-form-item>
                <el-form-item label="附件" :label-width="formLabelWidth">
                    <el-upload
                        class="upload-demo"
                        drag
                        :action="baseUrl + 'Material/FileUpLoad'"
                        :multiple="false"
                        :limit="1"
                        ref="uploadEdit"
                        :on-success="editUploadSuccess"
                        :on-error="editUploadError"
                        accept="."
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            请将文件拖至此处，或
                            <em>点击上传</em>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="类别" :label-width="formLabelWidth">
                    <el-select
                        v-model="editForm.type"
                        placeholder="请输入设备类别"
                    >
                        <el-option label="类别1" value="类别1"></el-option>
                        <el-option label="类别2" value="类别2"></el-option>
                        <el-option label="类别3" value="类别3"></el-option>
                        <el-option
                            label="智能制造1"
                            value="智能制造1"
                        ></el-option>
                        <el-option
                            label="智能制造2"
                            value="智能制造2"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="EditConfirm()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    SelectMaterial,
    AddMaterial,
    DeleteMaterial,
    EditMaterial,
    Url,} from "../../api/api";
export default {
    components: {},
    data() {
        return {
            baseUrl: Url,
            tableData: [], //表数据
            queryStr: "",
            currentPage: 1, //当前是第几页
            pageSize: 7,
            pageSizes: [7, 10, 20, 30],
            totalNumber: 0, //共计多少条数据
            orderBy: "",
            addDialogFormVisible: false,
            editDialogFormVisible: false,
            addAccess: [],
            editAccess: [],
            addForm: {
                name: "",
                introduction: "",
                filePath: "",
                type: "",
            },
            editForm: {
                id: "",
                name: "",
                introduction: "",
                filePath: "",
                type: "",
            },
            formLabelWidth: "120px",
        };
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        //获取表格数据
        getTableData() {
            var pageData = {
                name: this.queryStr,
                desc: false,
                currentPage: this.currentPage - 1,
                pageNumber: this.pageSize,
            };
            SelectMaterial(pageData).then((res) => {
                this.tableData = res.data;
                this.totalNumber = res.count;
            });
        },
        //刷新表格
        refreshTable() {
            this.getTableData();
        },
        //点击添加按钮（改变添加dialog状态）
        changeAddDialogFormVisible() {
            this.addDialogFormVisible = true;
        },
        //点击确认添加按钮
        AddConfirm() {
            this.addForm.access = this.addAccess.toString();
            AddMaterial(this.addForm).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "添加设备成功！",
                        type: "success",
                    });
                } else {
                    this.$message.error("添加设备失败！");
                }
                this.addDialogFormVisible = false;
                this.getTableData();
            });
        },
        //点击修改按钮
        handleEdit(row) {
            this.editForm.id = row.Id;
            this.editForm.name = row.Name;
            this.editForm.introduction = row.Introduction;
            this.editForm.filePath = row.FilePath;
            this.editForm.type = row.Type;
            this.editDialogFormVisible = true;
        },
        //点击确认修改按钮
        EditConfirm() {
            this.editForm.access = this.editAccess.toString();
            EditMaterial(this.editForm).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "编辑设备成功！",
                        type: "success",
                    });
                    this.getTableData();
                } else {
                    this.$message.error("编辑设备失败！");
                }
                this.editDialogFormVisible = false;
                this.getTableData();
            });
        },
        //点击删除按钮
        handleDelete(row) {
            var Data = {
                id: row.Id,
            };
            console.log(row);
            DeleteMaterial(Data).then((res) => {
                if (res == true) {
                    this.$message({
                        message: "删除设备成功！",
                        type: "success",
                    });
                    this.getTableData();
                } else {
                    this.$message.error("删除设备失败！");
                }
            });
        },

        //切换表格每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
            console.log(`每页 ${val} 条`);
        },
        //切换表格当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
            console.log(`当前页: ${val}`);
        },

        //文件导入成功后的处理
        addUploadSuccess(response, file, fileList) {
            this.$message({
                message: "文件上传成功！",
                type: "success",
            });
            this.addForm.filePath = response.Data;
        },
        //文件导入失败后的处理
        addUploadError(error, file, fileList) {
            this.$message.error(error.message);
            this.$refs.uploadAdd.clearFiles();
        },
        //文件导入成功后的处理
        editUploadSuccess(response, file, fileList) {
            this.$message({
                message: "文件上传成功！",
                type: "success",
            });
            this.editForm.filePath = response.Data;
        },
        //文件导入失败后的处理
        editUploadError(error, file, fileList) {
            this.$message.error(error.message);
            this.$refs.uploadEdit.clearFiles();
        },
    },
};
</script>
